import {
  Button,
  Form,
  Modal,
  Row,
  Typography,
  notification,
  Space,
  TimePicker,
  Col,
  Radio,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
//import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { GETMANUAL, POST } from "../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "rc-field-form";
import validateRules from "../../../providers/validateRules";
import { role } from "../../../providers/companyInfo";

export default function ModalAddConsultantAvailability(props) {
  const user_role = role();
  const {
    toggleModal,
    setToggleModal,
    selectedDate,
    id,
    reload,
    data,
    workflow,
  } = props;
  const [form] = Form.useForm();

  const format = "HH:mma";

  const { mutate: mutateAddAppointments, isLoading: isLoadingAddAppointments } =
    POST("api/v1/add/appointment", "get_appointment");

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const onFinish = (values) => {
    let slots = form.getFieldsValue("slots");
    let form_data = [];

    if (slots.slots?.length > 0) {
      slots?.slots.map((slot) => {
        if (slot.startTime && slot.endTime) {
          let startTime = moment(slot.startTime).format("HH:mm");
          let endTime = moment(slot.endTime).format("HH:mm");
          if (slot.startTime.isBefore(slot.endTime)) {
            form_data.push({
              id: id,
              endTime: endTime,
              startTime: startTime,
              date: selectedDate,
              workflow: selectedWorkflow,
            });
          } else {
            notification.warning({
              message: "Warning",
              description: "Invalid time slot.",
            });
          }
        }
      });
    }

    if (form_data.length == 0) {
      notification.warning({
        message: "Warning",
        description: "Slot time is needed.",
      });
    }

    let data = {
      data: form_data,
    };

    mutateAddAppointments(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Slot successfully added",
          });

          addHistoryLog(
            {
              page: "Consultant/Schedule",
              key: "appointment slot",
              consultant: id,
              old_data: "",
              new_data: JSON.stringify(data.data),
              method: "add-slot",
            },
            { onSuccess: (res) => {} }
          );

          setToggleModal(false);
          reload();
        }
      },
    });
  };

  useEffect(() => {
    if (!toggleModal) {
      form.resetFields(["slots"]);
    }
    // console.log("selectedDate", selectedDate);
    // console.log("selectedDate", id);

    if (selectedDate && id) {
      refetchGetDaySchedule();
    }
  }, [toggleModal]);

  const [dayScheduleList, setDayScheduleList] = useState([]);
  const { refetch: refetchGetDaySchedule } = GETMANUAL(
    `api/v1/get_appoinment_by_date?user_id=${id}&schedule_date=${selectedDate}`,
    "get_appoinment_by_date",
    (res) => {
      if (res.success) {
        // console.log("handleDateCheckStart", res);
        let row = [];
        if (res.data && res.data.length > 0) {
          res.data.map((item, key) => {
            row.push({
              startTime: moment(selectedDate + " " + item.time_start),
              endTime: moment(selectedDate + " " + item.time_end),
            });
          });
        }
        setDayScheduleList(row);
      }
    },
    false
  );

  const [disabledEndDate, setDisabledEndDate] = useState([]);
  const [addedTime, setAddedTime] = useState({
    limit: workflow === "all" ? 2 : 30,
    type: workflow === "all" ? "hours" : "minutes",
  });

  const handleDateCheckStart = (e, index) => {
    let row = form.getFieldsValue().slots;
    let rowGet = form.getFieldsValue().slots;
    row = row.concat(dayScheduleList);
    let selected = row[index];

    let err = 0;
    let disEnd = [...disabledEndDate];
    disEnd[index] = e ? false : true;
    if (selected && selected?.startTime) {
      row.map((item, key) => {
        if (item && item.startTime) {
          if (key !== index) {
            let startTime = item?.startTime?.format("HHmm");
            let startTime_selected = selected?.startTime?.format("HHmm");
            // if there is no errors
            if (err === 0) {
              if (startTime === startTime_selected) {
                let rowResult = [...rowGet];
                rowResult[index] = {
                  ...rowResult[index],
                  startTime: null,
                  endTime: null,
                };
                form.setFieldsValue({
                  slots: rowResult,
                });

                notification.warning({
                  message: "Warning",
                  description: "Start time is already used!",
                });
                disEnd[index] = true;
                err = 1;
              } else {
                let endTime = item?.endTime?.format("HHmm");
                let endtTime_selected = selected?.endTime?.format("HHmm");

                if (
                  Number(startTime) < Number(startTime_selected) &&
                  Number(endTime) > Number(startTime_selected)
                ) {
                  notification.warning({
                    message: "Warning",
                    description: "Invalid time slot!",
                  });
                  let rowResult = [...rowGet];
                  rowResult[index] = {
                    ...rowResult[index],
                    startTime: null,
                  };
                  form.setFieldsValue({
                    slots: rowResult,
                  });
                  disEnd[index] = true;
                  err = 1;
                } else if (endtTime_selected === startTime_selected) {
                  notification.warning({
                    message: "Warning",
                    description: "Invalid time slot!",
                  });
                  let rowResult = [...rowGet];
                  rowResult[index] = {
                    ...rowResult[index],
                    startTime: null,
                    endTime: null,
                  };
                  form.setFieldsValue({
                    slots: rowResult,
                  });
                  disEnd[index] = true;
                  err = 1;
                } else if (
                  endtTime_selected &&
                  Number(endtTime_selected) < Number(startTime_selected)
                ) {
                  notification.warning({
                    message: "Warning",
                    description: "Invalid time slot!",
                  });
                  let rowResult = [...rowGet];
                  rowResult[index] = {
                    ...rowResult[index],
                    startTime: null,
                    endTime: null,
                  };
                  form.setFieldsValue({
                    slots: rowResult,
                  });
                  disEnd[index] = true;
                  err = 1;
                }
                // else if (index > 0 || index === 0) {
                //   let last_row_start_time =
                //     row[index - 1]?.startTime?.format("HHmm");
                //   let last_row_end_time =
                //     row[index - 1]?.endTime?.format("HHmm");
                //   if (!last_row_start_time || !last_row_end_time) {
                //     notification.warning({
                //       message: "Warning",
                //       description: "Please select time slot!",
                //     });
                //     let rowResult = [...rowGet];
                //     rowResult[index] = {
                //       ...rowResult[index],
                //       startTime: null,
                //     };
                //     form.setFieldsValue({
                //       slots: rowResult,
                //     });
                //     disEnd[index] = true;
                //     err = 1;
                //   }
                // }
              }
            }
          }
        }
      });
    }

    setDisabledEndDate(disEnd);
    // if (err === 0 && user_role === "Consultant") {
    if (err === 0) {
      // auto add endtime 2 hours
      let rowResult2hours = [...rowGet];
      rowResult2hours[index] = {
        ...rowResult2hours[index],
        endTime: e ? moment(e).add(addedTime.limit, addedTime.type) : null,
      };
      form.setFieldsValue({
        slots: rowResult2hours,
      });

      // get end time value
      if (rowResult2hours[index]?.endTime) {
        handleDateCheckEnd(rowResult2hours[index]?.endTime, index);
      }
      // } else if (err === 1 && user_role === "Consultant") {
    } else if (err === 1) {
      let rowResult2hours = [...rowGet];
      rowResult2hours[index] = {
        ...rowResult2hours[index],
        endTime: null,
      };
      form.setFieldsValue({
        slots: rowResult2hours,
      });
    }
  };

  const handleDateCheckEnd = (e, index) => {
    let row = form.getFieldValue().slots;
    let rowGet = form.getFieldsValue().slots;
    row = row.concat(dayScheduleList);
    let selected = row[index];
    let arrMax = [];
    let arrMin = [];
    row.map((i, k) => {
      if (k !== index) {
        if (i && i.endTime && selected) {
          if (i.endTime) {
            arrMax.push(Number(i.endTime.format("HHmm")));
          }
          if (i.startTime) {
            arrMin.push(Number(i.startTime.format("HHmm")));
          }
        }
      }
    });
    let minData = arrMin.length > 0 ? Math.min.apply(null, arrMin) : 0;
    let maxData = arrMax.length > 0 ? Math.min.apply(null, arrMax) : 0;

    let err = 0;
    if (selected && selected.startTime && selected.endTime) {
      let startTime = selected.startTime;
      let endTime = e;

      if (startTime && endTime) {
        startTime = startTime.format("HHmm");
        endTime = endTime.format("HHmm");
        if (err === 0) {
          if (startTime >= endTime) {
            notification.warning({
              message: "Warning",
              description: "Time schedule is invalid!",
            });

            let rowResult = [...rowGet];
            rowResult[index] = {
              ...rowResult[index],
              endTime: null,
            };
            form.setFieldsValue({
              slots: rowResult,
            });
            err = 1;
          } else {
            row.map((item, key) => {
              if (item && item.startTime && item.endTime) {
                let endTime = item.endTime.format("HHmm");
                let endTime_selected = selected.endTime.format("HHmm");
                console.log("handleGetDaySchedule", endTime, endTime_selected);
                console.log("handleGetDaySchedule", key, index);
                if (key !== index) {
                  if (endTime === endTime_selected) {
                    let rowResult = [...rowGet];
                    rowResult[index] = {
                      ...rowResult[index],
                      endTime: null,
                    };
                    form.setFieldsValue({
                      slots: rowResult,
                    });
                    err = 1;
                  }
                  // else if (key > index) {
                  //   if (Number(endTime) < Number(endTime_selected)) {
                  //     let rowResult = [...rowGet];
                  //     rowResult[index] = {
                  //       ...rowResult[index],
                  //       endTime: null,
                  //     };
                  //     form.setFieldsValue({
                  //       slots: rowResult,
                  //     });
                  //     err = 1;
                  //   }
                  // }
                  else {
                    if (item.startTime) {
                      let startTime_item = item.startTime.format("HHmm");
                      if (
                        Number(startTime_item) < Number(endTime_selected) &&
                        Number(endTime) > Number(endTime_selected)
                      ) {
                        let rowResult = [...rowGet];
                        rowResult[index] = {
                          ...rowResult[index],
                          endTime: null,
                        };
                        form.setFieldsValue({
                          slots: rowResult,
                        });
                        err = 1;
                      } else if (
                        maxData &&
                        Number(endTime_selected) > maxData &&
                        Number(startTime) < minData
                      ) {
                        let rowResult = [...rowGet];
                        rowResult[index] = {
                          ...rowResult[index],
                          endTime: null,
                        };
                        form.setFieldsValue({
                          slots: rowResult,
                        });
                        err = 1;
                      }
                    }
                  }
                }
              }
            });

            if (err > 0) {
              notification.warning({
                message: "Warning",
                description: "Invalid time slot!",
              });
              err = 0;
            }
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log("disabledEndDate", disabledEndDate);
  // }, [disabledEndDate]);

  const [selectedWorkflow, setSelectedWorkflow] = useState(
    workflow === "all" ? "8 hour application" : workflow
  );

  useEffect(() => {
    if (workflow === "all" || workflow === "8 hour application") {
      setSelectedWorkflow("8 hour application");
      setAddedTime({ limit: 2, type: "hours" });
    } else {
      setSelectedWorkflow(workflow);
      setAddedTime({ limit: 30, type: "minutes" });
    }
  }, [workflow]);

  const handleChangeWorkflow = (value) => {
    setSelectedWorkflow(value);
    if (value === "8 hour application") {
      setAddedTime({ limit: 2, type: "hours" });
    } else {
      setAddedTime({ limit: 30, type: "minutes" });
    }

    //reset row
    let rowGet = form.getFieldsValue().slots;
    if (!Array.isArray(rowGet)) {
      rowGet = [];
    }
    let rowResult = [...rowGet];
    rowResult.map((item, key) => {
      rowResult[key] = {
        ...rowResult[key],
        startTime: null,
        endTime: null,
      };
    });
    form.setFieldsValue({
      slots: rowResult,
    });
  };

  return (
    <Modal
      title={moment(selectedDate).format("LL")}
      closable={true}
      open={toggleModal}
      // closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={
        <Button
          onClick={() => {
            form.submit();
          }}
          className="atc-btn-opposite"
          type="primary"
          style={{ marginRight: "7px" }}
          loading={isLoadingAddAppointments}
        >
          SUBMIT
        </Button>
      }
      onCancel={() => setToggleModal(false)}
      className="modal-primary-default modal-change-2-factor-authentication modal-consultant-schedule"
      afterClose={() => {
        form.resetFields();
        setDayScheduleList([]);
        setDisabledEndDate([]);
      }}
    >
      <Row gutter={[12, 12]} className="m-b-sm">
        <Col md={24} lg={24} sm={24} xs={24}>
          <Radio.Group
            value={selectedWorkflow}
            onChange={(event) => handleChangeWorkflow(event.target.value)}
          >
            <Radio value="8 hour application">8 Hour Application </Radio>
            <Radio value="expedited editing">Expedited Editing </Radio>
          </Radio.Group>
        </Col>
      </Row>

      <Row>
        <Col md={24} lg={24} sm={24} xs={24}>
          {" "}
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.List name="slots">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row key={key}>
                      <Col
                        xs={24}
                        md={24}
                        lg={24}
                        sm={24}
                        key={key}
                        // style={{
                        //   display: "flex",
                        //   marginBottom: 8,
                        // }}
                        // align="baseline"
                      >
                        <Row
                          className="timeslot-select-cont"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Col xs={10} md={10} lg={10} sm={10}>
                            <Form.Item
                              {...restField}
                              name={[name, "startTime"]}
                              key={[key, "strtTime"]}
                              // hasFeedback
                              rules={[validateRules.required]}
                              className="timeslot-select"
                            >
                              <TimePicker
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  handleDateCheckStart(e, name);

                                  let rowGet = form.getFieldsValue().slots;
                                  let selected = rowGet[name];

                                  let startTime =
                                    selected?.startTime?.format("HHmm");
                                  let endTime =
                                    selected?.endTime?.format("HHmm");

                                  if (startTime && endTime) {
                                    let timeDiff =
                                      Number(endTime) - Number(startTime);
                                    if (timeDiff > 200) {
                                      notification.warning({
                                        message: "Warning",
                                        description:
                                          "Invalid time slot! Please select 2 hours time slot.",
                                      });

                                      let rowResult = [...rowGet];
                                      rowResult[name] = {
                                        ...rowResult[name],
                                        startTime: null,
                                        endTime: null,
                                      };
                                      form.setFieldsValue({
                                        slots: rowResult,
                                      });
                                      return false;
                                    }
                                  }
                                  // if (user_role === "Consultant") {
                                  // } else {
                                  //   handleDateCheckStart(e, name);
                                  // }
                                }}
                                placeholder="Start time"
                                format="HH:mm"
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            xs={4}
                            md={4}
                            lg={4}
                            sm={4}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              paddingTop: "5px",
                            }}
                          >
                            TO
                          </Col>

                          <Col xs={8} md={8} lg={8} sm={8}>
                            <Form.Item
                              {...restField}
                              name={[name, "endTime"]}
                              key={[key, "endTime"]}
                              className="timeslot-select"
                              rules={[validateRules.required]}
                            >
                              <TimePicker
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  let rowGet = form.getFieldsValue().slots;
                                  let selected = rowGet[name];

                                  let startTime =
                                    selected?.startTime?.format("HHmm");
                                  let endTime =
                                    selected?.endTime?.format("HHmm");

                                  if (startTime && endTime) {
                                    console.log("here");
                                    let timeDiff =
                                      Number(endTime) - Number(startTime);
                                    if (timeDiff > 200) {
                                      notification.warning({
                                        message: "Warning",
                                        description:
                                          "Please select less than or equal to 2 hours time slot.",
                                      });

                                      let rowResult = [...rowGet];
                                      rowResult[name] = {
                                        ...rowResult[name],
                                        endTime: null,
                                        endTime: null,
                                      };
                                      form.setFieldsValue({
                                        slots: rowResult,
                                      });
                                      return false;
                                    }
                                  }

                                  handleDateCheckEnd(e, name);
                                  // if (user_role === "Consultant") {
                                  // } else {
                                  //   handleDateCheckEnd(e, name);
                                  // }
                                }}
                                placeholder="End time"
                                disabled={disabledEndDate[name] === true}
                                format="HH:mm"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={2}
                            md={2}
                            lg={2}
                            sm={2}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              paddingTop: "9px",
                            }}
                          >
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(name);
                                let disEnd = [...disabledEndDate];
                                disEnd.splice(name, 1);
                                setDisabledEndDate(disEnd);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item className="m-b-none">
                    <Button
                      type="dashed"
                      onClick={() => {
                        form.validateFields().then(() => {
                          let newDisEnd = [...disabledEndDate, true];
                          setDisabledEndDate(newDisEnd);
                          add();
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      ADD SLOT
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
