import { Card, Row, Col, Collapse, Button, Upload, message, Form, notification, Space, Table, Typography, Image, Tooltip, Timeline } from "antd"
import React, { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from "jquery"
import { faArrowUpFromSquare, faCircleInfo, faInfoCircle } from "@fortawesome/pro-regular-svg-icons"

import { TablePageSize, TableGlobalSearch, TableShowingEntries, TablePagination } from "../../Components/ComponentTableFilter"

import { GET, GETMANUAL, POST, POSTFILE } from "../../../../providers/useAxiosQuery"
import ModalFileView from "../../Components/ModalFileView"

import { useParams } from "react-router-dom"
import moment from "moment"
import { userData } from "../../../../providers/companyInfo"
import { faFolderOpen, faPlus, faVideo, faDownload, faEye } from "@fortawesome/pro-solid-svg-icons"
import { faArrowUpFromBracket, faFileUpload } from "@fortawesome/pro-light-svg-icons"
import TableScrollOnTop from "../../../../providers/TableScrollOnTop"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

function PageDocuments(props) {
  const history = useHistory()
  const { match } = props
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([])
  const [currentTask, setCurrentTask] = useState("")
  const [visible, setVisible] = useState(false)
  const [fileSrc, setFileSrc] = useState("")
  const [fileExt, setFileExt] = useState("")
  const [base64File, setBase64File] = useState("")
  const [toggleModal, setToggleModal] = useState(false)

  const code = props.location.search.split("=")[1]

  // get url path
  const { path } = match
  const [tabIndex, setTabIndex] = useState(path === "/documents/my-uploads" ? 1 : path === "/documents/consultant-uploads" ? 2 : 0)

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "id",
    sort_order: "desc",
    type: "",
  })

  console.log("tab-index", tabIndex)

  const [tableTotal, setTableTotal] = useState(0)

  const { refetch: getfilelist } = GET(`expedited/dropbox/getfilelist?${$.param(tableFilter)}`, "get_list", (res) => {
    if (res.success) {
      setDataSource(res.data.data)
      setTableTotal(res.data.total)
    }
  })

  useEffect(() => {
    getfilelist()
    return () => {}
  }, [tableFilter])

  GET(`expedited/task/${userData().id}`, "get_task", (res) => {
    if (res.success) {
      let task_data = []
      res.data.reverse().map((item) => {
        if (task_data.length === 0) {
          task_data.push({
            id: item.id,
            title: item.title,
            description: item.description,
            assignedTo: item.assignedTo,
            dueDate: item.dueDate,
            isCompleted: item.isCompleted,
            isActive: true,
          })
        } else {
          task_data.push({
            id: item.id,
            title: item.title,
            description: item.description,
            assignedTo: item.assignedTo,
            dueDate: item.dueDate,
            isCompleted: item.isCompleted,
            isActive: task_data[task_data.length - 1].isCompleted ? true : false,
          })
        }
      })

      task_data.sort((a, b) => (a.isActive > b.isActive ? 1 : b.isCompleted > a.isCompleted ? -1 : 0))

      let current_task = task_data.filter((task) => {
        return (task.isActive == true) & (task.isCompleted == false)
      })
      console.log(current_task)
      setCurrentTask(current_task)
    }
  })

  // const { mutate: mutateFetchKey } = POST("expedited/dropbox", "upload_docs");

  // const connectToDropBox = () => {
  // 	mutateFetchKey("", {
  // 		onSuccess: (res) => {
  // 			if (res.type == "url") {
  // 				window.location.replace(res.data);
  // 				console.log(res.data);
  // 			} else {
  // 				console.log(res.data);
  // 			}
  // 		},
  // 	});
  // };

  // const { mutate: mutateSaveToken } = POST(
  // 	"expedited/dropbox/savetoken",
  // 	"savetoken"
  // );

  const { mutate: mutateDownloadFile } = POST("expedited/dropbox/download", "savetoken")

  const { mutate: mutatePreviewFile } = POST("expedited/dropbox/preview", "savetoken")

  const handleDownloadFile = (id) => {
    let data = {
      id: id,
    }

    mutateDownloadFile(data, {
      onSuccess: (res) => {
        if (res.success) {
          window.location.replace(res.data)
        }
      },
    })
  }

  const [showPdfDoc, setShowPdfDoc] = useState(false)

  const handlePreviewFile = (id) => {
    let data = {
      id: id,
    }

    mutatePreviewFile(data, {
      onSuccess: (res) => {
        if (res.success) {
          // window.location.replace(res.data);
          setFileSrc("")
          setFileExt("")
          setFileExt(res.filename)
          setBase64File(res.data)

          var file_type = ["docx", "pdf", "doc"]

          if (file_type.includes(res.filename)) {
            // setFileSrc(
            //   "data:application/" + `${res.filename}` + ";base64," + res.data
            // );

            setFileSrc(res.data)
          } else {
            setFileSrc("data:image/" + `${res.filename}` + ";base64," + res.data)
          }
        }
      },
    })
  }

  const handleClearFile = () => {
    setFileSrc("")
    setFileExt("")
  }

  useEffect(() => {
    if (fileSrc != "") {
      var file_type = ["docx", "pdf", "doc"]

      if (file_type.includes(fileExt)) {
        console.log("filesource :", fileSrc)

        setShowPdfDoc(true)

        // var win = window.open();
        // win.document.write(
        //   '<iframe src="' +
        //     fileSrc +
        //     '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
        // );
      } else {
        setVisible(true)
      }
    }
  }, [fileSrc])

  const { mutate: addHistoryLog } = POST("expedited/historylogs/add", "add_history_logs")
  const [current_tag, setCurrentTag] = useState([])
  const { refetch: refetchAllTags, isLoading: isLoadingTags } = GET(`expedited/user/getalltags?user_id=${userData().id}`, ["dataAllTask", "get_task"], (res) => {
    if (res.success) {
      setCurrentTag(res.dataAllTask)
    }
  })

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = POSTFILE("expedited/uploadFiles", "upload")

  const [multifileList, setMultiFileList] = useState([])
  const onFinish = (values) => {
    //console.log("values", values);

    // let data = {
    //   ...values,
    //   id: table_id ? table_id : "",
    // };

    const data = new FormData()

    multifileList.map((item, index) => {
      data.append("images_" + index, item.originFileObj, item.name)
    })
    data.append("images_count", multifileList ? multifileList.length : 0)
    data.append("images", multifileList)

    data.append("current_task", JSON.stringify(currentTask))

    let file_required = 0
    let notification_message = "Please choose files to upload"
    if (current_tag.includes("upload timeline sheet (current task)") || current_tag.includes("upload revised application and timeline (current task)")) {
      file_required = 1
      notification_message = "Please upload both timeline sheet and application revision"
    }

    if (multifileList.length > file_required) {
      mutateCreate(data, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully Uploaded",
            })
            var filename = multifileList.map((item) => item.name)
            console.log("filename", filename)

            addHistoryLog(
              {
                page: "Documents",
                key: "upload document",
                old_data: "",
                new_data: JSON.stringify(filename),
                method: "upload-document",
                // consultant: details[0].eventInfo.title,
              },
              { onSuccess: (res) => {} }
            )

            getfilelist()
            setMultiFileList([])
            form.resetFields()

            history.push(`/documents/my-uploads`)
            setTabIndex(1)
          }
        },
        onError: (res) => {},
      })
    } else {
      notification.warning({
        message: "Warning",
        description: notification_message,
      })
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page_number: 1,
    })
  }

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "..."
    } else {
      return str
    }
  }

  const isImage = (file) => {
    let file_type = ["pdf", "docx", "doc"]

    if (file_type.includes(file)) {
      return false
    } else {
      return true
    }
  }

  const elementRef = useRef(null)

  useEffect(() => {
    setTabIndex(path === "/documents" ? 0 : path === "/documents/my-uploads" ? 1 : 2)

    if (path !== "/documents") {
      setTableFilter({
        ...tableFilter,
        type: path === "/documents/my-uploads" ? "user" : "consultant",
      })
    }
  }, [path])
  return (
    <Card className="upload-card card--padding-mobile">
      <Row>
        <Col md={24}>
          <Space direction="horizontal" className="user-client-docs">
            <Button
              type="primary"
              size="large"
              className={"btn-primary btn-with-svg btn-video " + (tabIndex === 0 && " btn-video-active")}
              icon={<FontAwesomeIcon icon={faFileUpload} />}
              onClick={() => {
                setTabIndex(0)
                history.push("/documents")
              }}
            >
              Upload Docs
            </Button>

            <Button
              type="primary"
              size="large"
              className={"btn-primary btn-with-svg btn-download " + (tabIndex === 1 && " btn-download-active")}
              icon={<FontAwesomeIcon icon={faFolderOpen} />}
              onClick={() => {
                setTabIndex(1)

                history.push("/documents/my-uploads")
              }}
            >
              My Uploads
            </Button>

            <Button
              type="primary"
              size="large"
              className={"btn-primary btn-with-svg btn-download " + (tabIndex === 2 && " btn-download-active")}
              icon={<FontAwesomeIcon icon={faFolderOpen} />}
              onClick={() => {
                setTabIndex(2)

                history.push("/documents/consultant-uploads")
              }}
            >
              Consultant Uploads
            </Button>
          </Space>
        </Col>
      </Row>
      {tabIndex == 0 ? (
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={8} className="upload-doc-row m-t-md">
            <Col xs={24} sm={24} md={24} lg={20} xl={9} xxl={6}>
              <Card className="card-primary-new-primary" title="UPLOAD DOCUMENTS" size="10">
                <Space direction="vertical" className="w-100">
                  <Upload
                    className="venue-images"
                    listType="picture"
                    fileList={multifileList}
                    onChange={({ fileList: newFileList }) => {
                      var _file = newFileList
                      console.log(_file)
                      _file.map((row, key) => {
                        return (row.status = "done")
                      })

                      let _newFile = []
                      const allowedFileTypes = ["jpg", "jpeg", "png", "gif", "jpg", "doc", "docx", "pdf"]

                      _file.forEach((item) => {
                        const fileExtension = item.name.split(".").pop().toLowerCase()

                        if (allowedFileTypes.includes(fileExtension)) {
                          _newFile.push(item)
                        }
                      })

                      setMultiFileList(_newFile)
                    }}
                    beforeUpload={(file) => {
                      const isLt30M = file.size / 1024 / 1024 < 30 // Check if the file is smaller than 5MB

                      //   const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file is smaller than 5MB
                      const allowedFileTypes = ["jpg", "jpeg", "png", "gif", "jpg", "doc", "docx", "pdf"]
                      const fileExtension = file.name.split(".").pop().toLowerCase()

                      console.log("file.size", file.size)

                      if (!isLt30M) {
                        notification.warning({
                          message: "Error",
                          description: "File must be smaller than 30MB!",
                        })
                        return Upload.LIST_IGNORE
                      } else if (file.size <= 0) {
                        notification.warning({
                          message: "Error",
                          description: "File is invalid or corrupted!",
                        })
                        return Upload.LIST_IGNORE
                      }

                      if (!allowedFileTypes.includes(fileExtension)) {
                        notification.warning({
                          message: "Error",
                          description: "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX and PDF files.",
                        })
                        return Upload.LIST_IGNORE
                      }

                      return true // Allow the file to be uploaded
                    }}
                    onPreview={async (file) => {
                      let src = file.url
                      if (!src) {
                        src = await new Promise((resolve) => {
                          const reader = new FileReader()
                          reader.readAsDataURL(file.originFileObj)
                          reader.onload = () => resolve(reader.result)
                        })
                      }
                      const image = new Image()
                      image.src = src
                      const imgWindow = window.open(src)
                      imgWindow.document.write(image.outerHTML)
                    }}
                  >
                    <Button type="dashed" className="upload-btn">
                      <div className="upload-btn-description">
                        <p className="ant-upload-text">
                          <FontAwesomeIcon className="document-upload-icon" icon={faArrowUpFromBracket} />
                        </p>
                        <br />
                        <Typography.Text className="upload-title">{"Click or Drag to Upload." + (current_tag.includes("upload timeline sheet (current task)") || current_tag.includes("upload revised application and timeline (current task)") ? "" : "")}</Typography.Text>
                        <br />
                        <Typography.Text className="upload-title">{current_tag.includes("upload timeline sheet (current task)") || current_tag.includes("upload revised application and timeline (current task)") ? "Your Timeline Sheet and your Revised Application are needed here." : ""}</Typography.Text>

                        <br />
                        <br />

                        <Typography.Text>
                          <strong>Limit:</strong>{" "}
                          <span
                            style={{
                              color: "#58595b",
                              fontWeight: "lighter",
                            }}
                          >
                            30MB (Unlimited Number of Files)
                          </span>
                        </Typography.Text>
                        <br />
                        <Typography.Text>
                          <strong>Types:</strong>{" "}
                          <span
                            style={{
                              color: "#58595b",
                              fontWeight: "lighter",
                            }}
                          >
                            pdf, png, gif, jpg, jpeg, doc, docx.{" "}
                          </span>
                        </Typography.Text>
                        {/* <p
                      className="upload-instruction"
                      style={{ whiteSpaces: "break-word" }}
                    >
                      5MB limit. Allowed types: pdf, png, gif, jpg, jpeg, doc,
                      docx.
                    </p>
                    <p className="upload-instruction">
                      Unlimited number of documents can be uploaded to this
                      field.
                    </p> */}
                      </div>
                    </Button>
                  </Upload>

                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoadingCreate}
                    className="btn-primary btn-upload btn-with-svg m-t-sm"
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    size="large"
                    onMouseLeave={(e) => {
                      e.target.blur()
                    }}
                  >
                    Submit
                  </Button>
                </Space>
              </Card>
            </Col>
          </Row>
        </Form>
      ) : tabIndex == 1 ? (
        <Row>
          <Col className="m-t-md m-b-sm" sm={24} md={24}>
            <Typography.Text className="document-upload-title">MY UPLOADS</Typography.Text>
            <br />
            <Typography.Text>
              <strong>Note: </strong> Your uploaded documents will automatically be renamed according to ATC's nomenclature.
            </Typography.Text>
          </Col>
          <Col sm={24} md={24} className="upload-tbl-cont">
            <TableScrollOnTop tableRef={elementRef} />
            <div id="tbl1" style={{ marginTop: "-8px" }}>
              <Table className="ant-table-default ant-table-striped" dataSource={dataSource && dataSource} rowKey={(record) => record.id} pagination={false} bordered={true} onChange={handleTableChange} ref={elementRef} style={{ top: "0px" }} sticky>
                <Table.Column
                  title="Filename"
                  key="file_name"
                  sorter={true}
                  dataIndex="file_name"
                  width={"300px"}
                  render={(text, record) => {
                    return (
                      <>
                        <div
                          style={{
                            //    display: "inline-flex",
                            alignItems: "center",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <div className="table-action-btn">
                            <Button
                              style={{
                                color: "#365293",
                                marginRight: 7,
                                padding: 0,
                              }}
                              type="link"
                              onClick={() => {
                                handleDownloadFile(record.id)
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="table-link" />
                            </Button>
                            <Button
                              style={{
                                color: "#365293",
                                marginRight: 7,
                                padding: 0,
                              }}
                              type="link"
                              onClick={() => {
                                handlePreviewFile(record.id)
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} className="table-link" />
                            </Button>
                            <Tooltip title={text}>
                              <span>{truncateString(text.split(".")[0], 18) + "." + text.split(".")[1]}</span>
                            </Tooltip>{" "}
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
                <Table.Column title="Date Uploaded" key="created_date" dataIndex="created_date" defaultSortOrder="descend" sorter={true} width={"200px"} />
                <Table.Column
                  title="Original Filename"
                  key="original_file_name"
                  dataIndex="original_file_name"
                  sorter={true}
                  width={"200px"}
                  render={(text, record) => (
                    <Tooltip title={text}>
                      <p className="margin-bottom-0">{truncateString(text.split(".")[0], 15) + "." + text.split(".")[1]}</p>
                    </Tooltip>
                  )}
                />
              </Table>
            </div>
            <Col xs={24} sm={24} md={24}>
              <div className="ant-space-flex-space-between the-pagination the-pagination--view-user">
                <TableShowingEntries />
                <TablePagination paginationFilter={tableFilter} setPaginationFilter={setTableFilter} setPaginationTotal={tableTotal} showLessItems={true} showSizeChanger={false} />
              </div>
            </Col>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col className="m-t-md m-b-sm" sm={24} md={24}>
            <Typography.Text className="document-upload-title">CONSULTANT UPLOADS</Typography.Text>
            <br />
            <Typography.Text>
              <strong>Note: </strong> All of the consultant uploaded files are listed in here.
            </Typography.Text>
          </Col>
          <Col sm={24} md={24} className="upload-tbl-cont">
            <TableScrollOnTop tableRef={elementRef} />
            <div id="tbl1" style={{ marginTop: "-8px" }}>
              <Table className="ant-table-default ant-table-striped" dataSource={dataSource && dataSource} rowKey={(record) => record.id} pagination={false} bordered={true} onChange={handleTableChange} ref={elementRef} style={{ top: "0px" }} sticky>
                <Table.Column
                  title="Filename"
                  key="file_name"
                  sorter={true}
                  dataIndex="file_name"
                  width={"300px"}
                  render={(text, record) => {
                    return (
                      <>
                        <div
                          style={{
                            //    display: "inline-flex",
                            alignItems: "center",
                            whiteSpace: "break-spaces",
                          }}
                        >
                          <div className="table-action-btn">
                            <Button
                              style={{
                                color: "#365293",
                                marginRight: 7,
                                padding: 0,
                              }}
                              type="link"
                              onClick={() => {
                                handleDownloadFile(record.id)
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} className="table-link" />
                            </Button>
                            <Button
                              style={{
                                color: "#365293",
                                marginRight: 7,
                                padding: 0,
                              }}
                              type="link"
                              onClick={() => {
                                handlePreviewFile(record.id)
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} className="table-link" />
                            </Button>
                            <Tooltip title={text}>
                              <span>{truncateString(text.split(".")[0], 18) + "." + text.split(".")[1]}</span>
                            </Tooltip>{" "}
                          </div>
                        </div>
                      </>
                    )
                  }}
                />
                <Table.Column title="Date Uploaded" key="created_date" dataIndex="created_date" defaultSortOrder="descend" sorter={true} width={"200px"} />
                <Table.Column
                  title="Original Filename"
                  key="original_file_name"
                  dataIndex="original_file_name"
                  sorter={true}
                  width={"200px"}
                  render={(text, record) => (
                    <Tooltip title={text}>
                      <p className="margin-bottom-0">{truncateString(text.split(".")[0], 15) + "." + text.split(".")[1]}</p>
                    </Tooltip>
                  )}
                />
              </Table>
            </div>
            <Col xs={24} sm={24} md={24}>
              <div className="ant-space-flex-space-between the-pagination the-pagination--view-user">
                <TableShowingEntries />
                <TablePagination paginationFilter={tableFilter} setPaginationFilter={setTableFilter} setPaginationTotal={tableTotal} showLessItems={true} showSizeChanger={false} />
              </div>
            </Col>
          </Col>
        </Row>
      )}

      {fileSrc != "" && isImage(fileExt) && (
        <Image
          width={200}
          style={{ display: "none" }}
          src={fileSrc}
          preview={{
            visible,
            src: fileSrc,
            onVisibleChange: (value) => {
              setVisible(value)
              if (value == false) {
                setFileSrc("")
                setFileExt("")
              }
            },
          }}
        />
      )}
      <ModalFileView setToggleModal={setShowPdfDoc} toggleModal={showPdfDoc} file={fileSrc} type={fileExt} base64Var={base64File} />
    </Card>
  )
}

export default PageDocuments
