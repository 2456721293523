import React from "react";
import { Button, Row, Col, Space, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserCheck,
    faUserClock,
    faUserVneckHair,
    faUserXmark,
} from "@fortawesome/pro-regular-svg-icons";

export default function ComponentStatusMenu(props) {
    const { onClickedNotify, isLoadingInterviewFeedback } = props;

    return (
        <Row gutter={[10, 10]}>
            <Col sm={24} md={24} style={{ marginTop: "-10px" }}>
                <Typography.Text className="task-card-category">
                    Let us know how the interview went
                </Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} className="show-tell-us-1">
                <Space wrap size={[8, 12]} className="complete-btn-space w-100">
                    <Button
                        onClick={() => {
                            onClickedNotify("hired");
                        }}
                        size="small"
                        className="btn-primary btn-complete"
                        style={{
                            textTransform: "uppercase",
                            fontWeight: 300,
                        }}
                        loading={isLoadingInterviewFeedback}
                    >
                        {" "}
                        <FontAwesomeIcon
                            style={{ marginRight: "5px" }}
                            icon={faUserCheck}
                        />
                        HIRED !
                    </Button>

                    <Button
                        onClick={() => {
                            onClickedNotify("interview soon");
                        }}
                        size="small"
                        className="btn-primary btn-complete"
                        style={{
                            textTransform: "uppercase",
                            fontWeight: 300,
                        }}
                        loading={isLoadingInterviewFeedback}
                    >
                        {" "}
                        <FontAwesomeIcon
                            style={{ marginRight: "5px" }}
                            icon={faUserVneckHair}
                        />
                        INTERVIEW SOON
                    </Button>
                </Space>

                <Space
                    wrap
                    size={[8, 12]}
                    className="complete-btn-space complete-btn-space-m-b-sm w-100 m-b-sm"
                >
                    <Button
                        onClick={() => {
                            onClickedNotify("still waiting");
                        }}
                        size="small"
                        className="btn-primary btn-complete still-waiting-btn"
                        style={{
                            textTransform: "uppercase",
                            fontWeight: 300,
                        }}
                        loading={isLoadingInterviewFeedback}
                    >
                        {" "}
                        <FontAwesomeIcon
                            style={{ marginRight: "5px" }}
                            icon={faUserClock}
                        />
                        STILL WAITING
                    </Button>

                    <Button
                        onClick={() => {
                            onClickedNotify("end of job search");
                        }}
                        size="small"
                        className="btn-primary btn-complete"
                        style={{
                            textTransform: "uppercase",
                            fontWeight: 300,
                        }}
                        loading={isLoadingInterviewFeedback}
                    >
                        {" "}
                        <FontAwesomeIcon
                            style={{ marginRight: "5px" }}
                            icon={faUserXmark}
                        />
                        END OF JOB SEARCH
                    </Button>
                </Space>
            </Col>
        </Row>
    );
}
