import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Row, Space, Typography, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; //
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import ModalCancelAppointment from "../../Components/ModalCancelAppointment";
import { saveAs } from "file-saver";
import { GoPrimitiveDot } from "react-icons/go";

function PageMySchedule() {
  const [appointments, setAppointments] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [stage, setStages] = useState([]);

  const { mutate: mutateDownloadCSV } = POST(
    "expedited/calendar/download/csv",
    "download_calendar_csv"
  );

  const { mutate: mutateDownloadICS } = POST(
    "expedited/calendar/download/ics",
    "download_calendar_csv"
  );

  const arrayToCSV = (arr, delimiter = ",") =>
    arr
      .map((v) =>
        v
          .map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x))
          .join(delimiter)
      )
      .join("\n");

  const donwloadCSV = () => {
    let data = { appointments: appointments };

    console.log("data", appointments);

    mutateDownloadCSV(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([arrayToCSV(res.data)], {
            type: "text/csv;charset=utf-8",
          });
          // console.log("donwloadCSV", res);
          // saveAs(blob, "download.csv");
          // setAppointments(bookedAppointments);
          saveAs(
            blob,
            `${res?.client_name} Calendar Schedules - ${moment().format(
              "MM/DD/YYYY h:mm:ss a"
            )}.csv`
          );
        }
      },
    });
  };

  const donwloadICS = () => {
    let data = { appointments: appointments };

    mutateDownloadICS(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([res.data], {
            type: "text/calendar;charset=utf-8",
          });
          saveAs(blob, "download.ics");
        }
      },
    });
  };

  GET(
    "expedited/user/schedule",
    "schedule",
    (res) => {
      if (res.success) {
        let appointments = [];
        console.log("events:", res.pluck);
        res.data.map((item) => {
          let dateStartTime = item?.schedule_date + " " + item?.time_start;
          dateStartTime = moment(dateStartTime).format("YYYY-MM-DD HH:mm:ss");

          let dateEndTime = item?.schedule_date + " " + item?.time_end;
          dateEndTime = moment(dateEndTime).format("YYYY-MM-DD HH:mm:ss");
          if (item.appointmentStatus != "deleted") {
            appointments.push({
              extendedProps: item,
              title: `${item?.user.firstname} ${item?.user.lastname}`,
              start: dateStartTime,
              end: dateEndTime,
              id: item.id,
              status: item.status,
              color: "#4affbc",
              backgroundColor: "#7b7cf8",
            });
          }
        });

        setAppointments(appointments);
      }
    },
    false
  );

  GET("expedited/user/opportunity", "opportunity", (res) => {
    if (res.success) {
      setStages(res.pipeline_stages);
    }
  });

  const handleEventClick = (e) => {
    let appointment_data = [];

    let new_start_time = e.event.startStr.split("T");
    let new_end_time = e.event.endStr.split("T");
    let start_time = new_start_time[1].split("-");
    let end_time = new_end_time[1].split("-");

    appointment_data.push({
      date: moment(e.event.startStr).tz("MST").format("dddd, MMMM Do"),
      time_start: moment(new_start_time[0] + "T" + start_time[0]).format(
        "H:mm a"
      ),
      time_end: moment(new_end_time[0] + "T" + end_time[0]).format("H:mm a"),
      id: e,
      eventInfo: e.event,
    });

    setAppointmentDetails(appointment_data);
    setToggleModal(true);
  };

  // useEffect(() => {
  //   if (appointmentDetails.length > 0) {
  //     setToggleModal(true);
  //   }
  // }, [appointmentDetails]);

  const handleDateClick = (e) => {
    if (stage.length > 0) {
      if (stage[2].status != "wait") {
        let row = e.dateStr;
        if (moment(row).format("L") < moment().format("L")) {
          notification.warning({
            message: `Booking not allowed`,
            description: "You can't book current or previous dates.",
            placement: "bottom",
          });
        } else if (moment(row).format("dddd") === "Sunday") {
          notification.warning({
            message: `Booking not allowed`,
            description: "You can't book on weekends.",
            placement: "bottom",
          });
        } else {
          // setSelectedDate(row);
          // setToggleModal(true);
        }
      } else {
        notification.warning({
          message: `Booking not allowed`,
          description: "You are not yet on set appointment stage",
          placement: "bottom",
        });
      }
    }
  };

  const [toggleModal, setToggleModal] = useState(false);

  const renderEventContent = (eventInfo) => {
    let ext = eventInfo.event.extendedProps;
    let timeStart = moment(eventInfo.event.startStr).tz("MST").format("H:mm A");

    let timeEnd = moment(eventInfo.event.endStr).tz("MST").format("H:mm A");

    // console.log("eventInfo", ext);
    let classname = "";

    switch (ext.status) {
      case "showed":
        classname = "primitive-brown";
        break;
      case "cancelled" || "cancelled and delete":
        classname = "primitive-cancelled";
        break;
      case "noshow":
        classname = "primitive-warning";
        break;
      case null || "booked":
        classname = "primitive-success";
        break;
      default:
        break;
    }

    return (
      <div className="calendar-event-cont">
        <div
          style={{
            display: "flex",
            color: "#365293",
            paddingTop: "0px",
          }}
        >
          <span
            className="admin-calendar-status"
            style={{ padding: "0px", height: "10px" }}
          >
            <GoPrimitiveDot className={classname} size={15} />
          </span>
          <div className="calendar-event-cont-tittle">
            {ext.user.firstname + " " + ext.user.lastname}
          </div>
        </div>

        <div
          className={
            "time-cont " + (ext.client ? "time-booked" : "time-disabled")
          }
        >
          {ext.client ? (
            <span>{timeStart + "-" + timeEnd}</span>
          ) : (
            <span>{timeStart + "-" + timeEnd}</span>
          )}
        </div>
        {ext.client && (
          <div className={"client-name-cont " + classname}>
            {ext.client.firstname + " " + ext.client.lastname}
          </div>
        )}
      </div>
    );
  };

  const cRef = useRef(null);
  const gCalendar = useRef(null);
  const [calendarWidth, setCalendarWidth] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dayMaxEvent, setDayMaxEvent] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (cRef?.current) {
        setCalendarWidth(cRef.current.offsetWidth);
      }
    };

    if (cRef?.current) {
      const calendarApi = gCalendar?.current?.getApi();
      if (windowSize.width <= 662) {
        calendarApi?.changeView("timeGridDay");
      } else if (windowSize.width > 662 && windowSize.width <= 768) {
        calendarApi?.changeView("timeGridWeek");
      } else {
        calendarApi?.changeView("dayGridMonth");
      }
      const resizeObserver = new ResizeObserver(handleResize);
      if (cRef.current) {
        resizeObserver.observe(cRef.current);
      }
    }
  }, [windowSize.width]);

  const [viewOnRefresh, setViewOnRefresh] = useState("dayGridMonth");

  return (
    // <Card className="user-calendar-my-sched card--padding-mobile">
    <Card className="ard-min-height card--padding-mobile">
      <Legends />
      <Row gutter={8}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          // className="admin-calendar admin-calendar-bookings"
          className="admin-calendar admin-calendar-bookings consultant-calendar-bookings"
          style={{ marginTop: "18px" }}
          ref={cRef}
        >
          <FullCalendar
            key={`ss-book-${calendarWidth}`}
            ref={gCalendar}
            allDaySlot={false}
            height="auto"
            slotEventOverlap={false}
            eventMaxStack={dayMaxEvent}
            dayMaxEvents={dayMaxEvent}
            initialView={viewOnRefresh ? viewOnRefresh : "dayGridMonth"}
            timeZone="MST"
            displayEventEnd={{
              month: true,
              basicWeek: true,
              default: true,
            }}
            eventClick={(e) => handleEventClick(e)}
            // dateClick={(e) => handleDateClick(e)}
            customButtons={{
              downLoadCSV: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> Outlook/Google
                  </>
                ),
                hint: "Download Outlook/Google",
                click: function () {
                  donwloadCSV();
                },
              },
              downLoadICS: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> ICalendar
                  </>
                ),
                hint: "Download ICalendar",
                click: function () {
                  donwloadICS();
                },
              },
            }}
            eventContent={renderEventContent}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right:
                "downLoadCSV,downLoadICS dayGridMonth,timeGridWeek,timeGridDay",
            }}
            viewHint={(buttonText, buttonName) => {
              if (buttonName.match(/^dayGridMonth/)) {
                return "Month View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridWeek/)) {
                return "Week View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridDay/)) {
                return "Day View"; // results in "week list view"
              }
            }}
            events={appointments}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentTimezonePlugin,
            ]}
            datesSet={(e) => {
              setViewOnRefresh(e.view.type);

              if (windowSize.width <= 662) {
                setDayMaxEvent(0);
              } else if (windowSize.width > 662 && windowSize.width <= 991) {
                if (e?.view?.type === "dayGridMonth") {
                  setDayMaxEvent(3);
                } else {
                  setDayMaxEvent(0);
                }
              } else {
                setDayMaxEvent(3);
              }
            }}
          />
        </Col>
      </Row>

      <ModalCancelAppointment
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        //   setSelectedDate={setSelectedDate}
        details={appointmentDetails}
      />
    </Card>
  );
}

function Legends() {
  return (
    <div className="calendar-legend">
      <Space size={30}>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-success" />
          <Typography.Text className="legend-title">Booked</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-brown" />
          <Typography.Text className="legend-title">Attended</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-cancelled" />
          <Typography.Text className="legend-title">Cancelled</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-warning" />
          <Typography.Text className="legend-title">No Show</Typography.Text>
        </Space>
      </Space>
    </div>
  );
}

export default PageMySchedule;
