import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Image,
  Layout,
  Row,
  Typography,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import PageCheckoutComponentProductDetails from "../../private/PageUser/PageCheckout/PageCheckoutComponents/PageCheckoutComponentProductDetails";
import PageCheckoutComponentBillingDetails from "../../private/PageUser/PageCheckout/PageCheckoutComponents/PageCheckoutComponentBillingDetails";
import PageCheckoutComponentAdditionalInformation from "../../private/PageUser/PageCheckout/PageCheckoutComponents/PageCheckoutComponentAdditionalInformation";
import validateRules from "../../../providers/validateRules";
import {
  userData,
  encrypt,
  fullwidthlogo,
  description,
} from "../../../providers/companyInfo";
import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";
import optionCountryCodes from "../../../providers/optionCountryCodes";
import { POST, POSTNOLOADING } from "../../../providers/useAxiosQuery";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";

import { loadStripe } from "@stripe/stripe-js";

import { H } from "highlight.run";
import moment from "moment";
import ModalProductOffer from "./components/ModalProductOffer";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function PagePublicCheckoutExpedited(props) {
  const [form] = Form.useForm();
  const user = userData();
  const history = useHistory();
  const [couponCode, setCouponCode] = useState({
    coupon_name: "",
    coupon_value: "",
    coupon_type: "",
    coupon_amount: "",
    total: "",
  });

  const poductDetails = [
    {
      id: 1,
      name: "Expedited Editing",
      quantity: 1,
      amount: 0,
    },
  ];
  const totalAmount = poductDetails?.reduce(
    (total, item) => total + item?.quantity * Number(item?.amount),
    0
  );

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [optionBillingZip, setOptionBillingZip] = useState(
    /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/
  );
  const [countryValue, setCountryValue] = useState("Zip Code");

  const onChangeCountry = (val, opt) => {
    if (val === "United States") {
      setOptionState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setOptionState(stateCA);
      setOptionBillingZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (val === "Puerto Rico") {
      setOptionState(stateCA);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }
    setCountryValue(val);
    // form.resetFields(["state", "zip_code", "city"]);
  };

  const onChangeState = (val, opt) => {
    // form.resetFields(["zip_code"]);
  };

  const [toggleProductOffer, setToggleProductOffer] = useState(false);

  const [couponValue, setCouponValue] = useState(null);
  const handleApplyCoupon = (e) => {
    let coupon = couponValue;
    let totalAmount = poductDetails?.reduce(
      (total, item) => total + item?.quantity * Number(item?.amount),
      0
    );

    if (coupon) {
      mutateApplyCoupon(
        { code: coupon, role: "User" },
        {
          onSuccess: (res) => {
            if (res.success) {
              console.log(
                "res?.data?.max",
                res?.data?.max,
                res?.data?.redemption
              );
              if (Number(res?.data?.max) > Number(res?.data?.redemption)) {
                let discountData;
                if (res.data.type == "fixed") {
                  discountData = res.data.off;
                } else if (res.data.type == "percent") {
                  discountData = (res.data.off / 100) * totalAmount;
                }
                let total = calculateTotal(discountData, totalAmount);
                setCoupon(
                  res?.data?.coupon_name,
                  res?.data?.coupon_code,
                  res?.data?.type,
                  discountData,
                  total
                );
                setSubmitLabel(total >= 0.5 ? "PROCEED" : "PLACE ORDER");
                notification.success({
                  message: "Coupon Applied",
                  description: "Successfully applied coupon code.",
                });
              } else {
                handleError("Coupon Denied", "Coupon code is not available1");
              }
            } else {
              handleError("Coupon Denied", res.error);
            }
          },
          onError: (err) => {
            handleError("Coupon Denied", err.error);
          },
        }
      );
    } else {
      handleError("Coupon Denied", "Coupon code is not available");
    }
  };

  const setCoupon = (name, value, type, amount, total) => {
    setCouponCode({
      coupon_name: name,
      coupon_value: value,
      coupon_type: type,
      coupon_amount: amount,
      total: total,
    });
  };

  const handleError = (message, description) => {
    notification.error({
      message: message,
      description: description,
    });
    setCouponValue(null);
    setCoupon("", "", "", "", "");
  };

  const calculateTotal = (amount, totalAmount) => {
    let total = totalAmount - amount;
    total = total > 0 ? total : 0;
    total = total.toFixed(6);
    total = total.split(".");
    return `${total[0]}.${total[1].substr(0, 2)}`;
  };

  const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
    "api/v1/apply_coupon_stripe_system",
    "apply_coupon_stripe_system"
  );

  // stripe checkout start
  useEffect(() => {
    resetCheckoutData();
  }, []);

  const resetCheckoutData = () => {
    setCheckoutData({
      show: false,
      data: null,
    });

    setClientSecret("");

    localStorage.removeItem("checkoutShow");
  };

  const [checkoutData, setCheckoutData] = useState({
    show: localStorage.getItem("checkoutShow")
      ? Boolean(localStorage.getItem("checkoutShow"))
      : false,
    data: localStorage.getItem("checkoutData")
      ? JSON.parse(localStorage.getItem("checkoutData"))
      : null,
  });

  const [clientSecret, setClientSecret] = useState(
    localStorage.getItem("checkoutData")
      ? localStorage.getItem("checkoutData")
      : ""
  );

  const [submitLabel, setSubmitLabel] = useState("PLACE ORDER");
  const onFinish = (values) => {
    // for stripe array data
    let phone = values.phone;
    phone = phone.replace(/[^0-9]/g, "");
    let productNames = poductDetails.map((product) => product.name);
    let data = {
      ...values,
      amount: totalAmount,
      coupon_name: couponCode?.coupon_name,
      coupon_value: couponCode?.coupon_value,
      coupon_type: couponCode?.coupon_type,
      coupon_amount: couponCode?.coupon_amount,
      sub_total:
        couponCode?.total ||
        couponCode?.total === 0 ||
        couponCode?.total === "0"
          ? couponCode?.total
          : totalAmount,
      phone: phone,
      order_datails: productNames,
      product: productNames,
      isFreeConsultation: false,
      product_name: "Expedited Editing",
      purchase_from: "public",
    };

    mutateCheckout(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: res.message,
            description: res.description,
          });
          autoLogin(values.email);
        } else {
          if (res.error_type === "criteria") {
            setToggleProductOffer(true);
          } else {
            form.resetFields(["email", "phone"]);
            form.setFieldsValue({
              phone: "+" + data?.phone,
              email: data?.email,
            });
            if (res.array_message.includes("Email is already registered!")) {
              form.setFields([
                {
                  name: "email",
                  errors: ["Email is already registered!"],
                },
              ]);
            }

            if (res.array_message.includes("Phone is already registered!")) {
              form.setFields([
                {
                  name: "phone",
                  errors: ["Phone is already registered!"],
                },
              ]);
            }

            notification.warning({
              message: "Warning",
              description: (
                <div
                  dangerouslySetInnerHTML={{
                    __html: res.array_message.join("<br/>"),
                  }}
                />
              ),
            });
          }
        }
      },
    });
  };

  const { mutate: mutateCheckout, isLoading: isLoadingCheckout } = POST(
    "api/v1/checkout_order",
    "checkout_order"
  );

  const checkAccount = (value, type) => {
    if (type === "phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
          // form.submit();
        } else {
          form.setFields([
            {
              name: type,
              errors: [res.message],
            },
          ]);
        }
      },
      onError: (err) => {
        console.log("err", err);

        form.setFields([
          {
            name: type,
            errors: ["Error on checking " + type + "!"],
          },
        ]);
      },
    });
  };

  const { mutate: mutateCheckAccount } = POSTNOLOADING(
    "api/v1/check_account",
    "check_account",
    false
  );

  const { mutate: mutateAutoLogin } = POST("api/v1/auto_login", "auto_login");

  const autoLogin = (email, from_purchase = null) => {
    mutateAutoLogin(
      { email: email, from_purchase: from_purchase },
      {
        onSuccess: (res) => {
          if (res.success) {
            localStorage.removeItem("userdata_admin");
            localStorage.removeItem("viewas");

            localStorage.userdata = encrypt(res.data);
            localStorage.token = res.token;
            localStorage.hasLoggedIn = true;

            H.identify(res.data.firstname + " " + res.data.lastname, {
              id: res.data.id,
              email: res.data.email,
              username: res.data.username,
            });

            window.location.href = "/dashboard";
          }
        },
      }
    );
  };

  return (
    <Layout className="public-layout purchase-layout">
      <Layout.Content>
        <Row>
          <Col xs={24} sm={24} md={24} className="center-contents">
            <Image
              onClick={() => history.push("/")}
              className="zoom-in-out-box"
              src={fullwidthlogo}
              preview={false}
            />
            <Card className="card--padding-mobile card--public-body">
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={20}>
                  {checkoutData.show === false && (
                    <>
                      {/* Billing Details */}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                          className="main-1-collapse border-none"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faPlus}
                              />
                            )
                          }
                          defaultActiveKey={["1"]}
                          expandIconPosition="end"
                        >
                          <Collapse.Panel
                            header="Billing details"
                            key="1"
                            className="accordion bg-darkgray-form m-b-md collapse-min-height-0"
                          >
                            <PageCheckoutComponentBillingDetails
                              country={country}
                              onChangeCountry={onChangeCountry}
                              optionState={optionState}
                              optionBillingZip={optionBillingZip}
                              countryValue={countryValue}
                              onChangeState={onChangeState}
                              disabledEmail={false}
                              viewPublic={true}
                              checkAccount={checkAccount}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </Col>

                      {/* Additional information */}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                          className="main-1-collapse border-none"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faPlus}
                              />
                            )
                          }
                          defaultActiveKey={["1"]}
                          expandIconPosition="end"
                        >
                          <Collapse.Panel
                            header="Additional information"
                            key="1"
                            className="accordion bg-darkgray-form m-b-md collapse-additional-information collapse-min-height-0"
                          >
                            <PageCheckoutComponentAdditionalInformation
                              productDetails={poductDetails}
                              form={form}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </Col>

                      {/* Product Details */}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse
                          className="main-1-collapse border-none"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faMinus}
                              />
                            ) : (
                              <FontAwesomeIcon
                                style={{ color: "#325db8", fontSize: 18 }}
                                icon={faPlus}
                              />
                            )
                          }
                          defaultActiveKey={["1"]}
                          expandIconPosition="end"
                        >
                          <Collapse.Panel
                            header="Your order"
                            key="1"
                            className="accordion bg-darkgray-form m-b-md checkout-order-details"
                          >
                            <PageCheckoutComponentProductDetails
                              poductDetails={poductDetails}
                              couponCode={couponCode}
                            />
                          </Collapse.Panel>
                        </Collapse>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row
                          gutter={[12, 0]}
                          className="personal-info-1"
                          style={{ paddingTop: "8px" }}
                        >
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="m-t-n-sm agree-checkbox"
                          >
                            <Form.Item
                              name="agree"
                              valuePropName="checked"
                              rules={[validateRules.required]}
                            >
                              <Checkbox
                                onChange={(e) => {
                                  form.setFieldsValue({
                                    agree: e.target.checked ? true : undefined,
                                  });
                                }}
                              >
                                <Typography.Text strong>
                                  I have read and agree to the website{" "}
                                  <Link
                                    to="/terms-and-condition"
                                    target="_blank"
                                  >
                                    terms and conditions
                                  </Link>{" "}
                                  <span className="text-danger">*</span>
                                </Typography.Text>
                              </Checkbox>
                            </Form.Item>
                          </Col>

                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="m-t-n-sm m-b-md text-right"
                          >
                            <Button
                              type="primary"
                              size="large"
                              className="btn-primary btn-sign-in w-100"
                              onClick={() => {
                                form
                                  .validateFields()
                                  .then((values) => {
                                    onFinish(values);
                                  })
                                  .catch((info) => {
                                    notification.warning({
                                      message: "Warning",
                                      description:
                                        "Please Check Invalid Fields!",
                                    });
                                  });
                              }}
                              loading={isLoadingCheckout}
                              icon={
                                <FontAwesomeIcon
                                  icon={faForward}
                                  className="m-r-xs"
                                />
                              }
                            >
                              {submitLabel}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <ModalProductOffer
          toggleModal={toggleProductOffer}
          setToggleModal={setToggleProductOffer}
        />
      </Layout.Content>
      <Layout.Footer className="text-center" style={{ marginTop: 70 }}>
        <Typography.Text class="copyright-txt">
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved.
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
