import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Typography, Divider } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import companyInfo, {
  name,
  role,
  fullwidthwhitelogo,
  userData,
  workflow,
} from "../../providers/companyInfo";

import AdminSideMenu from "./RoleMenu/admin/AdminSideMenu";
import UserSideMenu from "./RoleMenu/user/UserSideMenu";
import ConsultantSideMenu from "./RoleMenu/consultant/ConsultantSideMenu";
import FloatSelect from "../../providers/FloatSelect";

export default function SideMenu(props) {
  const {
    history,
    sideMenuCollapse,
    setSideMenuCollapse,
    width,
    hideLink,
    userInfo,
    setNotifVilisble,
    workflowOptions,
    page,
  } = props;

  const setSideMenu = (menu) => {
    localStorage.setItem("current_workflow", menu);
    window.location.replace("/dashboard");
  };

  const [menuItems, setMenuItems] = useState([]);
  // const [userInfo, setUserInfo] = useState(userData());

  // console.log(window.location.origin);
  // console.log("role", role());
  // console.log("CaregiverSideMenu", CaregiverSideMenu);

  console.log("workflow options", workflowOptions);

  useEffect(() => {
    if (role() === "Admin") {
      setMenuItems(AdminSideMenu);
    } else if (role() === "User") {
      // setMenuItems(MainMenu);
      setMenuItems(UserSideMenu);
    } else if (role() === "Consultant") {
      setMenuItems(ConsultantSideMenu);
    }

    return () => {};
  }, []);

  let pathname = page;
  const [openKeys, setOpenKeys] = useState([]);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
  useEffect(() => {
    if (!sideMenuCollapse) {
      let path = history.location.pathname;
      path = path.split("/");
      path = "/" + path[1];
      if (path) {
        setOpenKeys([path]);
      }
    }
    let menu = menuItems.map((item) => item.path);
    setRootSubmenuKeys(menu);
  }, [pathname, menuItems, sideMenuCollapse]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const activeRoute = (routeName) => {
    return page === routeName ? "ant-menu-item-selected" : "";
  };

  const activeSubRoute = (routeName) => {
    const pathname1 = page;
    const pathname2 = routeName;
    return pathname1 === pathname2 ? "ant-menu-item-selected" : "";
  };

  const handleMenuRender = () => {
    let items = [];

    menuItems.map((item, index) => {
      if (item.children && item.children.length > 0) {
        let children_list = item.children.map((item2) => {
          let link = "";

          if (item2.targetNew === 1) {
            link = (
              <Typography.Link
                target="new"
                href={window.location.origin + item2.path}
              >
                {item2.title ?? item2.permission}
              </Typography.Link>
            );
          } else {
            link = (
              <Link to={item2.path}>{item2.title ?? item2.permission}</Link>
            );
          }

          if (
            item2.path !== "#8-hour-application" &&
            item2.path !== "#expedited-editing"
          ) {
            return {
              key: item2.path,
              className: activeSubRoute(item2.page),
              label: link,
              onClick: () => {
                if (width < 768) {
                  setSideMenuCollapse(true);
                }
              },
            };
          } else {
            if (
              (item2.path === "#8-hour-application" &&
                workflowOptions.find(
                  (workflowItem) =>
                    workflowItem.value.toLowerCase() ===
                    "eight hour application"
                )) ||
              (item2.path === "#expedited-editing" &&
                workflowOptions.find(
                  (workflowItem) =>
                    workflowItem.value.toLowerCase() === "expedited editing"
                ))
            ) {
              return {
                key: item2.path,
                className:
                  item2.path === "#8-hour-application" &&
                  companyInfo().current_workflow === "8 hour application"
                    ? "ant-menu-item-selected"
                    : item2.path === "#expedited-editing" &&
                      companyInfo().current_workflow === "expedited editing"
                    ? "ant-menu-item-selected"
                    : "",
                label: link,
                onClick: () => {
                  setSideMenu(
                    item2.path === "#8-hour-application"
                      ? "8 hour application"
                      : "expedited editing"
                  );
                  // if (width < 768) {
                  //   setSideMenuCollapse(true);
                  // }
                },
              };
            }
          }
        });

        if (children_list && children_list.length > 0) {
          items.push({
            key: item.path,
            icon: item.icon,
            label: item.title,
            className: item.className ?? "",
            children: children_list,
          });
          return "";
        }
      } else {
        if (item.targetNew === 1) {
          items.push({
            key: item.path,
            icon: item.icon,
            label: (
              <Typography.Link
                target="new"
                href={window.location.origin + item.path}
              >
                {item.title ?? item.permission}
              </Typography.Link>
            ),
            className: activeRoute(item.page) + " " + (item.className ?? ""),
          });
        } else {
          items.push({
            key: item.path,
            icon: item.icon,
            label: (
              <Link
                onClick={() => {
                  setOpenKeys([]);
                }}
                to={
                  hideLink.link === item.className && hideLink.isHide
                    ? "#!"
                    : item.className === "MNDA" && !userInfo.expedited_mnda
                    ? "#!"
                    : item.path
                }
              >
                {item.title ?? item.permission}
              </Link>
            ),
            className:
              activeRoute(item.page) +
              " " +
              (item.className ?? "") +
              " " +
              (hideLink.link === item.className &&
              hideLink.hasDisabledClass !== ""
                ? hideLink.hasDisabledClass
                : "") +
              (item.className === "MNDA" && !userInfo.expedited_mnda
                ? "isDisabled"
                : ""),
            onClick: () => {
              if (width < 768) {
                setSideMenuCollapse(true);
              }
            },
          });
        }

        return "";
      }

      return "";
    });

    return items;
  };

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={sideMenuCollapse}
      // className="scrollbar-2" // live
      // className="scrollbar-2 staging-color" // staging
      className={`scrollbar-2 ${
        process.env.REACT_APP_SIDE_MENU_MODE === "staging"
          ? "staging-color"
          : ""
      }`}
      onClick={() => {
        setNotifVilisble(false);
      }}
    >
      <div className="ant-side-header">
        <MenuUnfoldOutlined
          id="btn_sidemenu_collapse_unfold"
          onClick={() => {
            setSideMenuCollapse(false);
          }}
          style={{ display: sideMenuCollapse ? "block" : "none" }}
        />
        <MenuFoldOutlined
          id="btn_sidemenu_collapse_fold"
          onClick={() => {
            setSideMenuCollapse(true);
          }}
          style={{ display: !sideMenuCollapse ? "block" : "none" }}
        />

        {!sideMenuCollapse && width > 480 && (
          <img src={fullwidthwhitelogo} alt={name} width="180px" />
        )}
      </div>

      {/* {role() === "User" && (
        <div className="m-b-xs sidemenu-select">
          <FloatSelect
            label="Consultaion Workflow"
            placeholder="Consultaion Workflow"
            value={currentSideMenu}
            onChange={(value) => {
              setCurrentSideMenu(value);
            }}
            options={workflowOptions}
          />
        </div>
      )} */}

      <Menu
        mode="inline"
        theme="light"
        className="sideMenu"
        openKeys={openKeys}
        selectedKeys={openKeys}
        onOpenChange={onOpenChange}
        items={handleMenuRender()}
      />
    </Layout.Sider>
  );
}
