import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Row,
  Space,
  Typography,
  Upload,
  notification,
} from "antd";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMaskDate from "../../../../providers/FloatInputMaskDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";

export default function ComponentRegisterStep3(props) {
  const {
    form,
    setCaptchaToken,
    captchaTokenError,
    ReCAPTCHA,
    windowSize,
    isLoadingRegister,
    setCompletePurchaseErr,
    onFinishInfomation,
    completePurchaseErr,
    multifileList,
    setMultiFileList,
  } = props;

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinishInfomation}
        form={form}
        // scrollToFirstError
      >
        <Space direction="vertical" className="w-100">
          <Upload
            className="venue-images"
            listType="picture"
            fileList={multifileList}
            onChange={({ fileList: newFileList }) => {
              var _file = newFileList;
              // console.log(_file);
              _file.map((row, key) => {
                return (row.status = "done");
              });

              let _newFile = [];

              const allowedFileTypes = [
                "jpg",
                "jpeg",
                "png",
                "gif",
                "jpg",
                "doc",
                "docx",
                "pdf",
              ];

              _file.forEach((item) => {
                const fileExtension = item.name.split(".").pop().toLowerCase();

                if (allowedFileTypes.includes(fileExtension)) {
                  _newFile.push(item);
                }
              });

              setMultiFileList(_newFile);
            }}
            beforeUpload={(file) => {
              const isLt30M = file.size / 1024 / 1024 < 30; // Check if the file is smaller than 5MB

              // const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file is smaller than 5MB
              const allowedFileTypes = [
                "jpg",
                "jpeg",
                "png",
                "gif",
                "jpg",
                "doc",
                "docx",
                "pdf",
              ];
              const fileExtension = file.name.split(".").pop().toLowerCase();

              if (!isLt30M) {
                notification.warning({
                  message: "Error",
                  description: "File must be smaller than 30MB!",
                });
                return Upload.LIST_IGNORE;
              } else if (file.size <= 0) {
                notification.warning({
                  message: "Error",
                  description: "File is invalid or corrupted!",
                });
                return Upload.LIST_IGNORE;
              }

              // console.log(fileExtension);

              if (!allowedFileTypes.includes(fileExtension)) {
                notification.warning({
                  message: "Error",
                  description:
                    "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX, PDF files.",
                });
                return Upload.LIST_IGNORE;
              }

              return false; // Allow the file to be uploaded
            }}
            // onPreview={async (file) => {
            //   let src = file.url;
            //   if (!src) {
            //     src = await new Promise((resolve) => {
            //       const reader = new FileReader();
            //       reader.readAsDataURL(file.originFileObj);
            //       reader.onload = () => resolve(reader.result);
            //     });
            //   }
            //   const image = new Image();
            //   image.src = src;
            //   const imgWindow = window.open(src);
            //   imgWindow.document.write(image.outerHTML);
            // }}
          >
            <Button type="dashed" className="upload-btn">
              <div className="upload-btn-description">
                <p className="ant-upload-text">
                  <FontAwesomeIcon
                    className="document-upload-icon"
                    icon={faArrowUpFromBracket}
                  />
                </p>
                <br />
                <Typography.Text className="upload-title">
                  Click or Drag{" "}
                </Typography.Text>
                <br />
                <Typography.Text className="upload-title">
                  Documents to Upload{" "}
                </Typography.Text>

                <br />
                <br />

                <Typography.Text className="upload-sm-desc">
                  <strong>Limit:</strong>{" "}
                  <span
                    style={{
                      color: "#58595b",
                      fontWeight: "lighter",
                    }}
                  >
                    30MB (Unlimited Number of Files)
                  </span>
                </Typography.Text>
                <br />
                <Typography.Text className="upload-sm-desc">
                  <strong>Types:</strong>{" "}
                  <span
                    style={{
                      color: "#58595b",
                      fontWeight: "lighter",
                    }}
                  >
                    pdf, png, gif, jpg, jpeg, doc, docx.{" "}
                  </span>
                </Typography.Text>
                {/* <p
                      className="upload-instruction"
                      style={{ whiteSpaces: "break-word" }}
                    >
                      5MB limit. Allowed types: pdf, png, gif, jpg, jpeg, doc,
                      docx.
                    </p>
                    <p className="upload-instruction">
                      Unlimited number of documents can be uploaded to this
                      field.
                    </p> */}
              </div>
            </Button>
          </Upload>

          {/* <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            onFinish();
                        }}
                        //    loading={isLoadingButtonLogin}
                        className="btn-primary btn-upload btn-with-svg m-t-sm"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        size="large"
                        onMouseLeave={(e) => {
                            e.target.blur();
                        }}
                    >
                        Submit
                    </Button> */}
        </Space>

        <div className="m-t-md">
          <Typography.Text>
            This page is protected by reCAPTCHA, and subject to the Google{" "}
            <Typography.Link
              href="https://policies.google.com/privacy?hl=en"
              className="color-1"
              target="new"
            >
              Privacy Policy
            </Typography.Link>{" "}
            and{" "}
            <Typography.Link
              href="https://policies.google.com/terms?hl=en"
              className="color-1"
              target="new"
            >
              Terms of Services.
            </Typography.Link>
          </Typography.Text>
        </div>
        <>
          <div className="captchaDiv m-t-md" style={{ marginBottom: 15 }}>
            <ReCAPTCHA
              size={windowSize.innerWidth < 350 ? "compact" : "normal"}
              style={{ marginTop: 10 }}
              onChange={(token) => setCaptchaToken(token)}
              // theme="dark"
              render="explicit"
              className="captcha-registration"
              // render="explicit"
              sitekey="6LfNfXgfAAAAAAbDCNuzI35Cm0hW_YwJ4UC0TYgw"
              // onloadCallback={() => {}}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {captchaTokenError && (
                <span
                  style={{
                    color: "#dc3545",
                  }}
                >
                  Please Verify Captcha
                </span>
              )}
            </div>
          </div>
        </>

        <Form.Item>
          <Button
            type="primary"
            // htmlType="submit"
            className="btn-main btn-register-here"
            style={{ marginTop: "10px" }}
            loading={isLoadingRegister}
            size="large"
            block
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinishInfomation(values);
                })
                .catch((info) => {
                  console.log(info);
                  setCompletePurchaseErr({
                    type: "error",
                    message: "Please Check Invalid Fields!",
                  });
                });
            }}
          >
            SUBMIT
          </Button>
        </Form.Item>

        {completePurchaseErr.message && (
          <Alert
            className="m-t-sm m-b-sm register-alert"
            type={completePurchaseErr.type}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: completePurchaseErr.message,
                }}
              />
            }
          />
        )}
      </Form>
    </>
  );
}
