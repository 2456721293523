import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  notification,
  Modal,
  Tooltip,
  Form,
  Space,
} from "antd";
import {
  faArrowAltFromLeft,
  faPlus,
  faPencil,
  faTrashCan,
  faRotate,
  faFloppyDiskCircleArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from "jquery";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import { encrypt, key, role } from "../../../../providers/companyInfo";
import FloatSelect from "../../../../providers/FloatSelect2";
import {
  TablePageSize,
  TableGlobalSearch,
  TableShowingEntries,
  TablePagination,
} from "../../Components/ComponentTableFilter";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  faChevronDown,
  faDownload,
  faEye,
} from "@fortawesome/pro-regular-svg-icons";
import ModalAssignPassword from "../../Components/ModalAssignPassword";
import TableScrollOnTop from "../../../../providers/TableScrollOnTop";

export default function PageConsultant() {
  const history = useHistory();
  const [toggleModal, setToggleModal] = useState(false);
  const [appConsultant, setAppConsultant] = useState({
    id: 0,
    value: [],
  });

  const [consultantAssignement, setConsultantAssignement] = useState({
    eight_hour_application: {
      id: 0,
      value: [],
    },
    expedited_editing: {
      id: 0,
      value: [],
    },
  });

  const { mutate: mutateViewAs, isLoading: isLoadingViewAs } = POST(
    "api/v1/admin/viewas",
    "admin-viewas"
  );

  const handleViewAs = (userId) => {
    let data = {
      id: userId,
    };

    mutateViewAs(data, {
      onSuccess: (res) => {
        let useradmin = localStorage.getItem("userdata");

        localStorage.userdata = encrypt(res.data);
        localStorage.current_workflow = res.data.current_workflow;
        localStorage.token = res.token;
        localStorage.userdata_admin = useradmin;
        localStorage.viewas = true;
        var url = window.location.origin;
        window.location.href = url;
      },
    });
  };

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "firstname",
    sort_order: "desc",
  });

  const options = [
    {
      label: "Call 1",
      value: "call 1",
    },
    {
      label: "Call 2",
      value: "call 2",
    },
    {
      label: "Follow up",
      value: "follow up call (optional)",
    },
    {
      label: "Timeline",
      value: "timeline",
    },
    {
      label: "Pre publish",
      value: "pre-publish",
    },
    {
      label: "One Hour Update",
      value: "one hour update",
    },
    {
      label: "Pre interview",
      value: "pre-interview",
    },
  ];

  const optionsExpeditedApp = [
    { label: "Call 1", value: "call 1" },
    { label: "Call 2", value: "call 2" },
  ];

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [consultantRole, setConsultantRole] = useState("");
  const [consultantID, setConsultantID] = useState(0);
  const [userId, setUserId] = useState(0);

  const { refetch: getConsultants } = GET(
    `api/v1/consultant/getrecord?${$.param(tableFilter)}`,
    "get_consultants",
    (res) => {
      if (res.success) {
        // console.log("dataTable", res);

        setDataSource(res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  const [searchText, setSearchText] = useState("");
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    getConsultants();
    // console.log("tableFilter", tableFilter);
    return () => {};
  }, [tableFilter]);

  const confirm = (role) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Change " + role + " role?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        changeConsultantRole();
      },
    });
  };

  const {
    mutate: mutateAssignConsultant,
    isLoading: isLoadingAssignConsultant,
  } = POST("api/v1/consultant/changerole", "consultant_changerole");

  const assignConsultant = (id) => {
    let data = {
      role: consultantRole,
      id: id,
    };

    // console.log("data:", data);

    mutateAssignConsultant(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Role successfully changed ",
          });

          setConsultantID(0);
          setConsultantRole("");
          getConsultants();
        }
      },
    });
  };

  const { mutate: mutateAppConsultant, isLoading: isLoadingAppConsultant } =
    POST("api/v1/appointment/assign_consultant", "get_consultants");

  const handleAppConsultant = (id, value, type) => {
    let data = {
      assigned_app: value,
      id: id,
      type: type,
    };

    console.log("data:", data);

    mutateAppConsultant(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Consultant Assignments",
            description: "Successfully updated.",
          });
          setAppConsultant({
            id: 0,
            value: [],
          });
          setChangeFor(false);
        }
      },
    });
  };

  const { mutate: mutateChangeRole, isLoading: isLoadingChangeRole } = POST(
    "api/v1/consultant/changerole",
    "consultant_changerole"
  );

  const changeConsultantRole = () => {
    let data = {
      role: consultantRole,
      id: consultantID,
    };

    mutateChangeRole(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Role successfully changed ",
          });

          setConsultantID(0);
          setConsultantRole("");
          getConsultants();
        }
      },
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page_number: 1,
    });
  };
  // const { mutate: mutateSync, isLoading: isLoadingCreate } = POST(
  //   "api/v1/consultant/updaterecord",
  //   "consultant_sync"
  // );

  // const handleSyncConsultants = () => {
  //   let data = { link_origin: window.location.origin };

  //   mutateSync(data, {
  //     onSuccess: (res) => {
  //       if (res.success) {
  //         // console.log("data-from-sync", res.data);
  //         notification.success({
  //           message: "Success",
  //           description: "Updated ",
  //         });

  //         getConsultants();
  //       }
  //     },
  //   });
  // };

  useEffect(() => {
    if (consultantRole != "" && consultantID != 0) {
      confirm(consultantRole);
    }
  }, [consultantRole, consultantID]);

  const elementRef = useRef(null);

  const [changeFor, setChangeFor] = useState({
    hasChange: false,
    for: "",
  });

  const [changeForOldData, setChangeForOldData] = useState("");
  useEffect(() => {
    if (changeFor.hasChange) {
      console.log("changeFor", consultantAssignement[changeFor.for].id);

      handleAppConsultant(
        consultantAssignement[changeFor.for].id,
        consultantAssignement[changeFor.for].value,
        changeFor.for
      );
      setChangeFor({ hasChange: false, for: "" });
    }
  }, [changeFor]);

  return (
    <Card className="card--padding-mobile page-consultant">
      <Row>
        <Col xs={24} sm={24} md={24}>
          <Button
            size="large"
            className="atc-btn-opposite  b-r-none"
            onClick={() => {
              history.push({
                pathname: `/consultant/register`,
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="m-r-sm" /> Add Consultant
          </Button>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Row gutter={12} className="per-page-search-notification">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={18}
              className="per-page-search-notification-input"
            >
              <Space>
                <TablePageSize
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <TableGlobalSearch
                paginationFilter={searchText}
                setPaginationFilter={setSearchText}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <TableScrollOnTop tableRef={elementRef} />
          <div id="tbl1" style={{ marginTop: "-8px" }}>
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={dataSource && dataSource}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              onChange={handleTableChange}
              ref={elementRef}
              style={{ top: "0px" }}
              sticky
            >
              <Table.Column
                title="First Name"
                key="firstname"
                align="left"
                dataIndex="firstname"
                defaultSortOrder="descend"
                sorter={true}
                width={"200px"}
              />

              <Table.Column
                title="Last Name"
                key="lastname"
                align="left"
                dataIndex="lastname"
                sorter={true}
                width={"200px"}
              />

              <Table.Column
                title="Role"
                key="role"
                align="left"
                dataIndex="role"
                width={"150px"}
                render={(text, record) => {
                  return (
                    <span style={{ position: "relative", right: "21px" }}>
                      {text}
                    </span>
                  );
                }}
              />

              <Table.Column
                title="View Schedule"
                key="schedule"
                width={"150px"}
                dataIndex="id"
                align="center"
                render={(id) => {
                  return (
                    <Button
                      type="link"
                      className="light-blue-link table-link"
                      onClick={() => {
                        history.push({
                          pathname: `/consultant/schedule/${id}`,
                        });
                      }}
                    >
                      Schedule
                    </Button>
                  );
                }}
              />

              <Table.Column
                title="Setup Password"
                key="setup_password"
                width={"150px"}
                dataIndex="id"
                align="center"
                render={(record) => {
                  return (
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col md={24}>
                        <Button
                          type="link"
                          className="light-blue-link table-link"
                          onClick={() => {
                            setUserId(record);
                            setToggleModal(true);
                          }}
                        >
                          Setup Password
                        </Button>
                      </Col>
                    </Row>
                  );
                }}
              />

              <Table.Column
                title={
                  <>
                    Consultant Assignments <br /> (8 Hour Application)
                  </>
                }
                key="appointment_assigned"
                width={"320px"}
                dataIndex="id"
                align="left"
                render={(_, record) => {
                  let initialValue = record?.appointment_consultant
                    ? JSON.parse(record?.appointment_consultant)
                    : [];
                  // setChangeForOldData(record?.appointment_consultant);
                  return (
                    <Form initialValues={{ assignment: initialValue }}>
                      <Row
                        className="consultant-register-grid"
                        style={{ position: "relative", right: "31px" }}
                      >
                        <Col>
                          <div className="consultant-register-assigned">
                            <Form.Item name="assignment">
                              <FloatSelect
                                label="Consultant for"
                                placeholder={
                                  <>
                                    Assign{" "}
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </>
                                }
                                multi="multiple"
                                options={options}
                                defaultValue={initialValue}
                                onChange={(value) => {
                                  setConsultantAssignement({
                                    ...consultantAssignement,
                                    eight_hour_application: {
                                      id: record.id,
                                      value: value,
                                    },
                                  });
                                }}
                                onBlur={(e) => {
                                  if (
                                    consultantAssignement
                                      ?.eight_hour_application?.value?.length >
                                    0
                                  ) {
                                    setChangeFor({
                                      hasChange: true,
                                      for: "eight_hour_application",
                                    });
                                  }
                                }}
                                onDeselect={(e) => {
                                  setChangeFor({
                                    hasChange: true,
                                    for: "eight_hour_application",
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              />

              <Table.Column
                title={
                  <>
                    Consultant Assignments <br /> (Expedited Editing)
                  </>
                }
                key="expedited_consultant_appointment"
                width="320px"
                dataIndex="id"
                align="left"
                render={(_, record) => {
                  let initialValue = record?.expedited_appointment_consultants
                    ? JSON.parse(record?.expedited_appointment_consultants)
                    : [];

                  return (
                    <Form initialValues={{ assignment: initialValue }}>
                      <Row
                        className="consultant-register-grid"
                        style={{ position: "relative", right: "31px" }}
                      >
                        <Col>
                          <div className="consultant-register-assigned">
                            <Form.Item name="assignment">
                              <FloatSelect
                                label="Consultant for"
                                placeholder={
                                  <>
                                    Assign{" "}
                                    <FontAwesomeIcon icon={faChevronDown} />
                                  </>
                                }
                                multi="multiple"
                                options={optionsExpeditedApp}
                                onChange={(value) => {
                                  initialValue = value;
                                  setConsultantAssignement({
                                    ...consultantAssignement,
                                    expedited_editing: {
                                      id: record.id,
                                      value: value,
                                    },
                                  });
                                }}
                                onBlur={() => {
                                  if (
                                    consultantAssignement?.expedited_editing
                                      ?.value?.length > 0
                                  ) {
                                    setChangeFor({
                                      hasChange: true,
                                      for: "expedited_editing",
                                    });
                                  }
                                }}
                                onDeselect={() => {
                                  setChangeFor({
                                    hasChange: true,
                                    for: "expedited_editing",
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              />

              <Table.Column
                title="View As"
                key="View as"
                width={"100px"}
                render={(text, record) => {
                  return (
                    <Button
                      type="link"
                      className="light-blue-link "
                      onClick={() => {
                        handleViewAs(record.id);
                      }}
                      loading={isLoadingViewAs}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ marginRight: "5px" }}
                        className="table-link"
                      />
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <div className="ant-space-flex-space-between the-pagination the-pagination--m-b">
            <TableShowingEntries />
            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={tableTotal}
              showLessItems={true}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>

      <ModalAssignPassword
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        id={userId}
      />
    </Card>
  );
}
