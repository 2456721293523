import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMaskDate from "../../../../providers/FloatInputMaskDate";
import ModalProductOffer from "./ModalProductOffer";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { POST } from "../../../../providers/useAxiosQuery";

export default function ComponentRegisterStep2(props) {
  const {
    form,
    isLoadingRegister,
    onFinishInfomation,
    completePurchaseErr,
    setCollapseActiveKey,
  } = props;

  const [toggleProductOffer, setToggleProductOffer] = useState(false);

  const { mutate: mutateCheckCriteria, isLoading: isLoadingCheckCriteria } =
    POST("expedited/check_criteria", "criteria");

  const [criteriaValues, setCriteriaValues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
  });

  const confirm = () => {
    //     Modal.confirm({
    //         title: "Before Proceeding",
    //         icon: <ExclamationCircleOutlined />,
    //         content: "Are you sure you've checked all the necessary boxes?",
    //         okText: "Yes",
    //         cancelText: "No",
    //         onOk: () => {
    // let checkedValues = form.getFieldValue("evaluation")
    //   ? form.getFieldValue("evaluation")
    //   : [];

    if (!Object.values(criteriaValues).some((value) => value === "")) {
      let values = { evaluation: criteriaValues };

      mutateCheckCriteria(values, {
        onSuccess: (res) => {
          if (res.success) {
            if (res.haspassed) {
              setCollapseActiveKey("3");
            } else {
              setToggleProductOffer(true);
            }
          }
        },
      });
    } else {
      notification.warning({
        message: "Fields are empty",
        description: "choose yes or no for all the fields before proceeding",
      });
    }

    //     },
    // });
  };

  const checkboxOptions = [
    {
      label:
        "Do you hold foreign airman certificates, have performed foreign military service, have received foreign education, or have operated commercially non-FAR 91, 135, 121 operations? ",
      value: "1",
    },
    {
      label: "Did you exit high school BEFORE 2013? ",
      value: "2",
    },
    {
      label:
        "Do you have any legal trouble/issues beyond 5 moving violations? (DUI, License suspension, Arrests, Minor in Possession/Comsumption, Felonies, Misdemeanors, Reckless Driving) ",
      value: "3",
    },
    {
      label:
        "Do you have any aviation accidents, incidents, investigations, or any failed Part 121/135 checkrides or stage checks? Do you have more than two (2) failed checkrides/stage checks? ",
      value: "4",
    },
    {
      label:
        "Have you performed any military training as a pilot? (Due to the significant details of your training history, expedited editing is not available. Please sign up for the free application analysis so we can give you personalized guidance. ",
      value: "5",
    },
    {
      label:
        "Have you ever been fired, terminated from a job or training from a previous job, aviation or not?",
      value: "6",
    },
    {
      label: "Have you ever lost flight status?",
      value: "7",
    },
    {
      label: "Do you have any lost or damaged logbooks/flight records?",
      value: "8",
    },
  ];

  useEffect(() => {
    console.log("criteriaValues", criteriaValues);
  }, [criteriaValues]);

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinishInfomation}
        form={form}
        // scrollToFirstError
      >
        <Typography.Text className="form-title">
          Additional Details
        </Typography.Text>
        {/* <Form.Item name="evaluation"> */}
        {checkboxOptions.map((option, index) => (
          <Space
            align="start"
            style={{
              marginBottom: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
            direction="horizontal"
            key={index}
          >
            <>{option.label}</>
            <Radio.Group
              key={option.value}
              style={{ display: "flex" }}
              value={criteriaValues[option.value]} // This should match the value prop of the selected Radio button
              onChange={(e) => {
                let selectedValue = e.target.value;
                setCriteriaValues({
                  ...criteriaValues,
                  [option.value]: selectedValue, // Update the state for the current option
                });
              }}
            >
              <Radio value="yes">Yes</Radio> // The value prop is a string
              <Radio value="no">No</Radio> // The value prop is a string
            </Radio.Group>
          </Space>
        ))}
        {/* </Form.Item> */}

        <Button
          type="primary"
          // htmlType="submit"
          className="btn-main btn-register-here"
          style={{ marginTop: "10px" }}
          loading={isLoadingCheckCriteria}
          size="large"
          block
          onClick={() => {
            confirm();
            // let checkedValues = form.getFieldValue("evaluation")
            //     ? form.getFieldValue("evaluation")
            //     : [];
            // if (checkedValues.length === checkboxOptions.length) {
            //     setCollapseActiveKey("3");
            // } else {
            //     setToggleProductOffer(true);
            // }
            // console.log("evaluation", form.getFieldValue("evaluation"));
          }}
        >
          NEXT
        </Button>

        {completePurchaseErr.message && (
          <Alert
            className="m-t-sm m-b-sm register-alert"
            type={completePurchaseErr.type}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: completePurchaseErr.message,
                }}
              />
            }
          />
        )}
      </Form>
      <ModalProductOffer
        toggleModal={toggleProductOffer}
        setToggleModal={setToggleProductOffer}
      />
    </>
  );
}
