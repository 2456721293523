import React from "react"
import { Button, Col, Form, Modal, Row, Typography, notification } from "antd"
//import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import { POST } from "../../../../providers/useAxiosQuery"

export default function ModalProductOffer(props) {
  const { toggleModal, setToggleModal, user } = props

  const { mutate: mutateTask, isLoading: isLoadingPassword } = POST("api/v1/task", "store")

  const [form] = Form.useForm()

  // const onFinishForm = (values) => {
  //     let data = {
  //         ...values,
  //         id: user.id,
  //     };
  //     console.log("onFinishForm", data);

  //     mutateTask(data, {
  //         onSuccess: (res) => {
  //             if (res.success) {
  //                 // console.log(res)
  //                 notification.success({
  //                     message: "Success",
  //                     description: "Successfully updated",
  //                 });
  //                 setToggleModal(false);
  //                 // if (userdata.role !== "Admin" && userdata.role !== "Manager") {
  //                 // localStorage.removeItem("userdata");
  //                 // localStorage.removeItem("token");
  //                 // window.location.replace("/");
  //             }
  //         },
  //     });
  // };

  return (
    <Modal
      title={"Product Offer"}
      open={toggleModal}
      footer={
        null
        // <>
        //     <Button
        //         size="large"
        //         onClick={() => {
        //             form.validateFields()
        //                 .then((values) => {
        //                     onFinishForm(values);
        //                 })
        //                 .catch((info) => {
        //                     console.log("Validate Failed:", info);
        //                 });
        //         }}
        //         className="btn-main-invert-outline"
        //     >
        //         Add New Task
        //     </Button>
        // </>
      }
      onCancel={() => setToggleModal(false)}
      className="modal-primary-default modal-change-2-factor-authentication"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={24}>
            <Typography.Text className="form-title">It seems you have not met our criteria for expedited editing. Please purchase one of the following products to join our alternative consultation program.</Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              className="btn-main"
              style={{ marginTop: "10px" }}
              size="large"
              block
              onClick={() => {
                window.location.href = "https://system.airlinetc.com/purchase/free-application-analysis"
              }}
            >
              {" "}
              Free Application Analysis
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Button
              type="primary"
              className="btn-main"
              style={{ marginTop: "10px" }}
              size="large"
              block
              onClick={() => {
                window.location.href = "https://system.airlinetc.com/purchase/eight-hour-application-construction"
              }}
            >
              {" "}
              Eight Hour Application Construction
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
