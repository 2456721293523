import React, { useState } from "react";
import { Alert, Button, Col, Form, Image, Row, Space, Typography } from "antd";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMaskDate from "../../../../providers/FloatInputMaskDate";

export default function ComponentRegisterStep1(props) {
  const {
    form,
    setCollapseActiveKey,
    optionState,
    optionZip,
    stateLabel,
    checkAccount,
    isLoadingRegister,
    setCompletePurchaseErr,
    onFinishInfomation,
    completePurchaseErr,
  } = props;

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinishInfomation}
        form={form}
        // scrollToFirstError
      >
        <Typography.Text className="form-title">
          User's Information
        </Typography.Text>
        <Form.Item
          name="firstname"
          className="m-t-sm"
          hasFeedback
          rules={[validateRules.required]}
        >
          <FloatInput label="First Name" placeholder="First Name" required />
        </Form.Item>

        <Form.Item name="lastname" hasFeedback rules={[validateRules.required]}>
          <FloatInput label="Last Name" placeholder="Last Name" required />
        </Form.Item>

        <Form.Item
          name="username"
          hasFeedback
          rules={[validateRules.required, validateRules.username]}
        >
          <FloatInput
            label="Username"
            placeholder="Username"
            required
            onBlurInput={(e) => checkAccount(e, "username")}
          />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[validateRules.required, validateRules.international_phone]}
          className="class-phone"
        >
          <FloatInputPhone
            required
            international={true}
            label="Phone"
            placeholder="Phone"
            onBlurInput={(e) => {
              checkAccount(e.target.value, "phone");
            }}
          />
        </Form.Item>

        <Form.Item name="birthdate" rules={[validateRules.birthday]}>
          <FloatInputMaskDate
            label="Date of Birth (MM/DD/YYYY)"
            placeholder="Date of Birth"
            maskLabel="Date of Birth"
            maskType="mm/dd/yyyy"
          />
        </Form.Item>

        <Form.Item
          name="email"
          hasFeedback
          rules={[validateRules.email, validateRules.required]}
        >
          <FloatInput
            label="Email"
            placeholder="Email"
            required
            onBlurInput={(e) => checkAccount(e, "email")}
          />
        </Form.Item>

        <Form.Item
          name="confirm_email"
          hasFeedback
          dependencies={["email"]}
          rules={[validateRules.required, validateRules.email_validate]}
        >
          <FloatInput
            label="Confirm Email"
            placeholder="Confirm Email"
            required
          />
        </Form.Item>

        <Form.Item
          name="address_1"
          hasFeedback
          rules={[validateRules.required]}
        >
          <FloatInput label="Address" placeholder="Address" required />
        </Form.Item>

        <Form.Item name="city" hasFeedback rules={[validateRules.required]}>
          <FloatInput label="City" placeholder="City" required />
        </Form.Item>

        <Form.Item
          name="state"
          hasFeedback
          // className="form-select-error"
          className="w-100"
          rules={[validateRules.required]}
        >
          <FloatSelect
            label={stateLabel}
            placeholder={stateLabel}
            options={optionState}
            onChange={() => {
              // console.log("adasdasd");
              // form.setFieldValue("zip_code", "");
            }}
            required
            allowClear
          />
        </Form.Item>

        <Form.Item
          name="zip_code"
          hasFeedback
          className="w-100"
          rules={[
            validateRules.required,
            {
              pattern: optionZip,
              message: "Invalid Post Code / ZIP Code!",
            },
          ]}
        >
          <FloatInput
            label={"Post Code / ZIP Code"}
            placeholder={"Post Code / ZIP Code"}
            required
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            // htmlType="submit"
            className="btn-main btn-register-here"
            style={{ marginTop: "10px" }}
            loading={isLoadingRegister}
            size="large"
            block
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  setCompletePurchaseErr({
                    type: "",
                    message: "",
                  });
                  setCollapseActiveKey("2");
                })
                .catch((info) => {
                  console.log(info);
                  setCompletePurchaseErr({
                    type: "error",
                    message: "Please Check Invalid Fields!",
                  });
                });
            }}
          >
            NEXT
          </Button>
        </Form.Item>

        {completePurchaseErr.message && (
          <Alert
            className="m-t-sm m-b-sm register-alert"
            type={completePurchaseErr.type}
            message={
              <div
                dangerouslySetInnerHTML={{
                  __html: completePurchaseErr.message,
                }}
              />
            }
          />
        )}
      </Form>
    </>
  );
}
