import React, { useState, useEffect } from "react";

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from "antd";

import { POST, GET } from "../../../../../providers/useAxiosQuery";
import TableScrollOnTop from "../../../../../providers/TableScrollOnTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { faEye } from "@fortawesome/pro-regular-svg-icons";

const ComponentConsultantUpload = React.forwardRef((props, ref) => {
  const [dataSource, setDataSource] = useState([]);

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
  });

  const { user_id, handleTableChange, handleDownloadFile, handlePreviewFile } =
    props;

  const { refetch: refetchConsultantUploads } = GET(
    `expedited/dropbox/consultant_uploads?id=${user_id}`,
    "consultant_uploads",
    (res) => {
      console.log(res.data);
      setDataSource(res.data);
    }
  );

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  return (
    <Col sm={24} md={24} lg={24}>
      <TableScrollOnTop tableRef={ref} />
      <div
        id="tbl1"
        className="user-form-upload-docs"
        style={{ marginTop: "-8px" }}
      >
        <Table
          ref={ref}
          className="ant-table-default ant-table-striped scrollbar-2"
          dataSource={dataSource && dataSource}
          //     rowKey={(record) => record.id}
          pagination={false}
          bordered={true}
          onChange={handleTableChange}
          style={{ top: "0px" }}
          sticky
        >
          <Table.Column
            title="Date Uploaded"
            key="created_date"
            dataIndex="created_date"
            sorter={true}
            width={"180px"}
            defaultSortOrder={"descend"}
          />
          <Table.Column
            title="Filename"
            key="file_name"
            sorter={true}
            dataIndex="file_name"
            width={"300px"}
            render={(text, record) => {
              return (
                <>
                  <div
                    style={{
                      //    display: "inline-flex",
                      alignItems: "center",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    <div className="table-action-btn">
                      <Button
                        style={{
                          color: "#365293",
                          padding: 0,
                          marginRight: 7,
                        }}
                        type="link"
                        onClick={() => {
                          handleDownloadFile(record.id, record.file_path);
                        }}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </Button>
                      <Button
                        style={{
                          color: "#365293",
                          padding: 0,
                          marginRight: 7,
                        }}
                        type="link"
                        onClick={() => {
                          handlePreviewFile(record.id, record.file_path);
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Tooltip title={text}>
                        <span>
                          {truncateString(text.split(".")[0], 18) +
                            "." +
                            text.split(".")[1]}
                        </span>
                      </Tooltip>{" "}
                    </div>
                  </div>
                </>
              );
            }}
          />
          {/* <Table.Column
            title="Appt Stage"
            key="stage"
            dataIndex="stage"
            width={"200px"}
            render={(text, record) => {
              let stage_temp = text.trim();

              if (stage_temp === "reschedule") {
                stage_temp = record?.last_uploaded_stage
                  ? record?.last_uploaded_stage.trim()
                  : "";
              }
              let stage = "";
              stage_temp = stage_temp.split(" ");

              if (Array.isArray(stage_temp)) {
                stage_temp.forEach((x, index) => {
                  stage += x.charAt(0).toUpperCase() + x.slice(1) + " ";
                });
              } else {
                stage =
                  stage_temp.charAt(0).toUpperCase() + stage_temp.slice(1);
              }

              return stage;
            }}
          /> */}
          {/* <Table.Column
            title="Uploaded By"
            key="uploader"
            dataIndex="uploader"
            sorter={true}
            width={"200px"}
            render={(text, record) => (
              <Typography.Text
                style={{
                  color: record.uploader_role == "User" ? "#3f5fac" : "#ff8303",
                }}
              >
                {text}
              </Typography.Text>
            )}
          /> */}
          <Table.Column
            title="Original Filename"
            key="original_file_name"
            dataIndex="original_file_name"
            sorter={true}
            width={"230px"}
            render={(text, record) => (
              <Tooltip title={text}>
                <p className="margin-bottom-0">
                  {truncateString(text.split(".")[0], 15) +
                    "." +
                    text.split(".")[1]}
                </p>
              </Tooltip>
            )}
          />
        </Table>
      </div>
      {/* <Col xs={24} sm={24} md={24}>
        <div className="ant-space-flex-space-between the-pagination the-pagination--upload-docs">
          <TableShowingEntries />
          <TablePagination
            paginationFilter={tableFilter}
            setPaginationFilter={setTableFilter}
            setPaginationTotal={tableTotal}
            showLessItems={true}
            showSizeChanger={false}
          />
        </div>
      </Col> */}
    </Col>
  );
});

export default ComponentConsultantUpload;
