import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Typography,
  notification,
  Select,
  Divider,
  Dropdown,
  Menu,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
//import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
  faCircleInfo,
  faUserSlash,
  faUserCheck,
} from "@fortawesome/pro-light-svg-icons";
import { POST, GET, UPDATE } from "../../../providers/useAxiosQuery";
import { ExclamationCircleOutlined, WindowsFilled } from "@ant-design/icons";
import moment from "moment";
import { GoPrimitiveDot } from "react-icons/go";

export default function ModalCancelAppointment(props) {
  const { toggleModal, setToggleModal, reload, details, event } = props;
  const history = useHistory();

  const [form] = Form.useForm();
  const [slots, setSlots] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [currentTimezone, setCurrentTimezone] = useState("");
  const [showSlots, setShowSlots] = useState(false);
  const [date, setDate] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledCancel, setIsDisabledCancel] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [appointmentDetails, setappointmentDetails] = useState([]);
  const [client_details, setClientDetails] = useState([]);
  const [tags, setTags] = useState([]);

  const option = [
    { title: "Showed", value: "showed", icon: faCircleInfo },
    { title: "No-Show", value: "noshow", icon: faCircleInfo },
    { title: "Cancelled", value: "cancelled", icon: faCircleInfo },
  ];

  useEffect(() => {
    if (details[0] && details[0].eventInfo) {
      let contact = details[0].eventInfo.extendedProps.client;
      setClientDetails(details[0].eventInfo.extendedProps.client);
      let diff = moment(details[0] && details[0].eventInfo.startStr)
        .tz("MST")
        .diff(moment(), "hours");
      if (details[0].eventInfo.extendedProps.status != "booked") {
        setIsDisabled(true);
        setIsDisabledCancel(true);
      }

      let tag = [];
      console.log("details: ", details[0].eventInfo.extendedProps);
      if (details[0].eventInfo.extendedProps.workflow == "expedited editing") {
        details[0].eventInfo.extendedProps.client.expedited_tags.forEach(
          (item) => {
            tag.push(item.tag);
          }
        );
      } else {
        details[0].eventInfo.extendedProps.client.expedited_tags.forEach(
          (item) => {
            tag.push(item.tag);
          }
        );
      }

      if (
        tag.includes("call 1 - call (current task)") ||
        tag.includes("call 2 - call (current task)")
      ) {
        setIsDisabled(false);
      } else {
        message.warning(
          "Client is not yet on consultation call stage, Please check client task!"
        );
        setIsDisabled(true);
      }

      console.log("tags: ", tag);
      setTags(tag);
    }

    return () => {};
  }, [details]);

  const [classname, setClassName] = useState("");
  const [status, setStatus] = useState("");
  const [currentWorkflow, setCurrentWorkflow] = useState("");

  useEffect(() => {
    if (details.length != 0) {
      let classname = premitiveColor(details[0].eventInfo.extendedProps.status);

      if (details[0].eventInfo.extendedProps.status === "noshow") {
        setStatus("NO SHOW");
      } else if (details[0].eventInfo.extendedProps.status === "showed") {
        setStatus("ATTENDED");
      } else if (details[0].eventInfo.extendedProps.status === "booked") {
        setStatus("BOOKED");
      } else {
        setStatus(details[0].eventInfo.extendedProps.status.toUpperCase());
      }

      setClassName(classname);

      setCurrentWorkflow(
        details[0].eventInfo.extendedProps.workflow
          ? details[0].eventInfo.extendedProps.workflow
          : ""
      );
    }
  }, [details]);

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const confirm = (value) => {
    let status = "";
    let statusNotif = "";
    if (value === "showed") {
      status = "ATTENDED";
      statusNotif = "ATTENDED";
    } else if (value === "cancelled and delete") {
      status = "CANCEL MY APPOINTMENT";
      statusNotif = "Cancelled";
    } else if (value === "noshow") {
      status = "NO SHOW";
      statusNotif = "NO SHOW";
    }

    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you want to change appointment status to "' +
        status +
        '" ?',
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        let data = {
          client_id: details[0].eventInfo.extendedProps.booked_by,
          id: details[0].eventInfo.extendedProps.id,
          status: value,
        };

        mutateCancel(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Appointment status " + statusNotif.toLowerCase(),
              });

              addHistoryLog(
                {
                  page: "Calendar/Appointment-Status",
                  key: "appointment status",
                  data: JSON.stringify(data),
                  old_data: "",
                  new_data: value,
                  method: "change-appointment-status",
                },
                { onSuccess: (res) => {} }
              );

              if (
                value == "showed" &&
                tags.includes("call 2 - call (current task)") &&
                currentWorkflow != "expedited editing"
              ) {
                skipNextAppointment();
              } else {
                let path =
                  currentWorkflow !== "expedited editing"
                    ? `/user/manageuser/${details[0].eventInfo.extendedProps.booked_by}`
                    : `/user/expedited/manageuser/${details[0].eventInfo.extendedProps.booked_by}`;

                history.push({
                  pathname: path,
                  // state: {
                  //   username:
                  //     client_details.firstName.charAt(0) +
                  //     client_details.firstName.slice(1) +
                  //     " " +
                  //     client_details.lastName.charAt(0) +
                  //     client_details.lastName.slice(1),
                  // },
                });
                // setToggleModal(false);
                // reload(event);
              }
            }
          },
        });
      },
    });
  };

  const skipNextAppointment = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Proceed to timeline call?",
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        let data = {
          // email: client_details.email,
          // tag: "follow up call - book (current task)",
          user_id: client_details.id,
          tag: "follow up call - book (current task)",
        };
        mutateProceed(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Client will remain in follow up call",
              });

              // addHistoryLog(
              //   {
              //     page: "client-stage/proceed-to-timeline",
              //     key: "appointment stage",
              //     data: client_details.email,
              //     old_data: "call 2",
              //     new_data: "timeline call",
              //     method: "change-appointment-stage",
              //   },
              //   { onSuccess: (res) => {} }
              // );

              history.push({
                pathname: `/user/manageuser/${client_details.id}`,
                // state: { username: client_details.fullNameLowerCase },
              });
            }
          },
        });

        // setToggleModal(false);
        // reload(event);
      },
      onOk: () => {
        let data = {
          user_id: client_details.id,
          tag: "timeline - book (current task)",
        };
        mutateProceed(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully proceed client to next timeline",
              });
              history.push({
                pathname: `/user/manageuser/${client_details.id}`,
                // state: { username: client_details.fullNameLowerCase },
              });
            }
          },
        });
      },
    });
  };

  const { mutate: mutateProceed, isLoading: loadingProceed } = POST(
    "api/v1/add/conusltant/proceed_tag",
    "add_tag"
  );

  const { mutate: mutateCancel, isLoading: lodaingCancel } = POST(
    currentWorkflow != "expedited editing"
      ? "api/v1/user/schedule/change_status"
      : "expedited/user/schedule/change_status",
    "appointment"
  );

  const handleToggleModal = () => {
    setIsDisabled(false);
    setToggleModal(false);
    setIsDisabledCancel(false);
  };

  function premitiveColor(status) {
    // console.log("status: ", status);
    let classname = "";

    switch (status) {
      case "showed":
        classname = "primitive-brown";
        break;
      case "booked":
        classname = "primitive-success";
        break;
      case "cancelled":
        classname = "primitive-cancelled";
        break;
      case "noshow":
        classname = "primitive-warning";
        break;
      default:
        classname = "primitive-available";
        break;
    }
    return classname;
  }

  return (
    <Modal
      open={toggleModal}
      title="APPOINTMENT"
      onCancel={handleToggleModal}
      className="modal-primary-default modal-change-2-factor-authentication modal-appointment consultant-sched-appt"
      footer={false}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "start",
          marginTop: "-5px",
          marginLeft: "-5px",
          marginBottom: "-3px",
        }}
      >
        <Col>
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <span className="admin-calendar-status">
                <GoPrimitiveDot className={classname} />
              </span> */}
              <Typography.Link
                type="link"
                className={"name-link  event-title " + classname}
                onClick={() => {
                  let path =
                    currentWorkflow !== "expedited editing"
                      ? `/user/manageuser/${details[0].eventInfo.extendedProps.booked_by}`
                      : `/user/expedited/manageuser/${details[0].eventInfo.extendedProps.booked_by}`;

                  history.push({
                    pathname: path,
                    // state: {
                    //   username:
                    //     client_details.firstName.charAt(0) +
                    //     client_details.firstName.slice(1) +
                    //     " " +
                    //     client_details.lastName.charAt(0) +
                    //     client_details.lastName.slice(1),
                    // },
                  });
                }}
                style={{
                  marginLeft: "-2px !important",
                  fontWeight: 400,
                  fontSize: 24,
                  lineHeight: "24px",
                }}
              >
                {details[0] && details[0].eventInfo?.title}
              </Typography.Link>
            </div>
            <div className="event-schedule-container">
              <span className="event-date">
                {details[0] && details[0].date}
              </span>
              <br />
              <span className="event-time">
                {details[0] &&
                  moment(details[0].time_start, "H:mm a").format("h:mm A")}{" "}
                {"-"}{" "}
                {details[0] &&
                  moment(details[0].time_end, "H:mm a").format("h:mm A")}
              </span>
              <br />
              <span className="event-time">
                {" "}
                Status:{" "}
                <strong className={classname}>{details[0] && status}</strong>
              </span>
            </div>
          </>
        </Col>
      </Row>

      <Row
        gutter={[12, 12]}
        style={{ marginTop: "17px", marginLeft: "-11px", marginRight: "-11px" }}
      >
        <Col xs={24} sm={24} md={24}>
          <Button
            style={{ width: "100%", fontSize: "18px" }}
            size="large"
            onClick={() => {
              confirm("showed");
            }}
            className="text-center btn-modal btn-modal-success"
            disabled={isDisabled}
            loading={lodaingCancel}
            block
          >
            ATTENDED
          </Button>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Button
            style={{ width: "100%", fontSize: "18px" }}
            size="large"
            onClick={() => {
              confirm("noshow");
            }}
            className="text-center btn-modal btn-modal-warning"
            disabled={isDisabled}
            loading={lodaingCancel}
          >
            NO SHOW
          </Button>
        </Col>
        <Col xs={24} sm={24} md={24}>
          <Button
            style={{ width: "100%", fontSize: "17px" }}
            size="large"
            onClick={() => {
              confirm("cancelled and delete");
            }}
            className="text-center btn-modal btn-modal-danger"
            disabled={isDisabledCancel}
            loading={lodaingCancel}
          >
            CANCEL MY APPOINTMENT
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
