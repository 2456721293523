import React from "react";
import { Button, Row, Col, Space, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartCirclePlus,
  faClock,
  faUserCheck,
} from "@fortawesome/pro-regular-svg-icons";

export default function ComponentUpdateChoices(props) {
  const {
    onClickedIamSelected,
    isLoadingNotifyConsultant,
    hasFreeUpdate,
    oneHourUpdateBtn,
    setUpdateChoices,
    handleAddOneHourUpdateTag,
    isLoadingUpdateFreeHourUpdate,
  } = props;

  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={24} lg={24} className="m-b-md">
        <Space
          wrap
          size={[8, 12]}
          className="dash-btn-space dash-btn-space-one-hour"
        >
          <Button
            onClick={() => {
              onClickedIamSelected();

              //   if (isLoading) {
              //     setRunInterval(false);
              //   }

              // getTaskData();
            }}
            size="small"
            className="btn-primary btn-dashboard btn-dashboard-one"
            loading={isLoadingNotifyConsultant}
            style={{ borderRadius: 4, fontWeight: 300 }}
          >
            {" "}
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faUserCheck}
            />
            GOT SELECTED !
          </Button>
          {hasFreeUpdate === false ? (
            <Button
              onClick={() => {
                setUpdateChoices(false);
                window.location.replace(
                  // "https://myairlinetc.com/product/1-hour-update/",
                  // process.env.REACT_APP_PRODUCT_PURCHASE_ONE_HOUR_LINK,
                  "/checkout/one-hour-update"
                );

                // if (isLoading) {
                //   setRunInterval(false);
                // }
                // getTaskData();
              }}
              size="small"
              className="btn-primary btn-dashboard"
              disabled={oneHourUpdateBtn}
              style={{
                textTransform: "uppercase",
                borderRadius: 4,
                fontWeight: 300,
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                icon={faCartCirclePlus}
              />
              Purchase One hour update
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleAddOneHourUpdateTag();
                // if (isLoading) {
                //   setRunInterval(false);
                // }
              }}
              loading={isLoadingUpdateFreeHourUpdate}
              size="small"
              className="btn-primary btn-dashboard"
              style={{
                textTransform: "uppercase",
                borderRadius: 4,
                fontWeight: 300,
              }}
            >
              <FontAwesomeIcon
                style={{
                  marginRight: "5px",
                }}
                icon={faClock}
              />
              Free One Hour Update
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  );
}
