import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Collapse,
  Alert,
} from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import moment from "moment";
import { description, fullwidthlogo } from "../../../providers/companyInfo";
import { POST } from "../../../providers/useAxiosQuery";
import ComponentHeader from "./component/ComponentHeader";
import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import ReCAPTCHA from "react-google-recaptcha";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import ComponentRegisterStep2 from "./component/ComponentRegisterStep2";
import ComponentRegisterStep1 from "./component/ComponentRegisterStep1";
import ComponentRegisterStep3 from "./component/ComponentRegisterStep3";

export default function PageRegisterExpedited() {
  const history = useHistory();
  const [collapseActiveKey, setCollapseActiveKey] = useState("1");
  const bottomRef = useRef();

  const stateUS = optionStateCodesUnitedState();
  // const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [optionZip, setOptionZip] = useState();
  // const [zipLabel, setZipLabel] = useState("Zip Code");
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaTokenError, setCaptchaTokenError] = useState(false);

  const [completePurchaseErr, setCompletePurchaseErr] = useState({
    type: "",
    message: "",
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [multifileList, setMultiFileList] = useState([]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      //    console.log(getWindowSize().innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    console.log(windowSize.innerWidth);
  }, [windowSize]);

  const { mutate: mutateRegister, isLoading: isLoadingRegister } = POST(
    "expedited/register",
    "register"
  );

  useEffect(() => {
    setOptionState(stateUS);
    setStateLabel("State");
    setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    // setZipLabel("Zip Code");
  }, []);

  useEffect(() => {
    if (captchaToken && captchaTokenError) {
      setCaptchaTokenError(false);
    }
  }, [captchaToken]);

  const handleMoveToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onFinishInfomation = (values) => {
    let phone = values.phone;

    phone = phone.replace(/[^0-9]/g, "");

    const data = new FormData();

    for (let key in values) {
      data.append(key, values[key]);
    }

    multifileList.map((item, index) => {
      data.append("images_" + index, item.originFileObj, item.name);
    });
    data.append("images_count", multifileList ? multifileList.length : 0);
    data.append("images", multifileList);
    data.append("link_origin", window.location.origin);
    data.append("phone", phone);

    // for (let pair of data.entries()) {
    //     console.log(pair[0] + ", " + pair[1]);
    // }
    // form.validateFields(["username", "email", "phone"]).then((values) => {
    //   checkAccount(values, "multi");
    //   // checkAccount(values.email, "email");
    // });

    if (!captchaToken) {
      setCaptchaTokenError(true);
    } else {
      setCaptchaTokenError(false);

      mutateRegister(data, {
        onSuccess: (res) => {
          if (res.success) {
            handleMoveToBottom();
            setCompletePurchaseErr({
              type: "success",
              message:
                "A confirmation e-mail has been send please check your inbox or your spam folder for the next step.",
            });
          } else {
            form.resetFields(["username", "email", "phone"]);
            form.setFieldsValue({
              username: data?.username,
              email: data?.email,
              phone: "+" + data?.phone,
            });
            if (res.array_message.includes("Username is already registered!")) {
              form.setFields([
                {
                  name: "username",
                  errors: ["Username is already registered!"],
                },
              ]);
            }

            if (res.array_message.includes("Email is already registered!")) {
              form.setFields([
                {
                  name: "email",
                  errors: ["Email is already registered!"],
                },
              ]);
            }

            if (res.array_message.includes("Phone is already registered!")) {
              form.setFields([
                {
                  name: "phone",
                  errors: ["Phone is already registered!"],
                },
              ]);
            }

            setCompletePurchaseErr({
              type: "error",
              message: res.array_message
                // .map((item) => item + "<br/>")
                // .map((item) => item + ", ")
                .join(", "),
            });
          }
        },
        onError: (err) => {
          setCompletePurchaseErr({
            type: "error",
            message: err.response.data.message,
          });
        },
      });
    }
  };

  const [form] = Form.useForm();

  const checkAccount = (value, type) => {
    if (type === "phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
          // form.submit();
        } else {
          form.setFields([
            {
              name: type,
              errors: [res.message],
            },
          ]);
        }
      },
      onError: (err) => {
        form.setFields([
          {
            name: type,
            errors: ["Error on checking " + type + "!"],
          },
        ]);
      },
    });
  };

  // Call the function wherever you want to move to the bottom of the page

  const { mutate: mutateCheckAccount } = POST(
    "expedited/check_account",
    "check_account",
    false
  );

  return (
    <Layout className="public-layout register-layout">
      <Layout.Content>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Image
              className="zoom-in-out-box"
              onClick={() => history.push("/")}
              src={fullwidthlogo}
              preview={false}
            />

            <div className="register-sub-title"></div>

            <Card className="card--public-body">
              <Typography.Title
                level={2}
                style={{ textAlign: "center", color: "#79390f" }}
              >
                Expedited Editing
              </Typography.Title>
              <ComponentHeader
                title="Registration"
                subtitle="New User"
                icon={faEdit}
              />

              <Collapse
                accordion
                expandIconPosition="end"
                activeKey={collapseActiveKey}
                // onChange={(e) => setCollapseActiveKey(e)}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
                // collapsible="disabled"
              >
                <Collapse.Panel
                  header={
                    <>
                      <div className="title">Step 1</div>
                      <div className="sub-title">Complete All Fields Below</div>
                    </>
                  }
                  key="1"
                >
                  <ComponentRegisterStep1
                    form={form}
                    setCollapseActiveKey={setCollapseActiveKey}
                    optionState={optionState}
                    optionZip={optionZip}
                    stateLabel={stateLabel}
                    checkAccount={checkAccount}
                    isLoadingRegister={isLoadingRegister}
                    setCompletePurchaseErr={setCompletePurchaseErr}
                    onFinishInfomation={onFinishInfomation}
                    completePurchaseErr={completePurchaseErr}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <>
                      <div className="title">Step 2</div>
                      <div className="sub-title">
                        Select the items that applies to you
                      </div>
                    </>
                  }
                  key="2"
                >
                  <ComponentRegisterStep2
                    form={form}
                    checkAccount={checkAccount}
                    isLoadingRegister={isLoadingRegister}
                    setCompletePurchaseErr={setCompletePurchaseErr}
                    onFinishInfomation={onFinishInfomation}
                    completePurchaseErr={completePurchaseErr}
                    ReCAPTCHA={ReCAPTCHA}
                    windowSize={windowSize}
                    setCaptchaToken={setCaptchaToken}
                    setCollapseActiveKey={setCollapseActiveKey}
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <>
                      <div className="title">Step 3</div>
                      <div className="sub-title">Upload Application</div>
                    </>
                  }
                  key="3"
                >
                  <ComponentRegisterStep3
                    form={form}
                    setCollapseActiveKey={setCollapseActiveKey}
                    checkAccount={checkAccount}
                    isLoadingRegister={isLoadingRegister}
                    setCompletePurchaseErr={setCompletePurchaseErr}
                    onFinishInfomation={onFinishInfomation}
                    completePurchaseErr={completePurchaseErr}
                    ReCAPTCHA={ReCAPTCHA}
                    windowSize={windowSize}
                    setCaptchaToken={setCaptchaToken}
                    multifileList={multifileList}
                    setMultiFileList={setMultiFileList}
                  />
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer className="text-center" ref={bottomRef}>
        <Typography.Text className="copyright-txt">
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved.
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
