import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Typography,
  notification,
  Divider,
  Upload,
  message,
  Result,
  Space,
} from "antd";
//import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import { useHistory } from "react-router-dom";
import { GET, POST, POSTFILE } from "../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import moment from "moment";
import { role, tz_offset } from "../../../providers/companyInfo";
import { GoPrimitiveDot } from "react-icons/go";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/pro-regular-svg-icons";

export default function ModalEventBookedWeekView(props) {
  const { open, setOpen, details, appStage } = props;
  const [appointmentDetails, setappointmentDetails] = useState([]);
  const [selectedTime, setSelectedTime] = useState();
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [currentData, setCurrentData] = useState({
    stage: "",
    tag: "",
  });

  // useEffect(() => {
  //   if (details.length > 0) {
  //     let sorted_slots = details[0].slots.slots.sort((a, b) => {
  //       let time_1 = moment(a.time_start, "HH:mm");
  //       let time_2 = moment(b.time_start, "HH:mm");
  //       return time_1.isBefore(time_2) ? -1 : 1;
  //     });
  //     setAppointmentSlots(sorted_slots);
  //     setappointmentDetails(details);
  //   }
  // }, [details]);

  const { refetch: refetchGetcurrenttag } = GET(
    `api/v1/user/getcurrenttag`,
    "tag",
    (res) => {
      if (role() == "User") {
        if (res.success) {
          let data = res.data;
          let current_stage = "";
          if (Array.isArray(data)) {
            data.forEach((x) => {
              if (x.includes("current task")) {
                let stages = x.split("-");
                if (Array.isArray(stages)) {
                  stages = stages[0].trim();
                }

                if (stages === "reschedule") {
                  current_stage = appStage;
                } else if (stages === "pre publish") {
                  current_stage = "pre-publish";
                } else if (stages === "follow up call") {
                  current_stage = "follow up call (optional)";
                } else if (stages === "1 hr update reschedule") {
                  current_stage = currentData?.stage;
                } else if (stages === "pre interview") {
                  current_stage = "pre-interview";
                } else {
                  current_stage = stages;
                }
              }
            });
          }

          let dataStage = "";
          if (res.lastStageUploaded?.stage.trim() === "pre publish") {
            dataStage = "pre-publish";
          } else if (res.lastStageUploaded?.stage.trim() === "follow up call") {
            dataStage = "follow up call (optional)";
          } else if (res.lastStageUploaded?.stage.trim() === "pre interview") {
            dataStage = "pre-interview";
          } else if (
            res.lastStageUploaded?.stage.trim() === "reschedule" ||
            res.lastStageUploaded?.stage.trim() === "1 hr update reschedule"
          ) {
            dataStage = res.lastStageUploaded.last_uploaded_stage.trim();
            if (dataStage === "follow up call") {
              dataStage = "follow up call (optional)";
            } else if (dataStage === "1 hr update") {
              dataStage = "one hour update";
            } else if (dataStage === "pre interview") {
              console.log("asdasdsad asdadas");
              dataStage = "pre-interview";
            }
          } else {
            dataStage = res.lastStageUploaded?.stage.trim();
          }

          console.log(
            "currentData1",
            res.allStageUploaded,
            current_stage,
            // res?.allStageUploaded.includes(`${current_stage} `)
            res?.allStageUploaded.filter((item) => item.includes(current_stage))
              .length
          );

          setCurrentData({
            ...currentData,
            tag: current_stage,
            stage: dataStage,
            stageAll: res?.allStageUploaded.filter((item) =>
              item.includes(current_stage)
            ).length,
          });
        }
      }
    }
  );

  const [uploadMore, setUploadMore] = useState(false);

  //check if client has uploaded
  // const hasUploaded = (current_tag, stage, last_uploaded_stage) => {
  //   if (current_tag === last_uploaded_stage) {
  //     return true;
  //   }else if(current_tag){

  //   }
  // };

  // disable 1 hour button on timeline stage
  const disabledButton = (timeStart, timeEnd) => {
    let sTime = moment(timeStart, "HH:mm:ss");
    let eTime = moment(timeEnd, "HH:mm:ss");

    let duration = moment.duration(eTime.diff(sTime));
    if (appStage) {
      if (
        appStage === "timeline" ||
        appStage === "pre-publish" ||
        appStage === "one hour update" ||
        appStage === "pre-interview"
      ) {
        if (duration.hours() <= 1) {
          return true;
        }
      } else {
        if (duration.hours() <= 1) {
          return false;
        }
      }
    }
    return false;
  };

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const { mutate: mutateAddAppointments, isLoading: isLoading } = POST(
    "api/v1/appointment",
    ["appointment", "tag"]
  );

  const onFinishForm = () => {
    let timeslot =
      moment(details.startStr).format("YYYY-MM-DD") +
      "T" +
      moment(details.extendedProps.time_start, "HH:mm").format("HH:mm:ss");

    let data = {
      appointmentDate: moment(details.startStr).format("dddd, MMMM Do"),
      //   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone: "US/Mountain",
      timeslot: timeslot + "-06:00",
      slot_id: details.extendedProps.slot_id,
      calendarID: details.extendedProps.calendar_id,
    };

    mutateAddAppointments(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully booked",
          });

          addHistoryLog(
            {
              page: "User/Book-Appointment",
              key: "appointment slot",
              consultant: details.extendedProps.user_id,
              old_data: "",
              new_data: timeslot,
              method: "book-appointment",
            },
            { onSuccess: (res) => {} }
          );

          setOpen(false);
          history.push("/appointment/myschedule");
        } else if (res.success == false) {
          notification.warning({
            message: "Not allowed",
            description: res.message,
          });
          setOpen(false);
        }
      },
      onError: (res) => {
        notification.warning({
          message: "Booking failed",
          description: "Something went wrong while booking selected slot",
        });
      },
    });
  };

  let selectedTimeTemp;
  const [multifileList, setMultiFileList] = useState([]);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = POSTFILE(
    "api/v1/user/upload",
    ["getlastuploadedstage", "tag"]
  );

  const onFinishUpload = (values) => {
    const data = new FormData();

    multifileList.map((item, index) => {
      data.append("images_" + index, item.originFileObj, item.name);
    });
    data.append("images_count", multifileList ? multifileList.length : 0);
    data.append("images", multifileList);

    // data.append("current_task", JSON.stringify(currentTask));

    console.log("onFinishUpload", appStage);
    console.log("onFinishUpload", multifileList.length);

    if (multifileList.length > 0) {
      if (appStage == "timeline" && multifileList.length <= 1) {
        notification.warning({
          message: "Documents required!",
          description: "Please Upload Revised application and Timeline sheets",
        });
      } else {
        mutateCreate(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully Uploaded",
              });
              var filename = multifileList.map((item) => item.name);
              // console.log("filename", filename);
              setUploadMore(false);
              addHistoryLog(
                {
                  page: "Documents",
                  key: "upload document",
                  old_data: "",
                  new_data: JSON.stringify(filename),
                  method: "upload-document",
                  // consultant: details[0].eventInfo.title,
                },
                { onSuccess: (res) => {} }
              );
              setMultiFileList([]);
              form.resetFields();
              // history.push(`/view/restaurants/edit/${table_id}`);
            }
          },
          onError: (res) => {},
        });
      }
    } else {
      notification.warning({
        message: "Warning",
        description: "Please choose files to upload",
      });
    }
  };

  return (
    <Modal
      open={open}
      title="APPOINTMENT"
      footer={
        <Button
          onClick={() => {
            // if (selectedTime) {
            onFinishForm();
            // } else {
            //   notification.warning({
            //     message: "Time slot required",
            //     description: "Please select timelslot first!",
            //   });
            // }
          }}
          type="primary"
          size="large"
          className="btn-primary btn-sign-in"
          style={{ width: "100%", fontSize: "18px" }}
          disabled={currentData.tag !== currentData.stage}
          // disabled={currentData?.stageAll === 0 ? true : false}
        >
          BOOK APPOINTMENT
        </Button>
      }
      onCancel={() => {
        setOpen(false);
        setOpen(false);
      }}
      className="modal-primary-default modal-change-2-factor-authentication modal-appointment"
    >
      <div
        className="event-title-container"
        style={{ margin: "-15px 0px 0px" }}
      >
        <span className="admin-calendar-status" style={{ marginBottom: "2px" }}>
          <GoPrimitiveDot className="primitive-available" />
        </span>
        <span className="event-title" style={{ fontSize: 24 }}>
          {details && details.title}
        </span>
      </div>
      <div
        className="event-schedule-container"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "-5px",
        }}
      >
        <span className="event-date" style={{ fontSize: 16 }}>
          {details && moment(details.startStr).format("dddd, MMMM Do")}
        </span>

        <span className="event-time" style={{ fontSize: 16 }}>
          {details &&
            moment(details.extendedProps.time_start, "HH:mm").format("HH:mm a")}
          {" - "}
          {details &&
            moment(details.extendedProps.time_end, "HH:mm").format("HH:mm a")}
        </span>
      </div>
      <Divider />
      {currentData.tag !== currentData.stage || uploadMore ? (
        <>
          <Typography.Text style={{ fontSize: "16px" }}>
            <span style={{ color: "red" }}>*</span>
            Application Revision and/or Timeline pages must be uploaded to
            enable call scheduling.
          </Typography.Text>
          <Form layout="vertical" form={form} onFinish={onFinishUpload}>
            <Upload
              className="venue-images"
              listType="picture"
              fileList={multifileList}
              onChange={({ fileList: newFileList }) => {
                var _file = newFileList;
                // console.log(_file);
                _file.map((row, key) => {
                  return (row.status = "done");
                });

                let _newFile = [];
                const allowedFileTypes = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "jpg",
                  "doc",
                  "docx",
                  "pdf",
                ];

                _file.forEach((item) => {
                  const fileExtension = item.name
                    .split(".")
                    .pop()
                    .toLowerCase();

                  if (allowedFileTypes.includes(fileExtension)) {
                    _newFile.push(item);
                  }
                });

                setMultiFileList(_newFile);
              }}
              beforeUpload={(file) => {
                const isLt30M = file.size / 1024 / 1024 < 30; // Check if the file is smaller than 5MB

                // const isLt2M = file.size / 1024 / 1024 < 2 // Check if the file is smaller than 5MB
                const allowedFileTypes = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "jpg",
                  "doc",
                  "docx",
                  "pdf",
                ];
                const fileExtension = file.name.split(".").pop().toLowerCase();

                if (!isLt30M) {
                  notification.warning({
                    message: "Error",
                    description: "File must be smaller than 30MB!",
                  });
                  return Upload.LIST_IGNORE;
                } else if (file.size <= 0) {
                  notification.warning({
                    message: "Error",
                    description: "File is invalid or corrupted!",
                  });
                  return Upload.LIST_IGNORE;
                }

                if (!allowedFileTypes.includes(fileExtension)) {
                  notification.warning({
                    message: "Error",
                    description:
                      "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX, PDF files.",
                  });
                  return Upload.LIST_IGNORE;
                }

                return true; // Allow the file to be uploaded
              }}
              onPreview={async (file) => {
                let src = file.url;
                if (!src) {
                  src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                  });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow.document.write(image.outerHTML);
              }}
            >
              <Button type="dashed" className="upload-btn">
                <div className="upload-btn-description">
                  <p className="ant-upload-text">
                    <FontAwesomeIcon
                      className="document-upload-icon"
                      icon={faArrowUpFromBracket}
                    />
                  </p>
                  <br />
                  <Typography.Text className="upload-title">
                    Click or Drag{" "}
                  </Typography.Text>
                  <br />
                  <Typography.Text className="upload-title">
                    Documents to Upload{" "}
                  </Typography.Text>

                  <br />
                  <br />

                  <Typography.Text className="upload-sm-desc">
                    <strong>Limit:</strong>{" "}
                    <span style={{ color: "#58595b", fontWeight: "normal" }}>
                      30MB (Unlimited Number of Files)
                    </span>
                  </Typography.Text>
                  <br />
                  <Typography.Text className="upload-sm-desc">
                    <strong>Types:</strong>{" "}
                    <span style={{ color: "#58595b", fontWeight: "normal" }}>
                      pdf, png, gif, jpg, jpeg, doc, docx.{" "}
                    </span>
                  </Typography.Text>
                  {/* <p
                      className="upload-instruction"
                      style={{ whiteSpaces: "break-word" }}
                    >
                      5MB limit. Allowed types: pdf, png, gif, jpg, jpeg, doc,
                      docx.
                    </p>
                    <p className="upload-instruction">
                      Unlimited number of documents can be uploaded to this
                      field.
                    </p> */}
                </div>
              </Button>
            </Upload>

            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCreate}
              className="btn-primary btn-submit-upload"
              block
              size="large"
            >
              SUBMIT
            </Button>
          </Form>
        </>
      ) : (
        <>
          <Result
            status="success"
            title="Thanks for uploading!"
            subTitle="You can book for an appointment now"
            className="result-success"
          />
          {
            <center>
              <Button type="link" onClick={() => setUploadMore(true)}>
                Upload More
              </Button>
            </center>
          }
        </>
      )}
      {/* <Divider /> */}
    </Modal>
  );
}
