import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Typography,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import PageCheckoutComponentProductDetails from "./PageCheckoutComponents/PageCheckoutComponentProductDetails";
import PageCheckoutComponentBillingDetails from "./PageCheckoutComponents/PageCheckoutComponentBillingDetails";
import PageCheckoutComponentAdditionalInformation from "./PageCheckoutComponents/PageCheckoutComponentAdditionalInformation";
import PageCheckoutComponentCardDetails from "./PageCheckoutComponents/PageCheckoutComponentCardDetails";
import validateRules from "../../../../providers/validateRules";
import { userData, appUrl } from "../../../../providers/companyInfo";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import {
  GET,
  GETMANUAL,
  POST,
  POSTLOADING,
} from "../../../../providers/useAxiosQuery";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import FloatInput from "../../../../providers/FloatInput";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function PageCheckoutEightHour(props) {
  const [form] = Form.useForm();
  const user = userData();
  const history = useHistory();
  const [couponCode, setCouponCode] = useState({
    coupon_name: "",
    coupon_value: "",
    coupon_type: "",
    coupon_amount: "",
    total: "",
  });

  const poductDetails = [
    {
      id: 1,
      name: "[VIDEO 5] Timeline Training Video",
      quantity: 1,
      amount: 0,
    },
    {
      id: 2,
      name: "United Airlines Application Boot Camp",
      quantity: 1,
      amount: 0,
    },
    {
      id: 3,
      name: "Eight Hour Application Construction",
      quantity: 1,
      amount: 1199,
    },
  ];
  const totalAmount = poductDetails?.reduce(
    (total, item) => total + item?.quantity * Number(item?.amount),
    0
  );

  GET(`api/v1/users/${user?.id}`, "users", (res) => {
    if (res.success) {
      let data = res?.data;
      let country = data?.user_address?.country
        ? data?.user_address?.country
        : "United States";

      onChangeCountry(country); // set state option list

      form.setFieldsValue({
        first_name: data?.firstname,
        last_name: data?.lastname,
        phone: data?.phone ? "+" + data?.phone : undefined,
        email: data?.email,
        address_1: data?.user_address?.address1
          ? data?.user_address?.address1
          : undefined,
        address_2: data?.user_address?.address2
          ? data?.user_address?.address2
          : undefined,
        country: country,
        state: data?.user_address?.state,
        city: data?.user_address?.city,
        zip_code: data?.user_address?.zip_code,
      });

      refetchCheckout(); // back to dashboard if not have product purchase (current task)
    }
  });

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [optionBillingZip, setOptionBillingZip] = useState(
    /(^\d{5}$)|(^\d{5}[ -]\d{4}$)/
  );
  const [countryValue, setCountryValue] = useState("Zip Code");

  const onChangeCountry = (val, opt) => {
    if (val === "United States") {
      setOptionState(stateUS);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setOptionState(stateCA);
      setOptionBillingZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (val === "Puerto Rico") {
      setOptionState(stateCA);
      setOptionBillingZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }
    setCountryValue(val);
    // form.resetFields(["state", "zip_code", "city"]);
  };

  const onChangeState = (val, opt) => {
    // form.resetFields(["zip_code"]);
  };

  // back to dashboard if not have product purchase (current task)
  const { refetch: refetchCheckout } = GETMANUAL(
    `api/v1/get_client_tag?user_id=${user?.id}`,
    "get_client_tag",
    (res) => {
      if (res.success) {
        if (!res.data.includes("product purchase (current task)")) {
          history.push("/");
        }
      }
    }
  );

  const [couponValue, setCouponValue] = useState(null);
  const handleApplyCoupon = (e) => {
    let coupon = couponValue;
    let totalAmount = poductDetails?.reduce(
      (total, item) => total + item?.quantity * Number(item?.amount),
      0
    );

    if (coupon) {
      mutateApplyCoupon(
        { code: coupon, role: user.role, user_id: user.id },
        {
          onSuccess: (res) => {
            if (res.success) {
              console.log(
                "res?.data?.max",
                res?.data?.max,
                res?.data?.redemption
              );
              if (Number(res?.data?.max) > Number(res?.data?.redemption)) {
                let discountData;
                if (res.data.type == "fixed") {
                  discountData = res.data.off;
                } else if (res.data.type == "percent") {
                  discountData = (res.data.off / 100) * totalAmount;
                }
                let total = calculateTotal(discountData, totalAmount);
                setCoupon(
                  res?.data?.coupon_name,
                  res?.data?.coupon_code,
                  res?.data?.type,
                  discountData,
                  total
                );
                setSubmitLabel(total >= 0.5 ? "PROCEED" : "PLACE ORDER");
                notification.success({
                  message: "Coupon Applied",
                  description: "Successfully applied coupon code.",
                });
              } else {
                handleError("Coupon Denied", "Coupon code is not available1");
              }
            } else {
              handleError("Coupon Denied", res.error);
            }
          },
          onError: (err) => {
            handleError("Coupon Denied", err.error);
          },
        }
      );
    } else {
      handleError("Coupon Denied", "Coupon code is not available");
    }
  };

  const setCoupon = (name, value, type, amount, total) => {
    setCouponCode({
      coupon_name: name,
      coupon_value: value,
      coupon_type: type,
      coupon_amount: amount,
      total: total,
    });
  };

  const handleError = (message, description) => {
    notification.error({
      message: message,
      description: description,
    });
    setCouponValue(null);
    setCoupon("", "", "", "", "");
  };

  const calculateTotal = (amount, totalAmount) => {
    let total = totalAmount - amount;
    total = total > 0 ? total : 0;
    total = total.toFixed(6);
    total = total.split(".");
    return `${total[0]}.${total[1].substr(0, 2)}`;
  };

  const { mutate: mutateApplyCoupon, isLoading: isLoadingApplyCoupon } = POST(
    "api/v1/apply_coupon_stripe_system",
    "apply_coupon_stripe_system"
  );

  // stripe checkout start
  useEffect(() => {
    resetCheckoutData();
  }, []);

  const resetCheckoutData = () => {
    setCheckoutData({
      show: false,
      data: null,
    });

    setClientSecret("");

    localStorage.removeItem("checkoutShow");
  };

  const [checkoutData, setCheckoutData] = useState({
    show: localStorage.getItem("checkoutShow")
      ? Boolean(localStorage.getItem("checkoutShow"))
      : false,
    data: localStorage.getItem("checkoutData")
      ? JSON.parse(localStorage.getItem("checkoutData"))
      : null,
  });

  const [clientSecret, setClientSecret] = useState(
    localStorage.getItem("checkoutData")
      ? localStorage.getItem("checkoutData")
      : ""
  );

  const [submitLabel, setSubmitLabel] = useState("PROCEED");
  const onFinish = (values) => {
    // for stripe array data
    let productNames = poductDetails.map((product) => product.name);
    let data = {
      ...values,
      amount: totalAmount,
      coupon_name: couponCode?.coupon_name,
      coupon_value: couponCode?.coupon_value,
      coupon_type: couponCode?.coupon_type,
      coupon_amount: couponCode?.coupon_amount,
      sub_total:
        couponCode?.total ||
        couponCode?.total === 0 ||
        couponCode?.total === "0"
          ? couponCode?.total
          : totalAmount,
      order_datails: productNames,
      product_name: "Eight Hour Application Construction",
    };

    if (data.sub_total >= 0.5) {
      // for stripe embeded checkout
      let dataCheckoutStripe = {
        url: `${appUrl}dashboard`,
        email: data.email,
        total: data.sub_total,
        product_name: data.product_name,
      };
      mutateCheckoutStripe(dataCheckoutStripe, {
        onSuccess: (res) => {
          if (res.success) {
            console.log("res.data", res.data);
            setClientSecret(res.data.client_secret);
            localStorage.setItem(
              "checkoutClientSecret",
              res.data.client_secret
            );

            setCheckoutData({
              show: true,
              data: data,
            });

            localStorage.setItem("checkoutShow", true);
            localStorage.setItem("checkoutData", JSON.stringify(data));
          } else {
            notification.warning({
              message: res.message,
              description: res.description,
            });
          }
        },
      });
    } else {
      mutateCheckout(data, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: res.message,
              description: res.description,
            });
            window.location.href = "/dashboard";
          } else {
            notification.warning({
              message: res.message,
              description: res.description,
            });
          }
        },
      });
    }
  };

  const { mutate: mutateCheckoutStripe, isLoading: isLoadingCheckoutStripe } =
    POST("api/v1/stripe_checkout", "stripe_checkout", false);

  const { mutate: mutateCheckout, isLoading: isLoadingCheckout } = POST(
    "api/v1/checkout_order",
    "checkout_order"
  );

  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const sessionId = urlParams.get("session_id");
  // useEffect(() => {
  //   if (sessionId) {
  //     let checkout_data = localStorage.getItem("checkoutData")
  //       ? JSON.parse(localStorage.getItem("checkoutData"))
  //       : null;
  //     let checkout_show = localStorage.getItem("checkoutShow")
  //       ? Boolean(localStorage.getItem("checkoutShow"))
  //       : false;

  //     if (checkout_show === true && checkout_data) {
  //       localStorage.removeItem("checkoutShow");
  //       let data = {
  //         ...checkout_data,
  //         session_id: sessionId,
  //       };

  //       console.log("sripte_status", data);

  //       mutateStatus(data, {
  //         onSuccess: (res) => {
  //           if (res.success) {
  //             if (res?.data?.status === "complete") {
  //               localStorage.removeItem("checkoutData");
  //               localStorage.removeItem("checkoutClientSecret");

  //               notification.success({
  //                 message: res.message,
  //                 description: res.description,
  //               });

  //               history.push("/dashboard");
  //             }
  //           } else {
  //             notification.success({
  //               message: res.message,
  //               description: res.description,
  //             });
  //           }
  //         },
  //       });
  //     }
  //   }
  // }, [sessionId]);

  // const { mutate: mutateStatus } = POST(
  //   "api/v1/stripe_checkout_status",
  //   "stripe_checkout_status"
  // );

  return (
    <Card className="card--padding-mobile">
      <Form form={form} onFinish={onFinish}>
        <Row gutter={20}>
          {checkoutData.show === false && (
            <>
              {/* Billing Details */}
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Collapse
                  className="main-1-collapse border-none"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faPlus}
                      />
                    )
                  }
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header="Billing details"
                    key="1"
                    className="accordion bg-darkgray-form m-b-md collapse-min-height-0"
                  >
                    <PageCheckoutComponentBillingDetails
                      country={country}
                      onChangeCountry={onChangeCountry}
                      optionState={optionState}
                      optionBillingZip={optionBillingZip}
                      countryValue={countryValue}
                      onChangeState={onChangeState}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Col>

              {/* Additional information */}
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Collapse
                  className="main-1-collapse border-none"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faPlus}
                      />
                    )
                  }
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header="Additional information"
                    key="1"
                    className="accordion bg-darkgray-form m-b-md collapse-additional-information collapse-min-height-0"
                  >
                    <PageCheckoutComponentAdditionalInformation />
                  </Collapse.Panel>
                </Collapse>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Collapse
                  className="main-1-collapse border-none"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faPlus}
                      />
                    )
                  }
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header="Apply Coupon"
                    key="1"
                    className="accordion bg-darkgray-form m-b-md collapse-coupon collapse-min-height-0"
                  >
                    <FloatInput
                      label="Coupon"
                      placeholder="Coupon"
                      value={couponValue}
                      disabled={couponCode?.coupon_name ? true : false}
                      onChange={(e) => setCouponValue(e)}
                      addonAfter={
                        <Button
                          className="atc-btn-opposite b-r-none"
                          loading={isLoadingApplyCoupon}
                          style={{
                            height: "45px",
                            marginTop: "-1px",
                            borderRadius: "0px",
                          }}
                          onClick={(e) => {
                            if (couponCode?.coupon_name) {
                              setCoupon("", "", "", "", "");
                              setCouponValue(null);
                              notification.success({
                                message: "Coupon Removed",
                                description:
                                  "Successfully removed coupon code.",
                              });
                            } else {
                              handleApplyCoupon(e);
                            }
                            setSubmitLabel("PROCEED");
                          }}
                          // disabled={couponValue === "Cancelled" ? false : true}
                        >
                          {couponCode?.coupon_name ? "REMOVE" : "APPLY"}
                        </Button>
                      }
                      // disabled={userData.status === "Cancelled" ? true : false}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Col>

              {/* Product Details */}
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Collapse
                  className="main-1-collapse border-none"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faPlus}
                      />
                    )
                  }
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header="Your order"
                    key="1"
                    className="accordion bg-darkgray-form m-b-md checkout-order-details"
                  >
                    <PageCheckoutComponentProductDetails
                      poductDetails={poductDetails}
                      couponCode={couponCode}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row
                  gutter={[12, 0]}
                  className="personal-info-1"
                  style={{ paddingTop: "8px" }}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className="m-t-n-sm"
                  >
                    <Form.Item
                      name="agree"
                      valuePropName="checked"
                      rules={[validateRules.required]}
                    >
                      <Checkbox
                        onChange={(e) => {
                          form.setFieldsValue({
                            agree: e.target.checked ? true : undefined,
                          });
                        }}
                      >
                        <Typography.Text strong>
                          I have read and agree to the website{" "}
                          <Link to="/terms-and-condition" target="_blank">
                            terms and conditions
                          </Link>{" "}
                          <span className="text-danger">*</span>
                        </Typography.Text>
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className="m-t-n-sm m-b-md text-right"
                  >
                    <Button
                      type="primary"
                      size="large"
                      className="btn-primary btn-sign-in"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            onFinish(values);
                          })
                          .catch((info) => {
                            notification.warning({
                              message: "Warning",
                              description: "Please Check Invalid Fields!",
                            });
                          });
                      }}
                      loading={isLoadingCheckoutStripe || isLoadingCheckout}
                      icon={
                        <FontAwesomeIcon icon={faForward} className="m-r-xs" />
                      }
                    >
                      {submitLabel}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </>
          )}

          {checkoutData.show === true && (
            <>
              {/* Card Details */}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="m-t-n-sm m-b-md text-right"
              >
                <Button
                  type="primary"
                  size="large"
                  className="btn-primary btn-sign-in"
                  onClick={() => {
                    resetCheckoutData();
                  }}
                  icon={
                    <FontAwesomeIcon icon={faBackward} className="m-r-xs" />
                  }
                >
                  BACK
                </Button>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Collapse
                  className="main-1-collapse border-none"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faMinus}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ color: "#325db8", fontSize: 18 }}
                        icon={faPlus}
                      />
                    )
                  }
                  defaultActiveKey={["1"]}
                  expandIconPosition="end"
                >
                  <Collapse.Panel
                    header="Card Details"
                    key="1"
                    className="accordion bg-darkgray-form m-b-md collapse-min-height-0"
                  >
                    {/* <PageCheckoutComponentCardDetails /> */}

                    {clientSecret && (
                      <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{ clientSecret }}
                      >
                        <EmbeddedCheckout />
                      </EmbeddedCheckoutProvider>
                    )}
                    <br />
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Card>
  );
}
