import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Checkbox,
  Col,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; //
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import ModalCancelAppointment from "../../Components/ModalCancelAppointmentConsultant";
import $ from "jquery";
import { GoPrimitiveDot } from "react-icons/go";
import { saveAs } from "file-saver";
import { userData, workflow } from "../../../../providers/companyInfo";

function PageConsultantBookings() {
  const userdata = userData();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [selectedDate, setSelectedDate] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [appointmentsRaw, setAppointmentsRaw] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [stage, setStages] = useState([]);
  const [event, setEvent] = useState([]);
  const [isLoadingCalendar, setLoadingCalendar] = useState(false);
  const [calendarButtons, setCalendarButtons] = useState(
    "dayGridMonth,timeGridWeek,timeGridDay"
  );

  const { data: calendarSettings, refetch: getsettings } = GET(
    "api/v1/consultant/settings",
    "consultant-settings",
    (res) => {
      if (res.success) {
        if (res.data) {
          setCalendarButtons(
            "downLoadCSV,downLoadICS dayGridMonth,timeGridWeek,timeGridDay"
          );
        } else {
          setCalendarButtons("dayGridMonth,timeGridWeek,timeGridDay");
        }
      }
    }
  );

  const { mutate: mutateDownloadCSV, isLoading: isLoadingSlot } = POST(
    "api/v1/calendar/download/csv",
    "download_calendar_csv"
  );

  const { mutate: mutateDownloadICS, isLoading: isLoadingICS } = POST(
    "api/v1/calendar/download/ics",
    "download_calendar_csv"
  );

  const { mutate: mutateGetToken, isLoading: isLoadingMutateGetToken } =
    POST("api/gapi/get_token");

  const { mutate: mutateUpdateGToken, isLoading: isLoadingGToken } = POST(
    "api/v1/gapi/update_token"
  );

  useEffect(() => {
    if (urlParams.get("code")) {
      // https://accounts.google.com/o/oauth2/auth?response_type=code&access_type=offline&client_id=414765363555-1t9l0dcs8p6nrh7729sj0ja32vog6jhj.apps.googleusercontent.com&redirect_uri=http%3A%2F%2F127.0.0.1%3A3000%2Fdashboard&state&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar&include_granted_scopes=true&prompt=consent
      mutateGetToken(
        { code: urlParams.get("code") },
        {
          onSuccess: (res) => {
            // console.log("get_token", res);
            if (res.data && res.data?.access_token) {
              mutateUpdateGToken(
                { calendar_token: res.data, user_id: userdata?.id },
                {
                  onSuccess: (res1) => {
                    console.log("get_token1", res1);
                  },
                }
              );
            }
          },
          onError: (err) => {
            console.log("err", err);
          },
        }
      );
    }

    return () => {};
  }, []);

  const arrayToCSV = (arr, delimiter = ",") =>
    arr
      .map((v) =>
        v
          .map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x))
          .join(delimiter)
      )
      .join("\n");

  const donwloadCSV = () => {
    let data = { appointments: appointments };

    mutateDownloadCSV(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([arrayToCSV(res.data)], {
            type: "text/csv;charset=utf-8",
          });
          saveAs(blob, "download.csv");
          //    setAppointments(bookedAppointments);
        }
      },
    });
  };

  const donwloadICS = () => {
    let data = { appointments: appointments };

    mutateDownloadICS(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([res.data], {
            type: "text/calendar;charset=utf-8",
          });
          saveAs(blob, "download.ics");
          //    setAppointments(bookedAppointments);
        }
      },
    });
  };

  const [workflow, setWorkflow] = useState({
    eight_hour: true,
    expedited: true,
  });

  const handlesChangeDate = (e) => {
    setEvent(e);

    let strDt = moment(e.startStr).format("MM/DD/YYYY");
    let endDt = moment(e.endStr).format("MM/DD/YYYY");

    let data = {
      startDate: strDt,
      endDate: endDt,
      timezone: "US/Mountain",
      workflow: workflow,
    };

    let bookedAppointments = [];
    let events = [];
    mutateGetAppointmentsAdmin(data, {
      onSuccess: (res) => {
        setLoadingCalendar(true);

        if (res.success) {
          let schedules = [];

          res.data.map((item) => {
            // console.log("item", item);

            let dateStartTime = item?.schedule_date + " " + item?.time_start;
            dateStartTime = moment(dateStartTime).format("YYYY-MM-DD HH:mm:ss");

            let dateEndTime = item?.schedule_date + " " + item?.time_end;
            dateEndTime = moment(dateEndTime).format("YYYY-MM-DD HH:mm:ss");
            if (item.appointmentStatus != "deleted") {
              schedules.push({
                extendedProps: item,
                title: `${item?.client?.firstname} ${item?.client?.lastname}`,
                start: dateStartTime,
                end: dateEndTime,
                id: item.id,
                status: item.status,
                color: "#4affbc",
                backgroundColor: "#7b7cf8",
              });
            }
          });

          setAppointments(schedules);
          setAppointmentsRaw(res.data);
          setLoadingCalendar(false);
        }
      },
    });
  };

  const { mutate: mutateGetAppointmentsAdmin, isLoading: loadingAppointments } =
    POST("api/v1/get/appointment", "get_appointment", false);

  const handleEventClick = (e) => {
    let appointment_data = [];
    console.log("handleEventClick", e.event.startStr);

    let new_start_time = e.event.startStr.split("T");
    let new_end_time = e.event.endStr.split("T");
    let start_time = new_start_time[1].split("-");
    let end_time = new_end_time[1].split("-");

    appointment_data.push({
      date: moment(e.event.startStr).tz("MST").format("dddd, MMMM Do"),
      time_start: moment(new_start_time[0] + "T" + start_time[0]).format(
        "H:mm a"
      ),
      time_end: moment(new_end_time[0] + "T" + end_time[0]).format("H:mm a"),
      id: e,
      eventInfo: e.event,
    });

    setAppointmentDetails(appointment_data);
    setToggleModal(true);
  };

  useEffect(() => {
    if (appointmentDetails.length > 0) {
      setToggleModal(true);
    }
  }, [appointmentDetails]);

  const handleDateClick = (e) => {
    if (stage.length > 0) {
      if (stage[2].status != "wait") {
        let row = e.dateStr;
        if (moment(row).format("L") < moment().format("L")) {
          notification.warning({
            message: `Booking not allowed`,
            description: "You can't book current or previous dates.",
            placement: "bottom",
          });
        } else if (moment(row).format("dddd") === "Sunday") {
          notification.warning({
            message: `Booking not allowed`,
            description: "You can't book on weekends.",
            placement: "bottom",
          });
        } else {
          // setSelectedDate(row);
          // setToggleModal(true);
        }
      } else {
        notification.warning({
          message: `Booking not allowed`,
          description: "You are not yet on set appointment stage",
          placement: "bottom",
        });
      }
    }
  };

  const [toggleModal, setToggleModal] = useState(false);
  const renderEventContent = (eventInfo) => {
    let ext = eventInfo.event.extendedProps;
    let timeStart = moment(eventInfo.event.startStr).tz("MST").format("H:mm A");
    let timeEnd = moment(eventInfo.event.endStr).tz("MST").format("H:mm A");
    let classname = "";

    switch (ext.status) {
      case "showed":
        classname = "primitive-brown";
        break;
      case "cancelled" || "cancelled and delete":
        classname = "primitive-cancelled";
        break;
      case "noshow":
        classname = "primitive-warning";
        break;
      case null || "booked":
        classname = "primitive-success";
        break;
      default:
        break;
    }

    return (
      <div className="calendar-event-cont">
        <div style={{ display: "flex", color: "#365293", paddingTop: "3px" }}>
          <span
            className="admin-calendar-status"
            style={{ padding: "0px", height: "10px" }}
          >
            <GoPrimitiveDot className={classname} size={15} />
          </span>
          <div
            className="calendar-event-cont-tittle"
            style={{
              color:
                ext.workflow != "expedited editing" ? "#00b8b8" : "#b132f9",
            }}
          >
            {ext.user.firstname +
              " " +
              ext.user.lastname +
              (ext.workflow !== "expedited editing"
                ? " (8 Hour App)"
                : " (Expedited)")}
          </div>
        </div>

        <div
          className={
            "time-cont " + (ext.client ? "time-booked" : "time-disabled")
          }
        >
          {ext.client ? (
            <span>{timeStart + "-" + timeEnd}</span>
          ) : (
            <span>{timeStart + "-" + timeEnd}</span>
          )}
        </div>
        {ext.client && (
          <div className={"client-name-cont " + classname}>
            {ext.client.firstname + " " + ext.client.lastname}
          </div>
        )}
        {/* <i>{eventInfo.event.title}</i> */}
      </div>
    );
  };

  const calendarRef = useRef(null);
  const fCalendar = useRef(null);
  const [calendarWidth, setCalendarWidth] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dayMaxEvent, setDayMaxEvent] = useState(2);
  useEffect(() => {
    if (windowSize.width <= 662) {
      setDayMaxEvent(0);
    } else if (windowSize.width > 662 && windowSize.width <= 991) {
      if (fCalendar?.current?.getApi()?.view?.type === "dayGridMonth") {
        setDayMaxEvent(2);
      } else {
        setDayMaxEvent(0);
      }
    } else {
      setDayMaxEvent(2);
    }
  }, [fCalendar?.current?.getApi()?.view?.type]);

  useEffect(() => {
    const handleResize = () => {
      if (calendarRef?.current) {
        setCalendarWidth(calendarRef.current.offsetWidth);
      }
    };

    if (calendarRef?.current) {
      const calendarApi = fCalendar?.current?.getApi();
      if (windowSize.width <= 662) {
        calendarApi?.changeView("timeGridDay");
      } else if (windowSize.width > 662 && windowSize.width <= 768) {
        calendarApi?.changeView("timeGridWeek");
      } else {
        calendarApi?.changeView("dayGridMonth");
      }

      const resizeObserver = new ResizeObserver(handleResize);
      if (calendarRef.current) {
        resizeObserver.observe(calendarRef.current);
      }
    }
  }, [windowSize.width]);

  const [viewOnRefresh, setViewOnRefresh] = useState("dayGridMonth");

  useEffect(() => {
    handlesChangeDate(event);
  }, [workflow]);

  return (
    <Card className="ard-min-height card--padding-mobile">
      <Legends setWorkflow={setWorkflow} workflow={workflow} />
      <Row gutter={8}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          className="admin-calendar admin-calendar-bookings consultant-calendar-bookings"
          style={{ marginTop: "18px" }}
          ref={calendarRef}
        >
          <FullCalendar
            key={`con-book-${calendarWidth}`}
            ref={fCalendar}
            allDaySlot={false}
            height="auto"
            slotEventOverlap={false}
            eventMaxStack={dayMaxEvent}
            dayMaxEvents={dayMaxEvent}
            initialView={viewOnRefresh ? viewOnRefresh : "dayGridMonth"}
            timeZone="MST"
            eventContent={renderEventContent}
            customButtons={{
              downLoadCSV: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> Outlook/Google
                  </>
                ),
                hint: "Download Outlook/Google",
                click: function () {
                  donwloadCSV();
                },
              },
              downLoadICS: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> ICalendar
                  </>
                ),
                hint: "Download ICalendar",
                click: function () {
                  donwloadICS();
                },
              },
            }}
            viewHint={(buttonText, buttonName) => {
              if (buttonName.match(/^dayGridMonth/)) {
                return "Month View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridWeek/)) {
                return "Week View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridDay/)) {
                return "Day View"; // results in "week list view"
              }
            }}
            datesSet={(e) => {
              setViewOnRefresh(e.view.type);
              handlesChangeDate(e);
            }}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: calendarButtons,
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentTimezonePlugin,
            ]}
            events={appointments}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: true,
            }}
            eventClick={(e) => handleEventClick(e)}
            dateClick={(e) => handleDateClick(e)}
            eventClassNames={(e) => {
              return "event-" + e.event.extendedProps.appointmentStatus;
            }}
            // datesSet={(e) => {
            //   handlesChangeDate(e);
            // }}
          />
        </Col>
      </Row>

      <ModalCancelAppointment
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        reload={handlesChangeDate}
        details={appointmentDetails}
        event={event}
      />
    </Card>
  );
}

function Legends(props) {
  return (
    <div className="calendar-legend calendar-legend-for-my-bookings">
      <Space size={30}>
        <Space size={6}>
          {" "}
          <Checkbox
            checked={props.workflow.eight_hour}
            className="eight-hour"
            onChange={(e) => {
              props.setWorkflow({
                ...props.workflow,
                eight_hour: e.target.checked,
              });
            }}
          >
            8 Hour Application
          </Checkbox>
        </Space>
        <Space size={6}>
          {" "}
          <Checkbox
            checked={props.workflow.expedited}
            onChange={(e) => {
              props.setWorkflow({
                ...props.workflow,
                expedited: e.target.checked,
              });
            }}
            className="expedited"
          >
            Expedited Editing
          </Checkbox>
        </Space>
      </Space>
      <Space size={30}>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-success" />
          <Typography.Text className="legend-title">Booked</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-brown" />
          <Typography.Text className="legend-title">Attended</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-cancelled" />
          <Typography.Text className="legend-title">Cancelled</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-warning" />
          <Typography.Text className="legend-title">No Show</Typography.Text>
        </Space>
      </Space>
    </div>
  );
}

export default PageConsultantBookings;
