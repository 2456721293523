import React from "react";
import { Button, Row, Col, Space, Typography, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faUserCheck,
  faUserVneckHair,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { POST } from "../../../../../providers/useAxiosQuery";

export default function ComponentUnitedOffer(props) {
  const { mutate: mutateProceedOffer, isLoading: isLoadingNotifyConsultant } =
    POST("expedited/user/proceed-offer", [
      "selected_tag",
      "opportunity",
      "get_task",
    ]);

  const onProceedOffer = () => {
    let data = { id: props.id };
    mutateProceedOffer(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: res.message,
          });

          props.fetchAll();

          props.setShowUAL(false);
        }
      },
    });
  };

  return (
    <Row className="mb-20 ual-section">
      <Col sm={24} md={24} style={{ marginTop: "-10px" }}>
        <Typography.Text className="task-card-category">
          Do you want to proceed to United Airline Application?
        </Typography.Text>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} className="show-tell-us-1">
        <Space wrap size={[8, 12]} className="complete-btn-space w-100">
          <Button
            onClick={() => {
              onProceedOffer();
              // onClickedNotify("hired");
            }}
            size="small"
            className="btn-primary btn-complete"
            style={{
              textTransform: "uppercase",
              fontWeight: 300,
            }}
            // loading={isLoadingInterviewFeedback}
          >
            {" "}
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCheck} />
            Proceed
          </Button>

          <Button
            onClick={() => {
              // onClickedNotify("interview soon");
            }}
            size="small"
            className="btn-primary btn-complete"
            style={{
              textTransform: "uppercase",
              fontWeight: 300,
            }}
            // loading={isLoadingInterviewFeedback}
          >
            {" "}
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faXmark} />
            NO
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
