import React from "react"
import { Checkbox, Col, Form, Row } from "antd"
import FloatTextArea from "../../../../../providers/FloatTextArea"

export default function PageCheckoutComponentAdditionalInformation(props) {
  const { productDetails, form } = props

  const checkboxOptions = [
    {
      label: "Do you hold foreign airman certificates, have performed foreign military service, have received foreign education, or have operated commercially non-FAR 91, 135, 121 operations? ",
      value: "1",
    },
    {
      label: "Did you exit high school BEFORE 2013? ",
      value: "2",
    },
    {
      label: "Do you have any legal trouble/issues beyond 5 moving violations? (DUI, License suspension, Arrests, Minor in Possession/Comsumption, Felonies, Misdemeanors, Reckless Driving) ",
      value: "3",
    },
    {
      label: "Do you have any aviation accidents, incidents, investigations, or any failed Part 121/135 checkrides or stage checks? Do you have more than two (2) failed checkrides/stage checks? ",
      value: "4",
    },
    {
      label: "Have you performed any military training as a pilot? (Due to the significant details of your training history, expedited editing is not available. Please sign up for the free application analysis so we can give you personalized guidance. ",
      value: "5",
    },
    {
      label: "Have never been fired or terminated from a job, training (For all previous jobs- aviation or not)",
      value: "6",
    },
    {
      label: "Have never lost flight status",
      value: "7",
    },
    {
      label: "No lost/damaged logbooks/flight records",
      value: "8",
    },
  ]

  console.log("productDetails", productDetails)

  return (
    <>
      <Row gutter={[12, 0]} className="personal-info-1" style={{ paddingTop: "8px" }}>
        {productDetails && productDetails[0].name === "Expedited Editing" && (
          <Col xs={24} sm={24} md={24} className="checkout-evaluation">
            <Form.Item name="evaluation" valuePropName="checked">
              <Checkbox.Group
                onChange={(checkedValues) => {
                  let checkedVal = Array.from(checkedValues)

                  form.setFieldsValue({
                    evaluation: checkedVal,
                  })
                }}
              >
                {checkboxOptions.map((option, index) => (
                  <Checkbox key={index} value={option.value}>
                    {option.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={24}>
          <Form.Item name="order_notes">
            <FloatTextArea label="Order notes (Optional)" placeholder="Order notes (Optional)" maxLength={160} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
