import React, { useEffect } from "react";
import { Modal, Space, Button, Row, Col, Form, notification } from "antd";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import ReactQuill from "react-quill";
import {
  formats,
  modulesToolBar,
} from "../../../../../providers/reactQuillOptions";
import { POST, GET, GETMANUAL } from "../../../../../providers/useAxiosQuery";

export default function ModalEmailNotes(props) {
  const [form] = Form.useForm();

  const { client } = props;

  const {
    data: revision_template,
    refetch: getRevisionTemplate,
    isLoading: isLoadingTemplate,
  } = GETMANUAL(`expedited/revision_template`, "revision_template", (res) => {
    console.log("resres", props.client_data);

    if (res.success) {
      let clientName = client ? client : "";
      let body = res.data.body;

      console.log("resres", client);
      body = body.replace("{{contact.name}}", clientName);

      form.setFieldsValue({
        body: body,
      });
    }
  });

  useEffect(() => {
    getRevisionTemplate();
  }, [client]);

  const { mutate: mutateSendRevisionNotes, isLoading: isLoadingNotes } = POST(
    `expedited/send_revision_notes`,
    "revision_notes"
  );

  const onFinishForm = (values) => {
    console.log("values", values);

    values = { ...values, id: props.client_id };

    mutateSendRevisionNotes(values, {
      onSuccess: (res) => {
        if (res.success) {
          props.refetch();
          notification.success({
            message: "Success",
            description: "Revision notes successfully sent",
          });
          handleCancel();
          // refetchAll();
        }
      },
    });
  };

  const handleCancel = () => {
    // form.resetFields();
    props.setShowModal(false);
  };

  return (
    <Modal
      open={props.showModal}
      // open={true}
      className="modal-send-email-notifiaction cancelled-appointment-notif"
      title="REVISION NOTES FORM"
      okText="Submit"
      width={700}
      onCancel={() => handleCancel()}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      footer={[
        <Space>
          <Button
            className="btn-primary atc-btn-opposite"
            onClick={() => {
              form.submit();
              // form.validateFields()
              //     .then((values) => {
              //         onFinishForm(values);
              //     })
              //     .catch((info) => {
              //         notification.warning({
              //             message: "Warning",
              //             description:
              //                 "Please complete the form!",
              //         });
              //     });
            }}
            // loading={isLoadingSendNotification}
          >
            Submit
          </Button>
        </Space>,
      ]}
    >
      <Form
        form={form}
        name="panlistnew"
        layout="vertical"
        onFinish={onFinishForm}
        initialValues={{
          body: "",
        }}
      >
        {/* <Row gutter={24}>
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        className="no-margin-bottom"
                    > */}
        {/* {searchFor === "Stage" && (
                            <Form.Item
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <FloatSelect
                                    label="Choose Stage"
                                    placeholder="Choose Stage"
                                    options={pipeline_stage && pipeline_stage}
                                />
                            </Form.Item>
                        )}

                        {searchFor === "Same stage clients" && (
                            <Form.Item
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                            >
                                <FloatInput
                                    label="Client Current Stage"
                                    placeholder="Client Current Stage"
                                    disabled
                                />
                            </Form.Item>
                        )} */}
        {/* {searchFor === "Client" && (
                            <Form.Item
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field is required!",
                                    },
                                ]}
                                className="form-select-error-multi"
                            >
                                <FloatSelect
                                    label="Choose clients"
                                    placeholder="Choose Clients"
                                    onChange={handleMember}
                                    options={member}
                                    multi="multiple"
                                />
                            </Form.Item>
                        )} */}
        {/* </Col>
                </Row>
                <Divider style={{ marginBottom: "24px" }}></Divider> */}
        <Row gutter={24} className="consultant-register-grid">
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            name="email_template"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "This field is required!",
                            //     },
                            // ]}
                            className="form-select-error-multi choose-email-template"
                        >
                            <FloatSelect
                                label="Choose Email Template"
                                placeholder="Choose Email Template"
                                // onChange={handleChangeEmailTemplate}
                                // options={templateOption}
                                options={[]}
                                // disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            className="the-subject"
                            name="subject"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "This field is required!",
                            //     },
                            // ]}
                        >
                            <FloatInput label="Subject" placeholder="Subject" />
                        </Form.Item>
                    </Col> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="body"
              className="m-b-sm the-body"
              // rules={[
              //     {
              //         required: true,
              //         message: "This field is required!",
              //     },
              // ]}
            >
              <ReactQuill
                className="notif-modal-quill"
                theme="snow"
                style={{ height: 250 }}
                modules={modulesToolBar}
                formats={formats}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
