import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Card, Space, Typography, Checkbox } from "antd"

import $ from "jquery"

import FullCalendar from "@fullcalendar/react" // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import moment from "moment"
import Filter from "./Component/Filter"
import { GETMANUAL, POST } from "../../../../providers/useAxiosQuery"
import { GoPrimitiveDot } from "react-icons/go"

import ModalAvailability from "./Component/ModalAvailability"
import ModalAppointment from "./Component/ModalAppointment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-regular-svg-icons"
import { saveAs } from "file-saver"
import { workflow } from "../../../../providers/companyInfo"

export default function PageCalendar(props) {
  const [calendarEvents, setCalendarEvents] = useState()
  const [params, setParams] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [showModalA, setShowModalA] = useState(false)
  const [modalData, setModalData] = useState([])
  const [eventMaxStack, setEventMaxStack] = useState(1)

  const [calendarWidth, setCalendarWidth] = useState(0)
  const calendarRef = useRef(null)
  const fCalendar = useRef(null)

  const [workflow, setWorkflow] = useState({
    eight_hour: true,
    expedited: true,
  })

  const { refetch: getEvents } = GETMANUAL(
    `api/v1/admin/calendar/events?${$.param({
      ...params,
      workflow: workflow,
    })}`,
    "admin-calendar-events",
    (res) => {
      if (res.success) {
        setCalendarEvents(res.events)
      }
    }
  )

  useEffect(() => {
    if (params.length != 0) {
      getEvents()
    }
  }, [params, workflow])

  const renderEventContent = (eventInfo) => {
    let ext = eventInfo.event.extendedProps
    console.log("ext", ext)
    let timeStart = moment(ext.schedule_date + " " + ext.time_start, "MM/DD/YYYY HH:mm").format("h:mm A")

    let timeEnd = moment(ext.schedule_date + " " + ext.time_end, "MM/DD/YYYY HH:mm").format("h:mm A")

    let classname = ""

    switch (ext.status) {
      case "booked":
        classname = "primitive-success"
        break
      case "showed":
        classname = "primitive-brown"
        break
      case "cancelled":
        classname = "primitive-cancelled"
        break
      case "noshow":
        classname = "primitive-warning"
        break
      case null:
        classname = "primitive-available"
        break

      default:
        break
    }

    return (
      <div className="calendar-event-cont">
        <div style={{ display: "flex", color: "#365293", paddingTop: "0px" }}>
          <span className="admin-calendar-status" style={{ padding: "0px", height: "10px" }}>
            <GoPrimitiveDot className={classname} size={15} />
          </span>
          <div
            className="calendar-event-cont-tittle"
            style={{
              color: ext.workflow != "expedited editing" ? "#00b8b8" : "#b132f9",
            }}
          >
            {ext.user.firstname + " " + ext.user.lastname + (ext.workflow != "expedited editing" ? " (8 Hour App)" : " (Expedited)")}
          </div>
        </div>

        <div className={"time-cont " + (ext.client ? "time-booked" : "time-disabled")}>{ext.client ? <span>{timeStart + "-" + timeEnd}</span> : <span>{timeStart + "-" + timeEnd}</span>}</div>
        {ext.client && <div className={"client-name-cont " + classname}>{ext.client.firstname + " " + ext.client.lastname}</div>}
      </div>
    )
  }

  useEffect(() => {
    if (modalData.length != 0) {
      if (modalData.status != null && modalData.status != undefined) {
        setShowModal(true)
      } else {
        setShowModalA(true)
      }
    }
  }, [modalData])

  const [currentDate, setCurrentDate] = useState({
    start: "",
    end: "",
  })

  const { mutate: mutateDownloadCSV, isLoading: isLoadingSlots } = POST("api/v1/calendar/download-schedule/csv", "download_calendar_csv")

  const { mutate: mutateDownloadICS, isLoading: isLoadingICS } = POST("api/v1/calendar/download-schedule/ics", "download_calendar_csv")

  const arrayToCSV = (arr, delimiter = ",") => {
    return arr.map((v) => v.map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(delimiter)).join("\n")
  }

  const donwloadCSV = () => {
    let data = { ...currentDate, params: params }

    console.log("data", data)

    mutateDownloadCSV(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("res", arrayToCSV(res.data))
          var blob = new Blob([arrayToCSV(res.data)], {
            type: "text/csv;charset=utf-8",
          })
          saveAs(blob, "download.csv")
        }
      },
    })
  }

  const donwloadICS = () => {
    let data = { ...currentDate, params: params }

    mutateDownloadICS(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([res.data], {
            type: "text/calendar;charset=utf-8",
          })
          saveAs(blob, "download.ics")
          //   window.location.replace(res.url);
          //    setAppointments(bookedAppointments);
        }
      },
    })
  }

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (calendarRef?.current) {
  //       setCalendarWidth(calendarRef.current.offsetWidth);

  //       if (calendarRef.current?.offsetWidth < 425) {
  //         if (fCalendar?.current) {
  //           console.log("fCalendar", fCalendar.current);
  //           const calendarApi = fCalendar.current.getApi();
  //           calendarApi.changeView("timeGridDay");
  //         }
  //       }
  //     }
  //   };

  //   const resizeObserver = new ResizeObserver(handleResize);

  //   if (calendarRef.current) {
  //     resizeObserver.observe(calendarRef.current);
  //   }

  //   return () => {
  //     if (calendarRef.current) {
  //       resizeObserver.unobserve(calendarRef.current);
  //     }
  //   };
  // }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const [dayMaxEvent, setDayMaxEvent] = useState(2)
  useEffect(() => {
    if (windowSize.width <= 662) {
      setDayMaxEvent(0)
    } else if (windowSize.width > 662 && windowSize.width <= 991) {
      if (fCalendar?.current?.getApi()?.view?.type === "dayGridMonth") {
        setDayMaxEvent(2)
      } else {
        setDayMaxEvent(0)
      }
    } else {
      setDayMaxEvent(2)
    }
  }, [fCalendar?.current?.getApi()?.view?.type])

  useEffect(() => {
    const handleResize = () => {
      if (calendarRef?.current) {
        setCalendarWidth(calendarRef.current.offsetWidth)
      }
    }

    if (calendarRef?.current) {
      const calendarApi = fCalendar?.current?.getApi()
      if (windowSize.width <= 662) {
        calendarApi?.changeView("timeGridDay")
      } else if (windowSize.width > 662 && windowSize.width <= 768) {
        calendarApi?.changeView("timeGridWeek")
      } else {
        calendarApi?.changeView("dayGridMonth")
      }

      const resizeObserver = new ResizeObserver(handleResize)
      if (calendarRef.current) {
        resizeObserver.observe(calendarRef.current)
      }
    }
  }, [windowSize.width])

  const [viewOnRefresh, setViewOnRefresh] = useState("dayGridMonth")

  return (
    <Card className="ard-min-height card--padding-mobile">
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={18} lg={18} className="filter-cont">
          <Filter setParams={setParams} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Legends workflow={workflow} setWorkflow={setWorkflow} />
        </Col>
      </Row>

      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          // className="admin-calendar admin-calendar-bookings"
          className="admin-calendar admin-calendar-bookings consultant-calendar-bookings"
          style={{ marginTop: "18px" }}
          ref={calendarRef}
        >
          <FullCalendar
            key={`con-book-${calendarWidth}`}
            ref={fCalendar}
            allDaySlot={false}
            height="auto"
            slotEventOverlap={false}
            eventMaxStack={dayMaxEvent}
            dayMaxEvents={dayMaxEvent}
            initialView={viewOnRefresh ? viewOnRefresh : "dayGridMonth"}
            timeZone="MST"
            eventClick={(eventInfo) => {
              let ex = eventInfo.event.extendedProps
              setModalData(ex)
            }}
            customButtons={{
              downLoadCSV: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> Outlook/Google
                  </>
                ),
                hint: "Download Outlook/Google",
                click: function () {
                  donwloadCSV()
                },
              },
              downLoadICS: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> ICalendar
                  </>
                ),
                hint: "Download ICalendar",
                click: function () {
                  donwloadICS()
                },
              },
            }}
            eventContent={renderEventContent}
            headerToolbar={{
              left: "",
              center: "prev,title,next",
              right: "downLoadCSV,downLoadICS dayGridMonth,timeGridWeek,timeGridDay",
            }}
            viewHint={(buttonText, buttonName) => {
              if (buttonName.match(/^dayGridMonth/)) {
                return "Month View" // results in "week list view"
              } else if (buttonName.match(/^timeGridWeek/)) {
                return "Week View" // results in "week list view"
              } else if (buttonName.match(/^timeGridDay/)) {
                return "Day View" // results in "week list view"
              }
            }}
            events={calendarEvents}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
            datesSet={(e) => {
              setViewOnRefresh(e.view.type)

              setCurrentDate({
                start: moment(e.view.currentStart).format("L"),
                end: moment(e.view.currentEnd).subtract(1, "days").endOf("month").format("L"),
              })
              if (e.view.type === "timeGridWeek" || e.view.type === "timeGridDay") {
                $(".fc-toolbar-title").addClass("resize-title")
              } else {
                $(".fc-toolbar-title").removeClass("resize-title")
              }
            }}
          />
        </Col>
      </Row>
      <ModalAppointment setModalVisibility={setShowModal} showModal={showModal} data={modalData && modalData.status != null ? modalData : []} setData={setModalData} />
      <ModalAvailability setModalVisibility={setShowModalA} showModal={showModalA} data={modalData && modalData.status == null ? modalData : []} setData={setModalData} getEvents={getEvents} />
    </Card>
  )
}

function Legends(props) {
  return (
    <div className="calendar-legend calendar-legend-for-my-bookings for-admin">
      <Space size={30}>
        <Space size={6}>
          {" "}
          <Checkbox
            checked={props.workflow.eight_hour}
            className="eight-hour"
            onChange={(e) => {
              props.setWorkflow({
                ...props.workflow,
                eight_hour: e.target.checked,
              })
            }}
          >
            8 Hour Application
          </Checkbox>
        </Space>
        <Space size={6}>
          {" "}
          <Checkbox
            checked={props.workflow.expedited}
            onChange={(e) => {
              props.setWorkflow({
                ...props.workflow,
                expedited: e.target.checked,
              })
            }}
            className="expedited"
          >
            {" "}
            Expedited Editing
          </Checkbox>
        </Space>
      </Space>
      <Space size={30}>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-available" />
          <Typography.Text className="legend-title">Available</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-success" />
          <Typography.Text className="legend-title">Booked</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-brown" />
          <Typography.Text className="legend-title">Attended</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-cancelled" />
          <Typography.Text className="legend-title">Cancelled</Typography.Text>
        </Space>
        <Space size={6}>
          <GoPrimitiveDot className="primitive-warning" />
          <Typography.Text className="legend-title">No Show</Typography.Text>
        </Space>
      </Space>
    </div>
  )
}
