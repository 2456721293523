import React from "react";
import { Route, Switch } from "react-router-dom";

import {
  faHome,
  faListCheck,
  faClipboardList,
  faCalendar,
  faUpload,
  faUser,
  faVideo,
  faChartNetwork,
  faCommentDots,
  faFileInvoiceDollar,
  faShoppingCart,
} from "@fortawesome/pro-regular-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

/** template */
import PrivateExpeditedRoute from "../PrivateExpeditedRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private_expedited/PageUser/PageDashboard/PageDashboard";
import PageTask from "../../views/private_expedited/PageUser/PageTask/PageTask";
import PageNotes from "../../views/private_expedited/PageUser/PageNotes/PageNotes";
import PageDocuments from "../../views/private_expedited/PageUser/PageDocuments/PageDocuments";
import PageProfile from "../../views/private_expedited/PageUser/PageProfile/PageProfile";
import PageAppointments from "../../views/private_expedited/PageUser/PageAppointments/PageAppointments";
import PageVideo from "../../views/private_expedited/PageUser/PageVideo/PageVideo";
import PagePolicy from "../../views/private/PagePolicy/PagePolicy";
import PageTermsAndConditions from "../../views/private/PageTermsAndCondition/PageTermsAndConditions";
import PageMySchedule from "../../views/private_expedited/PageUser/PageAppointments/PageMySchedule";
import Page2FA from "../../views/private/Page2fa/Page2fa";
import PageDocusign from "../../views/private_expedited/PageUser/PageDocusign/PageDocusign";
import PageCheckoutEightHour from "../../views/private_expedited/PageUser/PageCheckout/PageCheckoutEightHour";
import PageCheckoutOneHour from "../../views/private_expedited/PageUser/PageCheckout/PageCheckoutOneHour";
import PageCheckoutExpeditedApp from "../../views/private_expedited/PageUser/PageCheckout/PageCheckoutExpeditedApp";
import PageMessages from "../../views/private/PageAdmin/PageMessages/PageMessages";
import PageInvoices from "../../views/private_expedited/PageUser/PageInvoices/PageInvoices";

// import PagePaymentInvoices from "../../views/private/PagePaymentInvoices/PagePaymentInvoices";

export default function RouteUser() {
  console.log("here in RouteUser");
  return (
    <Switch>
      {/* Dashboard */}
      <PrivateExpeditedRoute
        exact
        page="Dashboard"
        path="/dashboard"
        title="Dashboard"
        subtitle="ATC"
        component={PageDashboard}
        pageHeaderIcon={faChartNetwork}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="Checkout"
        path="/checkout/eight-hour-application-construction"
        title="Eight Hour Application Construction"
        subtitle="CHECKOUT"
        component={PageCheckoutEightHour}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "Eight Hour Application Construction",
            link: "#",
          },
        ]}
      />
      <PrivateExpeditedRoute
        exact
        page="Checkout"
        path="/checkout/expedited-editing"
        title="Expedited Editing"
        subtitle="CHECKOUT"
        component={PageCheckoutExpeditedApp}
        pageHeaderIcon={faShoppingCart}
        breadcrumb={[
          {
            name: "Expedited Editing",
            link: "#",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="Checkout"
        path="/checkout/one-hour-update"
        title="1 Hour Update"
        subtitle="CHECKOUT"
        component={PageCheckoutOneHour}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "1 Hour Update",
            link: "#",
          },
        ]}
      />

      {/* Task */}
      <PrivateExpeditedRoute
        exact
        page="Tasks"
        path="/task"
        title="Tasks"
        subtitle="VIEW"
        component={PageTask}
        pageHeaderIcon={faListCheck}
        breadcrumb={[
          {
            name: "Task",
            link: "/Task",
          },
        ]}
      />

      {/* Calendars */}
      <PrivateExpeditedRoute
        exact
        page="Book a Consultant"
        path="/appointment/book-a-consultant"
        title="Consultant"
        subtitle="BOOK A"
        component={PageAppointments}
        pageHeaderIcon={faCalendar}
        breadcrumb={[
          {
            name: "Calendar",
            link: "#",
          },
          {
            name: "Book a Consultant",
            link: "/appointment/book-a-consultant",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="My Schedule"
        path="/appointment/myschedule"
        title="My Schedule"
        subtitle="CALENDAR"
        component={PageMySchedule}
        pageHeaderIcon={faCalendar}
        breadcrumb={[
          {
            name: "Calendar",
            link: "#",
          },
          {
            name: " My Schedule",
            link: "/appointment/myschedule",
          },
        ]}
      />

      {/* Videos */}
      <PrivateExpeditedRoute
        exact
        page="Videos"
        path="/videos"
        title="Application Videos"
        subtitle="TIMELINE"
        component={PageVideo}
        pageHeaderIcon={faVideo}
        breadcrumb={[
          {
            name: "Videos",
            link: "#",
          },
        ]}
      />

      {/* MNDA */}
      <PrivateExpeditedRoute
        exact
        page="MNDA"
        path="/mnda"
        title="Mutual Confidentiality Agreement"
        subtitle="SIGN"
        component={PageDocusign}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "MNDA",
            link: "#",
          },
        ]}
      />

      {/* Documents */}
      <PrivateExpeditedRoute
        exact
        page="Documents Manager"
        path="/documents"
        title="Manager"
        subtitle="DOCUMENTS"
        component={PageDocuments}
        pageHeaderIcon={faUpload}
        breadcrumb={[
          {
            name: "Documents Manager",
            link: "/documents",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="Documents Manager"
        path="/documents/my-uploads"
        title="Manager"
        subtitle="DOCUMENTS"
        component={PageDocuments}
        pageHeaderIcon={faUpload}
        breadcrumb={[
          {
            name: "Documents Manager",
            link: "/documents",
          },
          {
            name: "My Uploads",
            link: "/documents/my-uploads",
          },
        ]}
      />
      <PrivateExpeditedRoute
        exact
        page="Documents Manager"
        path="/documents/consultant-uploads"
        title="Manager"
        subtitle="DOCUMENTS"
        component={PageDocuments}
        pageHeaderIcon={faUpload}
        breadcrumb={[
          {
            name: "Documents Manager",
            link: "/documents",
          },
          {
            name: "My Uploads",
            link: "/documents/consultant-uploads",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="Documents Manager"
        path="/documents?code=:token"
        title="Document"
        subtitle="DOCUMENTS"
        component={PageDocuments}
        pageHeaderIcon={faHome}
        breadcrumb={[
          {
            name: "Documents Manager",
            link: "/documents",
          },
        ]}
      />

      {/* Messages */}
      <PrivateExpeditedRoute
        exact
        page="Messages"
        subtitle="View"
        title="Messages"
        path="/messages"
        pageHeaderIcon={faCommentDots}
        component={PageMessages}
        breadcrumb={[
          {
            name: "Messages",
            link: "#",
          },
        ]}
      />

      {/* Invioces */}
      <PrivateExpeditedRoute
        exact
        page="Invoices"
        subtitle="View"
        title="Invoices"
        path="/invoices"
        pageHeaderIcon={faFileInvoiceDollar}
        component={PageInvoices}
        breadcrumb={[
          {
            name: "Invoices",
            link: "/invoices",
          },
        ]}
      />

      {/* Profile */}
      <PrivateExpeditedRoute
        exact
        page="Profile"
        path="/profile/account"
        title="Profile"
        subtitle="EDIT"
        component={PageProfile}
        pageHeaderIcon={faHome}
        breadcrumb={[
          {
            name: "Profile",
            link: "/profile/account",
          },
        ]}
      />

      <PrivateExpeditedRoute
        exact
        page="Profile"
        path="/2fa"
        title="2 Factor Authentication"
        subtitle="ACCOUNT"
        component={Page2FA}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "2 Factor Authentication",
            link: "/2fa",
          },
        ]}
      />

      {/* Policy */}
      <PrivateExpeditedRoute
        exact
        page="Policy"
        path="/policy"
        title="Policy"
        subtitle="PRIVACY"
        component={PagePolicy}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "Privacy Policy",
            link: "/policy",
          },
        ]}
      />

      {/* Terms & Conditions */}
      <PrivateExpeditedRoute
        exact
        page="Terms & Conditions"
        path="/terms-and-condition"
        title="Terms & Conditions"
        subtitle="ATC"
        component={PageTermsAndConditions}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "Terms & Conditions",
            link: "/terms-and-condition",
          },
        ]}
      />

      <Route exact path="/*" component={Error404} />
      <Route exact path="/500" component={Error500} />
    </Switch>
  );
}
