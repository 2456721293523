import React, { useState } from "react";
import { Button, Modal, Radio, Space, Typography, notification } from "antd";
import { useEffect } from "react";
import { POST } from "../../../../providers/useAxiosQuery";

export default function PageUserSkipProgressTimeline(props) {
  const {
    modalSkipProgressTimeline,
    setModalSkipProgressTimeline,
    user_id,
    userTags,
    currentStage,
  } = props;
  const [progressTimeline, setProgressTimeline] = useState("");
  const [appointmentProgress, setAppointmentProgress] = useState(undefined);
  const [taskProgress, setTaskProgress] = useState("");
  const [progressTimelineData, setProgressTimelineData] = useState({
    appointment_progress: [],
    task_progress: [],
  });

  const [skipData, setSkipData] = useState({
    appointment_progress: [],
    tasks: [],
    tags: [],
  });

  useEffect(() => {
    onChangeProgressTimeline({
      target: { value: currentStage.pipeline_stages },
    });

    onChangeAppointmentProgress(currentStage.appointment_stage);
    // console.log("currentStage", currentStage);
  }, [currentStage]);

  const onChangeProgressTimeline = (e) => {
    let value = e.target.value;
    setProgressTimeline(value);
    if (value === 0) {
      setProgressTimelineData({
        appointment_progress: [],
        task_progress: [
          { title: "Upload Application", index: 0 },
          { title: "Wait For ATC Rep", index: 1 },
          { title: "Product Purchase", index: 2 },
        ],
      });

      onChangeAppointmentProgress(undefined);
      onChangeTask("Upload Application");
      // handleChangeTags("Upload Application");
    } else if (value === 1) {
      setProgressTimelineData({
        appointment_progress: [],
        task_progress: [{ title: "MNDA", index: 3 }],
      });

      onChangeAppointmentProgress(undefined);
      onChangeTask("MNDA");
      // handleChangeTags("MNDA");
    } else if (value === 2) {
      setProgressTimelineData({
        appointment_progress: [
          { name: "Call 1", index: 0 },
          { name: "Call 2", index: 1 },
          { name: "Follow Up Call (Optional)", index: 2 },
          { name: "Timeline", index: 3 },
          { name: "Pre-Publish", index: 4 },
        ],
        task_progress: [],
      });

      onChangeAppointmentProgress(
        currentStage.appointment_stage && currentStage.appointment_stage < 5
          ? currentStage.appointment_stage
          : 0
      );
      setTaskProgress("Call 1");
      // handleChangeTags("Call 1");
    } else if (value === 3) {
      setProgressTimelineData({
        appointment_progress: [],
        task_progress: [
          { title: "Consultation Call", index: 3, disabled: true },
        ],
      });

      // onChangeAppointmentProgress(3);
      setTaskProgress("Consultation Call");
      // handleChangeTags("Call 1");
    } else if (value === 4) {
      setProgressTimelineData({
        appointment_progress: [
          { name: "One Hour Update", index: 5 },
          { name: "Pre-Interview", index: 6 },
        ],
        task_progress: [],
      });

      onChangeAppointmentProgress(
        currentStage.appointment_stage && currentStage.appointment_stage >= 5
          ? currentStage.appointment_stage
          : 5
      );
      setTaskProgress("One Hour Update");
      // handleChangeTags("One Hour Update");
    } else if (value === 5) {
      setProgressTimelineData({
        appointment_progress: [],
        task_progress: [],
      });

      onChangeAppointmentProgress(undefined);
      onChangeTask("Completed");
      // handleChangeTags("Completed");
    }
  };

  const onChangeAppointmentProgress = (value) => {
    value = Number(value);
    let statuses = ["process", "wait", "finish"];
    let appointment_progress = [
      {
        name: "Call 1",
        status: statuses[+value === 0 ? 0 : 2],
      },
      {
        name: "Call 2",
        status: statuses[+value === 1 ? 0 : +value > 1 ? 2 : 1],
      },
      {
        name: "Follow Up Call (Optional)",
        status: statuses[+value === 2 ? 0 : +value > 2 ? 2 : 1],
      },
      {
        name: "Timeline",
        status: statuses[+value === 3 ? 0 : +value > 3 ? 2 : 1],
      },
      {
        name: "Pre-Publish",
        status: statuses[+value === 4 ? 0 : +value > 4 ? 2 : 1],
      },
      {
        name: "One Hour Update",
        status: statuses[+value === 5 ? 0 : +value > 5 ? 2 : 1],
      },
      {
        name: "Pre-Interview",
        status: statuses[+value === 6 ? 0 : +value > 6 ? 2 : 1],
      },
    ];

    let tagValue = "";
    if (value === 0) {
      tagValue = "Call 1";
    } else if (value === 1) {
      tagValue = "Call 2";
    } else if (value === 2) {
      tagValue = "Follow Up Call (Optional)";
    } else if (value === 3) {
      tagValue = "Timeline";
    } else if (value === 4) {
      tagValue = "Pre-Publish";
    } else if (value === 5) {
      tagValue = "One Hour Update";
    } else if (value === 6) {
      tagValue = "Pre-Interview";
    }
    let tags = handleChangeTags(tagValue);
    let onChangeAppointmentProgress = {
      ...skipData,
      appointment_progress: value || value === 0 ? appointment_progress : [],
      tasks: [],
      tags: tags,
    };

    // console.log("onFinishSkipStage", onChangeAppointmentProgress);
    setSkipData(onChangeAppointmentProgress);
    setAppointmentProgress(value);
  };

  const onChangeTask = (value) => {
    let tasks = changeTaskData(value);
    let tags = handleChangeTags(value);

    let onChangeTaskData = {
      ...skipData,
      appointment_progress: [],
      tasks: tasks,
      tags: tags,
    };

    setSkipData(onChangeTaskData);
    setTaskProgress(value);

    // console.log("appointmentProgress", onChangeTaskData);
    // // console.log("onFinishSkipStage", value);
    // console.log("appointmentProgress", tasks);
  };

  const onFinishSkipStage = () => {
    const statuses = ["process", "wait", "finish"];
    const progress_timeline = [
      {
        name: "App Analysis",
        status: statuses[+progressTimeline === 0 ? 0 : 2],
      },
      {
        name: "Client Stage",
        status:
          statuses[+progressTimeline === 1 ? 0 : +progressTimeline > 1 ? 2 : 1],
      },
      {
        name: "Appointment Stage",
        status:
          statuses[+progressTimeline === 2 ? 0 : +progressTimeline > 2 ? 2 : 1],
      },
      {
        name: "Docs for Evaluation",
        status:
          statuses[+progressTimeline === 3 ? 0 : +progressTimeline > 3 ? 2 : 1],
      },
      {
        name: "Publish",
        status:
          statuses[+progressTimeline === 4 ? 0 : +progressTimeline > 4 ? 2 : 1],
      },
      {
        name: "Completed",
        status:
          statuses[+progressTimeline === 5 ? 0 : +progressTimeline > 5 ? 2 : 1],
      },
    ];

    let data = {
      user_id: user_id,
      progress_timeline: progress_timeline,
      appointment_progress: skipData.appointment_progress,
      tasks: skipData.tasks,
      tags: skipData.tags,
    };

    console.log("onFinishSkipStage", data);

    mutateSkipStage(data, {
      onSuccess: (res) => {
        // console.log("onFinishSkipStage", res);
        setModalSkipProgressTimeline(false);
        notification.success({
          message: "Success",
          description: "Successfully Submitted",
        });
      },
    });
  };

  const changeTaskData = (data) => {
    let arr = [];
    if (data === "Upload Application") {
      arr = [
        { title: "Upload Application", status: "pending" },
        { title: "Wait For ATC Rep", status: "upcoming" },
        { title: "Product Purchase", status: "" },
        { title: "MNDA", status: "" },
        { title: "Book Appointment", status: "" },
        { title: "Consultation Call", status: "" },
      ];
    } else if (data === "Wait For ATC Rep") {
      arr = [
        { title: "Upload Application", status: "completed" },
        { title: "Wait For ATC Rep", status: "pending" },
        { title: "Product Purchase", status: "upcoming" },
        { title: "MNDA", status: "" },
        { title: "Book Appointment", status: "" },
        { title: "Consultation Call", status: "" },
      ];
    } else if (data === "Product Purchase") {
      arr = [
        { title: "Upload Application", status: "completed" },
        { title: "Wait For ATC Rep", status: "completed" },
        { title: "Product Purchase", status: "pending" },
        { title: "MNDA", status: "upcoming" },
        { title: "Book Appointment", status: "" },
        { title: "Consultation Call", status: "" },
      ];
    } else if (data === "MNDA") {
      arr = [
        { title: "Upload Application", status: "completed" },
        { title: "Wait For ATC Rep", status: "completed" },
        { title: "Product Purchase", status: "completed" },
        { title: "MNDA", status: "pending" },
        { title: "Book Appointment", status: "upcoming" },
        { title: "Consultation Call", status: "" },
      ];
    } else if (data === "Book Appointment") {
      arr = [
        { title: "Upload Application", status: "completed" },
        { title: "Wait For ATC Rep", status: "completed" },
        { title: "Product Purchase", status: "completed" },
        { title: "MNDA", status: "completed" },
        { title: "Book Appointment", status: "pending" },
        { title: "Consultation Call", status: "upcoming" },
      ];
    }

    // console.log("onChangeTask", userTags);
    return arr;
  };

  const { mutate: mutateSkipStage, isLoading: isLoadingSkipStage } = POST(
    "api/v1/user_skip_stage",
    [
      "user_skip_stage",
      "user_details",
      "opportunity",
      "get_task",
      "ghl_files",
      "get_list",
    ]
  );

  const modalAfterClose = () => {
    setSkipData({
      user_id: user_id,
      appointment_progress: [],
      tasks: [],
      tags: [],
    });

    setProgressTimelineData({
      appointment_progress: [],
      task_progress: [],
    });

    setProgressTimeline("");
    setAppointmentProgress(undefined);
    setTaskProgress("");

    onChangeProgressTimeline({
      target: { value: currentStage.pipeline_stages },
    });

    onChangeAppointmentProgress(currentStage.appointment_stage);
  };

  const handleChangeTags = (value) => {
    let arr = [];

    if (value === "Upload Application") {
      arr = ["upload documents (current task)", "just registered user"];
    } else if (value === "Wait For ATC Rep") {
      arr = [
        "upload documents (done)",
        "waiting for atc rep call (current task)",
      ];
    } else if (value === "Product Purchase") {
      arr = [
        "waiting for atc rep call (done)",
        "product purchase (current task)",
      ];
    } else if (value === "MNDA") {
      arr = ["product purchase (done)", "docusign (current task)"];
    } else if (value === "Call 1") {
      // if (userTags.find((task) => task.includes("call (current task)"))) {
      //   arr = ["call 1 - book (done)", "call 1 - call (current task)"];
      // } else {
      arr = [
        "docusign (done)",
        "book for call 1",
        "call 1 - book (current task)",
      ];
      // }
    } else if (value === "Call 2") {
      // if (userTags.find((task) => task.includes("call (current task)"))) {
      //   arr = ["call 2 - book (done)", "call 2 - call (current task)"];
      // } else {
      arr = [
        "book for call 2",
        "call 1 - call (done)",
        "call 2 - book (current task)",
      ];
      // }
    } else if (value === "Follow Up Call (Optional)") {
      // if (userTags.find((task) => task.includes("call (current task)"))) {
      //   arr = [
      //     "follow up call - book (done)",
      //     "follow up call - call (current task)",
      //   ];
      // } else {
      arr = [
        "call 2 - call (done)",
        "book for follow up call",
        "follow up call - book (current task)",
      ];
      // }
    } else if (value === "Timeline") {
      // if (userTags.find((task) => task.includes("call (current task)"))) {
      //   arr = ["timeline - book (done)", "timeline - call (current task)"];
      // } else {
      arr = [
        "follow up call - call (done)",
        "book for timeline",
        "timeline - book (current task)",
      ];
      // }
    } else if (value === "Pre-Publish") {
      // if (userTags.find((task) => task.includes("call (current task)"))) {
      //   arr = [
      //     "pre publish - book (done)",
      //     "pre publish - call (current task)",
      //   ];
      // } else {
      arr = [
        "timeline - call (done)",
        "book for pre publish",
        "pre publish - book (current task)",
      ];
      // }
    } else if (value === "One Hour Update") {
      arr = ["pre publish", "pre publish - call (done)"];
    } else if (value === "Pre-Interview") {
      arr = [
        "book for pre interview",
        "pre interview - book (current task)",
        "pre interview - product offer",
      ];
    } else if (value === "Completed") {
      arr = ["status"];
    }

    // let handleChangeTags = {
    //   ...skipData,
    //   tags: arr,
    // };
    // console.log("handleChangeTags", handleChangeTags);
    // setSkipData(handleChangeTags);

    return arr;
  };

  return (
    <Modal
      className="skip-progress-timeline-modal"
      title="Skip Progress Timeline"
      open={modalSkipProgressTimeline}
      onCancel={() => {
        setModalSkipProgressTimeline(false);
      }}
      afterClose={() => {
        modalAfterClose();
      }}
      width={600}
      footer={[
        <Space key="footer-btn">
          <Button
            key="back"
            size="large"
            onClick={() => {
              setModalSkipProgressTimeline(false);
            }}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            size="large"
            type="primary"
            className="atc-btn-opposite"
            loading={isLoadingSkipStage}
            // disabled={progressTimeline ? false : true}
            onClick={() => {
              onFinishSkipStage();
            }}
          >
            Submit
          </Button>
        </Space>,
      ]}
    >
      <Typography.Text strong className="font-20px">
        Progress Timeline
      </Typography.Text>
      <Radio.Group onChange={onChangeProgressTimeline} value={progressTimeline}>
        <Radio value={0} key={0}>
          App Analysis
        </Radio>
        <Radio
          value={1}
          key={1}
          disabled={
            currentStage.pipeline_stages > 1 ||
            currentStage.pipeline_stages === 1
              ? true
              : false
          }
        >
          Client Stage
        </Radio>
        <Radio value={2} key={2}>
          Set Appt.
        </Radio>
        <Radio value={3} key={3} disabled={true}>
          Development
        </Radio>
        <Radio value={4} key={4}>
          Publish
        </Radio>
        <Radio value={5} key={5}>
          Completed
        </Radio>
      </Radio.Group>

      {progressTimelineData?.appointment_progress?.length > 0 && (
        <>
          <br />
          <Space direction="vertical" className="m-t-md">
            <Typography.Text strong className="font-20px">
              Appointment Progress
            </Typography.Text>
            <Radio.Group
              onChange={(e) => {
                onChangeAppointmentProgress(
                  e.target.value ? e.target.value : ""
                );
              }}
              value={`${appointmentProgress}`}
            >
              {progressTimelineData.appointment_progress.map((item, index) => {
                return (
                  <Radio
                    value={`${item.index}`}
                    data-name={item.name}
                    key={index}
                  >
                    {item.name}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Space>
        </>
      )}

      {progressTimelineData?.task_progress?.length > 0 &&
        progressTimeline !== 0 && (
          <>
            <br />
            <Space direction="vertical" className="m-t-md">
              <Typography.Text strong className="font-20px">
                Task
              </Typography.Text>
              <Radio.Group
                onChange={(e) => {
                  onChangeTask(e.target.value ? e.target.value : "");
                }}
                value={taskProgress}
              >
                {progressTimelineData.task_progress.map((item, index) => {
                  return (
                    <Radio
                      value={item.title}
                      key={index}
                      disabled={item?.disabled ? true : false}
                    >
                      {item.title}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Space>
          </>
        )}
    </Modal>
  );
}
