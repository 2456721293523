import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faVideo,
  faFileArrowUp,
  faFilePdf,
  faChartNetwork,
  faCalendarDays,
  faCommentDots,
  faFileInvoiceDollar,
  faShoppingCart,
} from "@fortawesome/pro-regular-svg-icons";

const UserSideMenu = [
  {
    page: "Dashboard",
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faChartNetwork} />,
  },

  {
    page: "Products",
    title: "Products",
    path: "#products",
    icon: <FontAwesomeIcon icon={faShoppingCart} />,
    children: [
      {
        page: "8 Hour Application",
        title: "8 Hour Application",
        path: "#8-hour-application",
      },
      {
        page: "Expedited Editing",
        title: "Expedited Editing",
        path: "#expedited-editing",
      },
    ],
  },
  {
    page: "Tasks",
    title: "Tasks",
    path: "/task",
    icon: <FontAwesomeIcon icon={faListCheck} />,
  },
  {
    title: "Calendars",
    path: "/appointment",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
    children: [
      {
        page: "Book a Consultant",
        title: "Book a Consultant",
        path: "/appointment/book-a-consultant",
      },
      {
        page: "My Schedule",
        title: "My Schedule",
        path: "/appointment/myschedule",
      },
    ],
  },
  {
    page: "Videos",
    title: "Videos",
    path: "/videos",
    icon: <FontAwesomeIcon icon={faVideo} />,
    className: "video-link",
  },
  {
    page: "MNDA",
    title: "MNDA",
    path: "/mnda",
    icon: <FontAwesomeIcon icon={faFilePdf} />,
    className: "MNDA",
  },
  {
    page: "Upload Docs",
    title: "Documents Manager",
    path: "/documents",
    icon: <FontAwesomeIcon icon={faFileArrowUp} />,
  },
  {
    page: "Messages",
    title: "Messages",
    path: "/messages",
    icon: <FontAwesomeIcon icon={faCommentDots} />,
  },
  {
    page: "Invoices",
    title: "Invoices",
    path: "/invoices",
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
  },
];

export default UserSideMenu;
