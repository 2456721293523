import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Typography,
  notification,
  Divider,
  Upload,
  message,
  Result,
} from "antd";
//import FloatInputPasswordStrength from "../../../../providers/FloatInputPasswordStrength";
import { useHistory } from "react-router-dom";
import {
  GET,
  GETMANUAL,
  POST,
  POSTFILE,
} from "../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import moment from "moment";
import { role, tz_offset } from "../../../providers/companyInfo";
import { GoPrimitiveDot } from "react-icons/go";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faArrowUpFromSquare,
} from "@fortawesome/pro-regular-svg-icons";

export default function ModalEventBooked(props) {
  const { toggleModal, setToggleModal, details, appStage } = props;
  // const [appointmentDetails, setappointmentDetails] = useState([]);
  const [selectedTime, setSelectedTime] = useState();
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const [currentData, setCurrentData] = useState({
    stage: "",
    tag: "",
  });
  const [selectedSlot, setSelectedSlot] = useState(true);
  const [savedFiles, setSavedFiles] = useState(0);

  useEffect(() => {
    console.log("current_tag: ", currentData.tag);
  }, [currentData]);

  useEffect(() => {
    if (details.length > 0) {
      let sorted_slots = details[0].slots.slots
        .sort((a, b) => {
          let time_1 = moment(a.time_start, "HH:mm");
          let time_2 = moment(b.time_start, "HH:mm");
          return time_1.isBefore(time_2) ? -1 : 1;
        })
        .map((item) => {
          return { ...item, selected: false };
        });

      setAppointmentSlots(sorted_slots);
      if (currentData.tag === currentData.stage) {
        setSavedFiles(2);
        // console.log("savedFiles1", appStage, 2);
        // console.log("savedFiles1", currentData.tag, currentData.stage);
      }
    }
  }, [details, toggleModal, appStage]);

  const { refetch: refetchGetcurrenttag } = GET(
    `expedited/user/getcurrenttag`,
    "tag",
    (res) => {
      if (role() == "User") {
        if (res.success) {
          let data = res.data;
          let current_stage = "";
          if (Array.isArray(data)) {
            data.forEach((x) => {
              if (x.includes("current task")) {
                let stages = x.split("-");
                if (Array.isArray(stages)) {
                  stages = stages[0].trim();
                }

                if (stages === "reschedule") {
                  current_stage = appStage;
                } else if (stages === "pre publish") {
                  current_stage = "pre-publish";
                } else if (stages === "follow up call") {
                  current_stage = "follow up call (optional)";
                } else if (stages === "1 hr update reschedule") {
                  current_stage = currentData?.stage;
                } else if (stages === "pre interview") {
                  current_stage = "pre-interview";
                } else {
                  current_stage = stages;
                }
              }
            });
          }

          let dataStage = "";
          if (res.lastStageUploaded?.stage.trim() === "pre publish") {
            dataStage = "pre-publish";
          } else if (res.lastStageUploaded?.stage.trim() === "follow up call") {
            dataStage = "follow up call (optional)";
          } else if (res.lastStageUploaded?.stage.trim() === "pre interview") {
            dataStage = "pre-interview";
          } else if (
            res.lastStageUploaded?.stage.trim() === "reschedule" ||
            res.lastStageUploaded?.stage.trim() === "1 hr update reschedule"
          ) {
            dataStage = res.lastStageUploaded.last_uploaded_stage.trim();
            if (dataStage === "follow up call") {
              dataStage = "follow up call (optional)";
            } else if (dataStage === "1 hr update") {
              dataStage = "one hour update";
            } else if (dataStage === "pre interview") {
              dataStage = "pre-interview";
            }
          } else {
            dataStage = res.lastStageUploaded?.stage.trim();
          }

          setCurrentData({
            ...currentData,
            tag: current_stage,
            stage: dataStage,
            stageAll: res?.allStageUploaded.filter((item) =>
              item.includes(current_stage)
            ).length,
          });
        }
      }
    }
  );

  const [uploadMore, setUploadMore] = useState(false);

  // disable 1 hour button on timeline stage
  const disabledButton = (timeStart, timeEnd) => {
    // let sTime = moment(timeStart, "HH:mm:ss");
    // let eTime = moment(timeEnd, "HH:mm:ss");
    // let duration = moment.duration(eTime.diff(sTime));
    if (timeStart && timeEnd) {
      let sTime = moment(timeStart, "HHmm").format("HHmm");
      let eTime = moment(timeEnd, "HHmm").format("HHmm");
      let duration = Number(eTime) - Number(sTime);
      // console.log("booking_data", duration, sTime, eTime);
      if (appStage) {
        if (
          appStage === "timeline" ||
          appStage === "pre-publish" ||
          appStage === "one hour update" ||
          appStage === "pre-interview"
        ) {
          if (duration <= 100) {
            return true;
          }
        } else {
          if (duration <= 100) {
            return false;
          }
        }
      }
    }
    return false;
  };

  const { mutate: addHistoryLog } = POST(
    "expedited/historylogs/add",
    "add_history_logs"
  );

  const { mutate: mutateAddAppointments, isLoading: isLoadingAddAppointments } =
    POST("expedited/appointment", ["appointment", "tag"]);

  const onFinishForm = () => {
    let timeslot =
      details[0].alter_date +
      "T" +
      moment(selectedTime.time_start, "HH:mm").format("HH:mm:ss");

    let timeZone_offset = tz_offset(timeslot);

    let data = {
      appointmentDate: details[0].date,
      // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezone: "US/Mountain",
      timeslot: timeslot + "-06:00",
      slot_id: selectedTime.slot_id,
      calendarID: selectedTime.id,
    };

    mutateAddAppointments(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Successfully booked",
          });

          addHistoryLog(
            {
              page: "User/Book-appointment",
              key: "appointment slot",
              consultant: details[0].slots.user_id,
              old_data: "",
              new_data: timeslot,
              method: "book-appointment",
            },
            { onSuccess: (res) => {} }
          );

          setToggleModal(false);
          history.push("/appointment/myschedule");
        } else if (res.success == false) {
          notification.warning({
            message: "Not allowed",
            description: res.message,
          });
          setToggleModal(false);
        }
      },
      onError: (res) => {
        notification.warning({
          message: "Booking failed",
          description: "Something went wrong while booking selected slot",
        });
      },
    });
  };

  let selectedTimeTemp;
  const [multifileList, setMultiFileList] = useState([]);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = POSTFILE(
    "expedited/user/upload",
    ["getlastuploadedstage", "tag"]
  );

  const onFinishUpload = (values) => {
    const data = new FormData();

    multifileList.map((item, index) => {
      data.append("images_" + index, item.originFileObj, item.name);
    });
    data.append("images_count", multifileList ? multifileList.length : 0);
    data.append("images", multifileList);

    // data.append("current_task", JSON.stringify(currentTask));

    console.log("savedFiles", appStage, savedFiles);
    if (
      savedFiles > 0
      // multifileList.length > 0
    ) {
      if (
        appStage == "timeline" &&
        savedFiles <= 1
        // appStage == "timeline" && multifileList.length <= 1
      ) {
        notification.warning({
          message: "Documents required!",
          description: "Please Upload Revised application and Timeline sheets",
        });
      } else {
        mutateCreate(data, {
          onSuccess: (res) => {
            if (res.success) {
              notification.success({
                message: "Success",
                description: "Successfully Uploaded",
              });
              var filename = multifileList.map((item) => item.name);
              setUploadMore(false);
              addHistoryLog(
                {
                  page: "Documents",
                  key: "upload document",
                  old_data: "",
                  new_data: JSON.stringify(filename),
                  method: "upload-document",
                  // consultant: details[0].eventInfo.title,
                },
                { onSuccess: (res) => {} }
              );
              setMultiFileList([]);
              form.resetFields();
              // history.push(`/view/restaurants/edit/${table_id}`);
            }
          },
          onError: (res) => {},
        });
      }
    } else {
      notification.warning({
        message: "Warning",
        description: "Please choose files to upload",
      });
    }
  };

  return (
    <Modal
      open={toggleModal}
      title={
        <Typography.Text
          style={{ fontSize: 20, color: "#FFF", fontWeight: 400 }}
        >
          APPOINTMENT
        </Typography.Text>
      }
      footer={
        <Button
          onClick={() => {
            if (selectedTime) {
              onFinishForm();
            } else {
              notification.warning({
                message: "Time slot required",
                description: "Please select timelslot first!",
              });
            }
          }}
          type="primary"
          size="large"
          className="btn-primary btn-sign-in"
          style={{ width: "100%", fontSize: "18px", paddingTop: "5px" }}
          disabled={
            currentData.tag !== "call 2" &&
            (currentData.tag !== currentData.stage ? true : selectedSlot)
          }
          loading={isLoadingAddAppointments}
          // disabled={currentData?.stageAll === 0 ? true : false}
        >
          BOOK APPOINTMENT
        </Button>
      }
      onCancel={() => {
        setToggleModal(false);
        setUploadMore(false);
      }}
      afterClose={() => {
        setToggleModal(false);
        setUploadMore(false);
        setSelectedSlot(true);
        setAppointmentSlots([]);
        setSelectedTime(null);
        setSavedFiles(0);
        setMultiFileList([]);
      }}
      className="modal-primary-default modal-change-2-factor-authentication modal-appointment"
    >
      <>
        <div
          className="event-title-container"
          style={{ margin: "-15px 0px 0px" }}
        >
          <span
            className="admin-calendar-status"
            style={{ marginBottom: "2px" }}
          >
            <GoPrimitiveDot className="primitive-available" />
          </span>
          <span className="event-title" style={{ fontSize: 24 }}>
            {details[0] && details[0].title}
          </span>
        </div>
        <div
          className="event-schedule-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "-5px",
          }}
        >
          <span className="event-date" style={{ fontSize: 16 }}>
            {details[0] && details[0].date}
          </span>
          <br />
        </div>
      </>
      <Divider style={{ marginBottom: "10px" }} />

      {currentData.tag !== "call 2" && (
        <>
          <Typography.Text style={{ fontSize: 22, fontWeight: 400 }}>
            STEP 1
          </Typography.Text>
          <br />
          {currentData.tag !== currentData.stage || uploadMore ? (
            <>
              <Typography.Text style={{ fontSize: "16px" }}>
                <span style={{ color: "red" }}>*</span>
                Application Revision and/or Timeline pages must be uploaded to
                enable call scheduling.
              </Typography.Text>
              <Form layout="vertical" form={form} onFinish={onFinishUpload}>
                <Upload
                  className="venue-images"
                  listType="picture"
                  fileList={multifileList}
                  onChange={({ fileList: newFileList }) => {
                    var _file = newFileList;
                    _file.map((row, key) => {
                      return (row.status = "done");
                    });

                    let _newFile = [];
                    const allowedFileTypes = [
                      "jpg",
                      "jpeg",
                      "png",
                      "gif",
                      "jpg",
                      "doc",
                      "docx",
                      "pdf",
                    ];

                    _file.forEach((item) => {
                      const fileExtension = item.name
                        .split(".")
                        .pop()
                        .toLowerCase();

                      if (allowedFileTypes.includes(fileExtension)) {
                        _newFile.push(item);
                      }
                    });

                    setMultiFileList(_newFile);

                    setSavedFiles(
                      currentData.tag === currentData.stage
                        ? savedFiles
                        : _newFile.length
                    );
                  }}
                  beforeUpload={(file) => {
                    const isLt30M = file.size / 1024 / 1024 < 30; // Check if the file is smaller than 5MB
                    // const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file is smaller than 5MB
                    const allowedFileTypes = [
                      "jpg",
                      "jpeg",
                      "png",
                      "gif",
                      "jpg",
                      "doc",
                      "docx",
                      "pdf",
                    ];
                    const fileExtension = file.name
                      .split(".")
                      .pop()
                      .toLowerCase();

                    if (!isLt30M) {
                      notification.warning({
                        message: "Error",
                        description: "File must be smaller than 30MB!",
                      });
                      return Upload.LIST_IGNORE;
                    } else if (file.size <= 0) {
                      notification.warning({
                        message: "Error",
                        description: "File is invalid or corrupted!",
                      });
                      return Upload.LIST_IGNORE;
                    }

                    if (!allowedFileTypes.includes(fileExtension)) {
                      notification.warning({
                        message: "Error",
                        description:
                          "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX, PDF files.",
                      });
                      return Upload.LIST_IGNORE;
                    }

                    return true; // Allow the file to be uploaded
                  }}
                  onPreview={async (file) => {
                    let src = file.url;
                    if (!src) {
                      src = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => resolve(reader.result);
                      });
                    }
                    const image = new Image();
                    image.src = src;
                    const imgWindow = window.open(src);
                    imgWindow.document.write(image.outerHTML);
                  }}
                >
                  <Button type="dashed" className="upload-btn">
                    <div className="upload-btn-description">
                      <p className="ant-upload-text">
                        <FontAwesomeIcon
                          className="document-upload-icon"
                          icon={faArrowUpFromBracket}
                        />
                      </p>
                      <br />
                      <Typography.Text className="upload-title">
                        Click or Drag{" "}
                      </Typography.Text>
                      <br />
                      <Typography.Text className="upload-title">
                        Documents to Upload{" "}
                      </Typography.Text>

                      <br />
                      <br />

                      <Typography.Text className="upload-sm-desc">
                        <strong>Limit:</strong>{" "}
                        <span
                          style={{ color: "#58595b", fontWeight: "normal" }}
                        >
                          30MB (Unlimited Number of Files)
                        </span>
                      </Typography.Text>
                      <br />
                      <Typography.Text className="upload-sm-desc">
                        <strong>Types:</strong>{" "}
                        <span
                          style={{ color: "#58595b", fontWeight: "normal" }}
                        >
                          pdf, png, gif, jpg, jpeg, doc, docx.{" "}
                        </span>
                      </Typography.Text>
                      {/* <p
                      className="upload-instruction"
                      style={{ whiteSpaces: "break-word" }}
                    >
                      5MB limit. Allowed types: pdf, png, gif, jpg, jpeg, doc,
                      docx.
                    </p>
                    <p className="upload-instruction">
                      Unlimited number of documents can be uploaded to this
                      field.
                    </p> */}
                    </div>
                  </Button>
                </Upload>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoadingCreate}
                  className="btn-primary btn-submit-upload"
                  block
                  size="large"
                >
                  SUBMIT
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Result
                status="success"
                title="Thanks for uploading!"
                subTitle="You may now schedule your appointment"
                className="result-success"
              />
              {
                <center>
                  <Button type="link" onClick={() => setUploadMore(true)}>
                    Upload More
                  </Button>
                </center>
              }
            </>
          )}
          <Divider style={{ marginBottom: "10px" }} />

          <Typography.Text style={{ fontSize: 22, fontWeight: 400 }}>
            STEP 2
          </Typography.Text>
          <br />
        </>
      )}

      <Typography.Text style={{ fontSize: "16px" }}>
        Select Time (MST):
      </Typography.Text>
      <Row gutter={8}>
        {details[0] &&
          appointmentSlots.length > 0 &&
          appointmentSlots.map((item, index) => {
            let dateTimeNow = new Date(moment().tz("MST").format("Y-MM-DD"));
            let dateTimeSchedule = new Date(
              moment(details[0].alter_date + " " + item.time_start).format(
                "Y-MM-DD"
              )
            );

            return (
              <Col md={24} xs={24} className="m-t-xs" key={`${index}`}>
                <Button
                  onClick={() => {
                    setSelectedTime(item);
                    let _appointmentSlots = [...appointmentSlots];
                    _appointmentSlots.forEach((_appointmentSlot) => {
                      if (_appointmentSlot.slot_id == item.slot_id) {
                        _appointmentSlot.selected = true;
                      } else {
                        _appointmentSlot.selected = false;
                      }
                    });
                    setAppointmentSlots(_appointmentSlots);
                    setSelectedSlot(false);

                    // console.log("dateTimeNow 1", moment(dateTimeNow).unix());
                    // console.log(
                    //   "dateTimeNow 2",
                    //   moment(dateTimeSchedule).unix()
                    // );
                  }}
                  style={{ width: "100%" }}
                  className={item.selected && "active-slot-btn"}
                  disabled={
                    currentData.tag !== "call 2" &&
                    (currentData.tag !== currentData.stage ? true : false)
                  }
                >
                  {moment(item.time_start, "HH:mm").format("h:mm A") +
                    " - " +
                    moment(item.time_end, "HH:mm").format("h:mm A")}
                </Button>
              </Col>
            );
          })}
      </Row>
    </Modal>
  );
}
