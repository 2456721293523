import React, { useEffect, useState } from "react";
import { Card, Col, Collapse, Form, Input, Row, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleDown,
  faAngleUp,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";

import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import ComponentQuill from "../../Components/ComponenQuill";
import FloatSelect from "../../../../providers/FloatSelect";

export default function PageStages() {
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [stage, setStages] = useState([]);
  const [stageDetails, setStageDetails] = useState([]);

  const stage_name = [
    "App Analysis",
    "Client Stage",
    "Set Appt.",
    "Development",
    "Publish",
    "Complete",
  ];

  const [workflow, setWorkflow] = useState("8 hour application");

  GET("api/v1/user/opportunity", "opportunity", (res) => {
    if (res.success) {
      console.log(res.pipeline_stages);
      setStages(res.pipeline_stages);
    }
  });

  const handleSetWorkflow = (value) => {
    setWorkflow((prev) => value);
  };

  useEffect(() => {
    if (workflow) {
      // console.log(workflow);
      refetchStage();
    }
  }, [workflow]);

  const { refetch: refetchStage } = GETMANUAL(
    `api/v1/user/stages?workflow=${workflow}`,
    "stages",
    (res) => {
      if (res.success) {
        setStageDetails(res.data);

        form.setFieldsValue({
          stages: res.data,
        });
      }
    }
  );

  const {
    mutate: mutateStagesDetails,
    // isLoading: isLoadingEmailTemplate,
  } = POST("api/v1/user/stages/update", "stages");

  const save = (values) => {
    // console.log("onFinish", values);

    mutateStagesDetails(values, {
      onSuccess: (res) => {
        // console.log("mutateEmailTemplate", res);
        if (res.success) {
          notification.success({
            message: "Stage Description",
            description: "Successfully Updated",
          });
        } else {
          notification.error({
            message: "Stage Description",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Stage Description",
          description: err.response.data.message,
        });
      },
    });
  };

  const handleBlurSave = (value, index, id) => {
    if (
      stageDetails[index]?.description?.replace(/\s/g, " ") !==
      value?.replace(/\s/g, " ")
    ) {
      let data = {
        id: id,
        value: value,
      };

      save(data);
    }
  };

  return (
    <Card className="card--padding-mobile">
      <Row className="m-b-md">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="m-b-sm assigned-consultant"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "end",
          }}
        >
          <div style={{ width: "20%" }}>
            <FloatSelect
              label="Workflow"
              value={workflow}
              onChange={(value) => {
                handleSetWorkflow(value);
              }}
              onBlur={() => {}}
              options={[
                {
                  label: "Eight hour application",
                  value: "8 hour application",
                },
                { label: "Expedited Editing", value: "expedited editing" },
              ]}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form form={form}>
            <ComponentQuill
              form={form}
              name={"stages"}
              dataSource={stageDetails}
              onBlur={handleBlurSave}
            />
          </Form>
        </Col>
      </Row>
    </Card>
  );
}
