import React from "react";

import { Button, Space, Col, Popconfirm } from "antd";

export function CriteriaButton(props) {
  const { addNewTag, isLoadingAddTag } = props;
  return (
    <Col sm={24} md={12} lg={8}>
      <Space className="meets-criteria">
        <Button
          style={{
            color: "#365293",
          }}
          type="primary white"
          className="btn-primary btn-primary-wfc "
          onClick={() =>
            addNewTag([
              "wait for application screening (done)",
              "product purchase (current task)",
            ])
          }
          loading={isLoadingAddTag}
        >
          Client meets criteria
        </Button>
        <Button
          style={{
            color: "#365293",
          }}
          type="primary white"
          className="btn-warning btn-primary-wfc "
          onClick={() =>
            addNewTag([
              "wait for application screening (done)",
              "client did not meet criteria (current task)",
            ])
          }
          loading={isLoadingAddTag}
        >
          Client does not meet criteria
        </Button>
      </Space>
    </Col>
  );
}
export function AddAndEmailRevisionNotesButton(props) {
  const { handleProceed } = props;

  return (
    <>
      <Space direction="horizontal" size={20}>
        <Button
          style={{
            color: "#365293",
          }}
          type="primary white"
          className="btn-primary btn-primary-wfc btn-text"
          onClick={
            () => {
              props.setShowModal(true);
            }
            // addNewTag([
            //     "wait for application screening (done)",
            //     "product purchase (current task)",
            // ])
          }
          disabled={props.tags.includes(
            "call 2 - reschedule book (current task)"
          )}
          // loading={isLoadingAddTag}
        >
          Email Revision Notes
        </Button>

        {!props.tags.includes("call 2 - call (done)") &&
          !props.tags.includes("call 1 - call (done)") &&
          !props.tags.includes("timeline sheet (next task)") && (
            <Popconfirm
              title={`Is the consultant satisfied with the client${
                props.tags.includes("docusign (done)")
                  ? " initial upload"
                  : " upload"
              }?`}
              onConfirm={() => handleProceed("next-task")}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary white"
                className="btn-primary btn-primary-wfc btn-text"
                // onClick={
                //   () => {}
                //   // addNewTag([
                //   //     "wait for application screening (done)",
                //   //     "product purchase (current task)",
                //   // ])
                // }
                disabled={props.tags.includes(
                  "call 2 - reschedule book (current task)"
                )}
                // loading={isLoadingAddTag}
              >
                Proceed Client to Next Task
              </Button>
            </Popconfirm>
          )}

        {props.tags.includes("docusign (done)") && (
          <Popconfirm
            title="Does the consultant want to push client to call 1?"
            onConfirm={() => handleProceed("call1")}
            okText="Yes"
            cancelText="No"
          >
            <Button
              style={{
                color: "#f67500",
              }}
              type="primary white"
              className="btn-primary btn-primary-wfc btn-text"
              // onClick={
              //   () => {
              //     handleProceed("call2");
              //   }
              //   // addNewTag([
              //   //     "wait for application screening (done)",
              //   //     "product purchase (current task)",
              //   // ])
              // }
              disabled={props.tags.includes(
                "call 1 - reschedule book (current task)"
              )}
              // loading={isLoadingAddTag}
            >
              Proceed Client to Call 1
            </Button>
          </Popconfirm>
        )}
      </Space>
    </>
  );
}

export function PushToPublishButton(props) {
  return (
    <>
      <Button
        // style={{
        //   color: "#365293",
        // }}
        type="primary "
        className="btn-primary btn-download btn-text"
        onClick={
          () => {
            if (
              props.tags.includes("call 2 - call (done)") &&
              !props.tags.includes("ual call 2 - call (done)")
            ) {
              props.publishClient("published (done)");
            } else {
              props.publishClient("ual published (done)");
            }

            // props.setShowModal(true);
          }
          // addNewTag([
          //     "wait for application screening (done)",
          //     "product purchase (current task)",
          // ])
        }
        // loading={isLoadingAddTag}
      >
        Publish Client
      </Button>
    </>
  );
}
