import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Row,
  Modal,
  Space,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";

import FloatInput from "../../../../providers/FloatInput";

import {
  formats,
  modulesToolBar,
} from "../../../../providers/reactQuillOptions";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import FloatSelect from "../../../../providers/FloatSelect2";
import GeneralTemplates from "./Component/GeneralTemplates";
import validateRules from "../../../../providers/validateRules";

Quill.register("modules/imageResize", ImageResize);
const FontSize = ReactQuill.Quill.import("attributors/style/size");
FontSize.whitelist = ["10px", "12px", "14px", "16px", "18px", "20px"];
ReactQuill.Quill.register(FontSize, true);

export default function PageEmailTemplates() {
  const [dataSource, setDataSource] = useState([]);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [showModal, setShowModal] = useState(false);

  const [currentView, setCurrentView] = useState("0");
  const [workflow, setWorkflow] = useState("8 hour application");

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
        [{ font: [] }],
        [{ size: ["10px", "12px", "14px", "16px", "18px", "20px"] }],
      ],
    },
  };

  const { data: dataEmailTemplate, refetch: getEmailTemplate } = GET(
    `api/v1/email_template?${$.param({
      view: currentView,
      type: "email",
      workflow: workflow,
    })}`,
    "email_template_list",
    (res) => {
      if (res.success) {
        let newdata = res.data;

        setDataSource(newdata);

        form.setFieldsValue({
          list: newdata,
        });
      }
    }
  );

  const {
    mutate: mutateNewEmailTemplate,
    // isLoading: isLoadingEmailTemplate,
  } = POST("api/v1/add_email_template", "new_email_template");

  const onFinishNewEmailTemplate = (values) => {
    // console.log("onFinish", values);

    mutateNewEmailTemplate(values, {
      onSuccess: (res) => {
        // console.log("mutateEmailTemplate", res);
        if (res.success) {
          notification.success({
            message: "Add Editable Template",
            description: res.message,
          });

          form1.resetFields();
          setShowModal(false);
          getEmailTemplate();
        } else {
          notification.error({
            message: "Add Editable Template",
            description: res.message,
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Add Editable Template",
          description: err.response.data.message,
        });
      },
    });
  };

  const {
    mutate: mutateEmailTemplate,
    // isLoading: isLoadingEmailTemplate,
  } = POST("api/v1/email_template", "email_template");

  const onFinish = (values) => {
    values.view = currentView;

    mutateEmailTemplate(values, {
      onSuccess: (res) => {
        // console.log("mutateEmailTemplate", res);
        if (res.success) {
          notification.success({
            message: "Editable Template",
            description: "Successfully Updated",
          });

          // setDataSource(res.data);
          getEmailTemplate();

          // form.setFieldsValue({
          //   list: res.data,
          // });
        } else {
          notification.error({
            message: "Editable Template",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Editable Template",
          description: err.response.data.message,
        });
      },
    });
  };

  const handleBlurSave = (value, index, field) => {
    // console.log("emailForOptions", dataSource[index][field]);
    // console.log("emailForOptions", value);
    if (dataSource[index][field] !== value) {
      form.submit();
    }
  };

  const emailForOptions = [
    { label: "General", value: "0" },
    { label: "Consultant", value: 1 },
    { label: "Client", value: 2 },
  ];

  useEffect(() => {
    getEmailTemplate();
  }, [currentView, workflow]);

  const handleChangeView = (key) => {
    setCurrentView(key);
  };

  const handleSetWorkflow = (value) => {
    setWorkflow(value);
  };

  return (
    <Card className="email-templates-fields card--padding-mobile">
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Space
            direction="horizontal"
            size={0}
            wrap
            block
            className="space-btn-cont"
          >
            {/* <Button
              type="primary"
              className="btn-email-template btn-with-svg b-r-none atc-btn-opposite"
              size="large"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "10px" }} />
              Add New Email Template
            </Button>  */}
            <Button
              type="primary"
              className={
                "btn-email-template b-r-none atc-btn-opposite" +
                (currentView === "0" ? " btn-active-default" : "")
              }
              size="large"
              onClick={() => {
                handleChangeView("0");
              }}
            >
              GENERAL
            </Button>
            <Button
              type="primary"
              className={
                "btn-email-template b-r-none atc-btn-opposite" +
                (currentView === "1" ? " btn-active-default" : "")
              }
              size="large"
              onClick={() => {
                handleChangeView("1");
              }}
            >
              CONSULTANT
            </Button>
            <Button
              type="primary"
              className={
                "btn-email-template   b-r-none atc-btn-opposite" +
                (currentView === "2" ? " btn-active-default" : "")
              }
              size="large"
              onClick={() => {
                handleChangeView("2");
              }}
            >
              CLIENT
            </Button>
          </Space>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className="m-b-sm assigned-consultant"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ width: "100%" }}>
            <FloatSelect
              label="Workflow"
              value={workflow}
              onChange={(value) => {
                handleSetWorkflow(value);
              }}
              onBlur={() => {}}
              options={[
                {
                  label: "Eight hour application",
                  value: "8 hour application",
                },
                { label: "Expedited Editing", value: "expedited editing" },
              ]}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t-md">
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form form={form} onFinish={onFinish}>
            <GeneralTemplates
              form={form}
              // dataSource={dataSource}
              dataSource={dataEmailTemplate && dataEmailTemplate?.data}
              onBlur={handleBlurSave}
              currentView={currentView}
            />
          </Form>
        </Col>
      </Row>
      <Form
        form={form1}
        onFinish={onFinishNewEmailTemplate}
        initialValues={{ content: "", title: "", subject: "" }}
      >
        <Modal
          open={showModal}
          className="modal modal-email-template"
          title="NEW EMAIL TEMPLATE FORM"
          okText="Submit"
          cancelText="Cancel"
          width={700}
          onCancel={() => {
            form1.resetFields();
            setShowModal(false);
          }}
          footer={[
            <Space>
              <Button
                className="atc-btn-opposite"
                onClick={() => {
                  form1
                    .validateFields()
                    .then((values) => {
                      onFinishNewEmailTemplate(values);
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
                // loading={isLoadingNotification}
              >
                Submit
              </Button>
            </Space>,
          ]}
        >
          {/*  // initialValues={{ search_for: "Role" }}*/}

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {" "}
              <Form.Item name="email_for" className="m-b-sm form-select-error">
                <FloatSelect
                  label="Select Template"
                  placeholder="Select Template"
                  options={emailForOptions}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              {" "}
              <Form.Item
                name="title"
                className="m-b-sm form-select-error"
                hasFeedback
                rules={[validateRules.required]}
              >
                <FloatInput label="Title" placeholder="Title" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {" "}
              <Form.Item
                name="subject"
                className="m-b-sm form-select-error"
                hasFeedback
                rules={[validateRules.required]}
              >
                <FloatInput label="Subject" placeholder="Subject" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {" "}
              <Form.Item
                name="content"
                className="m-b-sm"
                hasFeedback
                rules={[validateRules.required]}
              >
                <ReactQuill
                  className="ticket-quill"
                  theme="snow"
                  modules={modulesToolBar}
                  formats={formats}
                  // onChange={(e) => console.log(e)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </Card>
  );
}
