import React from "react";
import { Card, Row, Col, Space } from "antd";
import {
  CriteriaButton,
  AddAndEmailRevisionNotesButton,
  PushToPublishButton,
} from "./ComponentActionButtons";

export default function ComponentConsultantActions(props) {
  const {
    tags,
    addNewTag,
    isLoadingAddTag,
    setShowModal,
    publishClient,
    handleProceed,
  } = props;

  console.log("tags", tags);
  return (
    <Card
      className="m-t-sm"
      headStyle={{
        marginTop: "10px",
        minHeight: 40,
        paddingBottom: 0,
      }}
      title="Actions"
      bodyStyle={{
        display: "block",
        overflow: "hidden",
        maxHeight: "300px",
      }}
    >
      <Row gutter={8} className=" m-b-md">
        {tags.includes("wait for application screening (current task)") &&
          !tags.includes("wait for application screening (done)") && (
            <CriteriaButton
              tags={tags}
              addNewTag={addNewTag}
              isLoadingAddTag={isLoadingAddTag}
            />
          )}

        <Col sm={24} md={12} lg={8}>
          <Space>
            {(tags.indexOf("consultant reviews document (current task)") !==
              -1 ||
              (tags.indexOf("call 2 - call (done)") !== -1 &&
                tags.indexOf("upload revised application (current task)") ===
                  -1) ||
              tags.indexOf("ual call 2 - call (done)") !== -1) && (
              <AddAndEmailRevisionNotesButton
                setShowModal={setShowModal}
                tags={tags}
                handleProceed={handleProceed}
              />
            )}
            {((tags.indexOf("call 2 - call (done)") !== -1 &&
              tags.indexOf("upload revised application (current task)") ===
                -1) ||
              tags.indexOf("ual call 2 - call (done)") !== -1) && (
              <PushToPublishButton tags={tags} publishClient={publishClient} />
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
}
